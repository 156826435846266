// Inspired by https://github.com/jxnblk/reflexbox
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { range, memoize } from 'lodash';

import style from './layout-style.scss';

const toSuffix = memoize((v = '') => v.toString().replace('.', '-'));

const Layout = (props) => {
  const {
    DecoratedComponent,
    flex,
    inlineFlex,
    flexWrap,
    flexAuto,
    flexNone,
    flexShrink,
    flexFill,
    flexEqualWidth,
    flexColumn,
    align,
    justify,
    col,
    sm,
    md,
    lg,
    gutter,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginX,
    marginY,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingX,
    paddingY,
    ...rest
  } = props;

  const classes = classNames({
    [style.flex]: flex,
    [style.inlineFlex]: inlineFlex,
    [style.flexAuto]: flexAuto,
    [style.flexNone]: flexNone,
    [style.flexShrink]: flexShrink,
    [style.flexFill]: flexFill,
    [style.flexEqualWidth]: flexEqualWidth,
    [style.flexWrap]: flexWrap,
    [style.directionColumn]: flexColumn,
    [style[`align-${align}`]]: align,
    [style[`justify-${justify}`]]: justify,
    [style[`col-${col}`]]: col,
    [style[`sm-${sm}`]]: sm,
    [style[`md-${md}`]]: md,
    [style[`lg-${lg}`]]: lg,
    [style[`margin-${toSuffix(margin)}`]]: margin,
    [style[`margin-left-${toSuffix(marginX)}`]]: marginX,
    [style[`margin-right-${toSuffix(marginX)}`]]: marginX,
    [style[`margin-top-${toSuffix(marginY)}`]]: marginY,
    [style[`margin-bottom-${toSuffix(marginY)}`]]: marginY,
    [style[`margin-left-${toSuffix(marginLeft)}`]]: marginLeft,
    [style[`margin-right-${toSuffix(marginRight)}`]]: marginRight,
    [style[`margin-top-${toSuffix(marginTop)}`]]: marginTop,
    [style[`margin-bottom-${toSuffix(marginBottom)}`]]: marginBottom,
    [style[`padding-${toSuffix(padding)}`]]: padding,
    [style[`padding-left-${toSuffix(paddingX)}`]]: paddingX,
    [style[`padding-right-${toSuffix(paddingX)}`]]: paddingX,
    [style[`padding-top-${toSuffix(paddingY)}`]]: paddingY,
    [style[`padding-bottom-${toSuffix(paddingY)}`]]: paddingY,
    [style[`padding-left-${toSuffix(paddingLeft)}`]]: paddingLeft,
    [style[`padding-right-${toSuffix(paddingRight)}`]]: paddingRight,
    [style[`padding-top-${toSuffix(paddingTop)}`]]: paddingTop,
    [style[`padding-bottom-${toSuffix(paddingBottom)}`]]: paddingBottom,
    [style[`gutter-${toSuffix(gutter)}`]]: gutter,
  });

  return <DecoratedComponent {...rest} layoutClasses={classes} />;
};

const columns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const spaceScale = range(0, 4.25, 0.25); // 0 to 4

Layout.propTypes = {
  DecoratedComponent: PropTypes.func,
  align: PropTypes.oneOf([
    'stretch',
    'center',
    'baseline',
    'flex-start',
    'flex-end',
  ]),
  children: PropTypes.node,
  col: PropTypes.oneOf(columns),
  flex: PropTypes.bool,
  flexAuto: PropTypes.bool,
  flexColumn: PropTypes.bool,
  flexEqualWidth: PropTypes.bool,
  flexFill: PropTypes.bool,
  flexNone: PropTypes.bool,
  flexShrink: PropTypes.bool,
  flexWrap: PropTypes.bool,
  gutter: PropTypes.oneOf(spaceScale),
  inlineFlex: PropTypes.bool,
  justify: PropTypes.oneOf([
    'center',
    'space-around',
    'space-between',
    'flex-start',
    'flex-end',
  ]),
  lg: PropTypes.oneOf(columns),
  margin: PropTypes.oneOf(spaceScale),
  marginBottom: PropTypes.oneOf(spaceScale),
  marginLeft: PropTypes.oneOf(spaceScale),
  marginRight: PropTypes.oneOf(spaceScale),
  marginTop: PropTypes.oneOf(spaceScale),
  marginX: PropTypes.oneOf(spaceScale),
  marginY: PropTypes.oneOf(spaceScale),
  md: PropTypes.oneOf(columns),
  padding: PropTypes.oneOf(spaceScale),
  paddingBottom: PropTypes.oneOf(spaceScale),
  paddingLeft: PropTypes.oneOf(spaceScale),
  paddingRight: PropTypes.oneOf(spaceScale),
  paddingTop: PropTypes.oneOf(spaceScale),
  paddingX: PropTypes.oneOf(spaceScale),
  paddingY: PropTypes.oneOf(spaceScale),
  sm: PropTypes.oneOf(columns),
};

const layout = (DecoratedComponent) => (props) =>
  <Layout {...props} DecoratedComponent={DecoratedComponent} />;

export default layout;
