import { find } from 'lodash';
import * as groupActions from '../../groups/actions/groups-actions';

const initialState = {
  loadingDataSources: false,
  loadingInvite: false,
  inviteError: null,
  isReconnectModalOpen: false,
  reconnectingDataSourceId: null,
  isDeleting: true,
};

const userById = (people, userId) => {
  const users = [
    ...(people.owners || []),
    ...(people.members || []),
    ...(people.viewers || []),
  ];
  return find(users, { id: userId });
};

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case groupActions.getDataSourcesStart.type:
      return {
        ...state,
        loadingDataSources: true,
      };

    case groupActions.getDataSourcesSuccess.type:
    case groupActions.getDataSourcesFailed.type:
      return {
        ...state,
        loadingDataSources: false,
      };

    case groupActions.deleteDataSourceStart.type:
    case groupActions.deleteDataSourceFailed.type:
      return {
        ...state,
        isDeleting: true,
      };

    case groupActions.inviteMembersStart.type: {
      return {
        ...state,
        loadingInvite: true,
        inviteError: null,
      };
    }

    case groupActions.inviteMembersSuccess.type: {
      return {
        ...state,
        loadingInvite: false,
      };
    }

    case groupActions.inviteMembersFailed.type: {
      return {
        ...state,
        loadingInvite: false,
        inviteError: payload,
      };
    }

    case groupActions.inviteMembersEmailInvalid.type: {
      return {
        ...state,
        inviteError: { id: 'people.invalidMessage' },
      };
    }

    case groupActions.inviteMembersClearError.type: {
      return {
        ...state,
        inviteError: null,
      };
    }

    case groupActions.openReconnectModal.type:
      return {
        ...state,
        isReconnectModalOpen: true,
        reconnectingDataSourceId: payload,
      };

    case groupActions.closeReconnectModal.type:
      return {
        ...state,
        isReconnectModalOpen: false,
        reconnectingDataSourceId: null,
      };

    default:
      return state;
  }
};

export default adminReducer;
export { initialState, userById };
