import { composeTransformers } from '../compose-transformers';
import { transform as genericTableTransform } from '../per-visualisation/table';
import {
  getSelectedCells,
  getSelectionHeaderIndices,
  greedilyParseFloat,
} from '../../spreadsheet-helpers';
import { getSelectionFormat, isNumber } from './helpers';
import { chain, head, isMatch, times, constant } from 'lodash';

const MAX_LENGTH_OF_COLUMN = 50;

const getCellFormat = (type) => {
  if (type === 'percentage') {
    return 'percent';
  }

  if (type === 'number') {
    return 'decimal';
  }

  return type;
};

const getBestSelectionFormat = (selections) => {
  const [format, unit] = getSelectionFormat(selections);

  // Apply no format when the column is text or has mixed types
  if (format === 'decimal' && !isNumber(selections)) {
    return [];
  }

  return [format, unit];
};

const mapCell = (cell) => {
  if (!cell) return null;

  const { type, value: rawValue, human, currency } = cell;
  const cellType = getCellFormat(type);
  let value;

  switch (cellType) {
    case 'decimal':
    case 'percent':
    case 'currency':
      value = greedilyParseFloat(rawValue);
      break;
    default:
      value = rawValue;
  }

  return {
    type: cellType,
    value,
    human,
    unit: currency,
  };
};

const mapHeading = ({ type, value, human } = {}) => {
  const cellType = getCellFormat(type);
  let heading;

  switch (cellType) {
    case 'decimal':
      heading = `${parseFloat(value)}`;
      break;
    case 'percent':
      heading = `${parseFloat(value) * 100}%`;
      break;
    case 'text':
      heading = value;
      break;
    default:
      heading = human;
  }

  return { heading };
};

const hasCellHeader = (cells, headerIndices, headerIndex) => {
  const firstCell = head(cells);
  const isFirstCellHeader = !headerIndex
    ? true
    : isMatch(firstCell, headerIndex);

  return headerIndices && isFirstCellHeader;
};

const tableTransform = (spreadsheet, config, timezone, initialContext = {}) => {
  const context = {};
  const { columns, hasHeader } = config;

  if (!columns || !columns.length) {
    return context;
  }

  const { cells } = spreadsheet;
  const columnsCells = getSelectedCells(cells, columns);
  let headerIndices;

  if (hasHeader) {
    headerIndices = getSelectionHeaderIndices(columns, columnsCells);
  }

  const [headingCells = [], dataCells] = chain(columnsCells)
    .zip(headerIndices)
    .map(([columnCells, headerIndex]) => {
      const hasFirstCellHeader = hasCellHeader(
        columnCells,
        headerIndices,
        headerIndex,
      );

      if (!hasFirstCellHeader) {
        return [undefined, columnCells];
      }

      const [heading, ...rest] = columnCells;
      return [heading, rest];
    })
    .reject(([heading, rest]) => {
      // Remove the whole column if there is no heading and if there are no values.
      // But the column is kept if a heading is set
      return !heading && !rest.length;
    })
    .unzip()
    .value();

  // +1 when we're slicing off the header cell
  const headerOffset = hasHeader ? 1 : 0;

  // as empty cells won't appear amongst selected cells we need
  // to compensate by filling in the gaps based on the "coordinates"
  // of a given cell in a series
  const fillEmptyCells = (colCells, i) => {
    let [[startCol, startRow]] = columns[i];
    if (startCol < 0) startCol = 0;
    if (startRow < 0) startRow = 0;

    // When calculating a given cells index in the 'series' we need to calculate an
    // offset from our header cells if we have them or from the beginning of the selection failing that
    // so we can insert the cells at the correct index in the new 'filled' series
    const offset = (headerIndices && headerIndices[i]) || {
      column: startCol + 1,
      row: startRow + 1,
    };

    return colCells.reduce((memo, cell) => {
      // if we already have a column that is of max length
      // then we should not add any more items to it
      if (memo.length === MAX_LENGTH_OF_COLUMN) {
        return memo;
      }
      // calculate the index to use in the memo'd array
      const index =
        cell.row - offset.row + cell.column - offset.column - headerOffset;

      // if the index would lie outside of the maximum length for a column
      // then we don't add the cell and just fill up empty values until
      // the max length
      if (index >= MAX_LENGTH_OF_COLUMN) {
        const fillToEndCount = MAX_LENGTH_OF_COLUMN - memo.length;
        return [...memo, ...times(fillToEndCount, constant(null))];
      }
      // calculate how empty cells we've missed in the memo'd array
      const missingCount = index - memo.length;

      // fill in the empty cells with null values
      return [...memo, ...times(missingCount, constant(null)), cell];
    }, []);
  };

  const rows = chain(dataCells)
    .map(fillEmptyCells)
    .map((column) => column.map(mapCell))
    .unzip()
    .value();

  const columnConfig = chain(dataCells)
    .map(getBestSelectionFormat)
    .map(([format, unit]) => ({ format, unit }))
    .merge(headingCells.map(mapHeading))
    .value();

  if (columnConfig.length) {
    context.columns = columnConfig;
  }

  context.data = rows;

  return { ...initialContext, ...context };
};

const transform = composeTransformers(tableTransform, genericTableTransform);

export { transform, MAX_LENGTH_OF_COLUMN };
