import { reject, find, without, omit } from 'lodash';
import * as groupActions from '../../groups/actions/groups-actions';
import * as peopleActions from '../../people/actions/people-actions';
import updateById from '../../lib/update-by-id';

const initialState = {};

const userById = (allUsers, userId) => {
  const users = [
    ...(allUsers.owners || []),
    ...(allUsers.members || []),
    ...(allUsers.viewers || []),
  ];
  return find(users, { id: userId });
};

const tidyUsers = (users) => {
  return {
    owners: users.owners,
    members: users.members,
    viewers: users.viewers.map((viewer) => {
      const tidied = { ...viewer };
      // Remove these fields as they are no longer used
      delete tidied.dashboards_shared;
      delete tidied.dashboard_ids;
      return tidied;
    }),
  };
};

const makeUserAccountOwner = (users, userId) => {
  const { owners, members, viewers } = users;

  const user = userById(users, userId);
  const newAccountOwner = {
    ...user,
    owner: true,
  };
  delete newAccountOwner.permission;

  return {
    owners: [...owners, newAccountOwner],
    members: without(members, user),
    viewers: without(viewers, user),
  };
};

const makeUserMemberViewer = (users, userId) => {
  const { owners, members, viewers } = users;

  const user = userById(users, userId);
  const promotedUser = { ...user, permission: 'read_only' };

  return {
    owners: without(owners, user),
    members: [...members, promotedUser],
    viewers: without(viewers, user),
  };
};

const revokeAccountOwner = (users, { userId }) => {
  const { owners, members } = users;
  const user = userById(users, userId);
  const newMember = {
    ...user,
    permission: 'admin',
  };
  delete newMember.owner;

  return {
    ...users,
    owners: without(owners, user),
    members: [...members, newMember],
  };
};

const allUsersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case groupActions.getPeopleSuccess.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { people } = payload;

      return {
        ...state,
        ...tidyUsers(people),
      };

    case groupActions.demoteToDashboardViewOnlySuccessful.type: {
      const { userId } = payload;
      const { members, viewers } = state;

      const user = find(members, { id: userId });
      const dashboardViewOnlyUser = { ...user };
      delete dashboardViewOnlyUser.permission;

      return {
        ...state,
        members: without(members, user),
        viewers: [...viewers, dashboardViewOnlyUser],
      };
    }

    case peopleActions.deleteUserStart.type: {
      const { userId } = payload;
      const { owners, members, viewers } = state;

      const updateUsers = (users) =>
        updateById(users, userId, (user) => ({
          ...user,
          isBeingDeleted: true,
        }));

      return {
        ...state,
        owners: updateUsers(owners),
        members: updateUsers(members),
        viewers: updateUsers(viewers),
      };
    }

    case peopleActions.deleteUserSuccessful.type: {
      const { userId } = payload;
      const { owners, members, viewers } = state;

      return {
        ...state,
        owners: reject(owners, { id: userId }),
        members: reject(members, { id: userId }),
        viewers: reject(viewers, { id: userId }),
      };
    }

    case peopleActions.deleteUserFailed.type: {
      const { userId } = payload;
      const { owners, members, viewers } = state;

      const updateUsers = (users) =>
        updateById(users, userId, (user) => omit(user, 'isBeingDeleted'));

      return {
        ...state,
        owners: updateUsers(owners),
        members: updateUsers(members),
        viewers: updateUsers(viewers),
      };
    }

    case groupActions.setPermissionsStart.type:
    case groupActions.setPermissionsFailed.type: {
      const { userId, role } = payload;
      const { members } = state;

      return {
        ...state,
        members: updateById(members, userId, (user) => ({
          ...user,
          permission: role,
        })),
      };
    }

    case groupActions.makeAccountOwnerSuccess.type:
      return {
        ...makeUserAccountOwner(state, payload),
      };

    case groupActions.promoteToMemberSuccess.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { userId } = payload;

      return {
        ...makeUserMemberViewer(state, userId),
      };

    case groupActions.revokeAccountOwnerSuccess.type:
      return {
        ...revokeAccountOwner(state, payload),
      };

    default:
      return state;
  }
};

export default allUsersReducer;
export { initialState, userById };
