import { includes } from 'lodash';
import {
  DARK_THEME,
  LIGHT_THEME,
  CUSTOM_THEME,
} from '../../dashboard/themes/theme-constants';

const starterThemes = [DARK_THEME, LIGHT_THEME];

const isThemeAllowed = ({ custom_css: customCSS }, theme) => {
  if (customCSS && theme === CUSTOM_THEME) {
    return true;
  }

  return includes(starterThemes, theme);
};

const filterByAllowances = (customizeValues, allowances) => {
  const {
    advanced_dashboard_customization: advancedDashboardCustomization,
    can_disable_branding: canDisableBranding,
    custom_css: customCSS,
  } = allowances;

  if (advancedDashboardCustomization) {
    return customizeValues;
  }

  const newCustomizeValues = {
    ...customizeValues,
    logoSrc: null,
    showLogo: true,
  };

  if (!isThemeAllowed(allowances, newCustomizeValues.theme)) {
    newCustomizeValues.theme = starterThemes[0];
  }

  if (!canDisableBranding) {
    newCustomizeValues.showFooter = true;
  }

  if (!customCSS) {
    newCustomizeValues.cssFile = null;
  }

  return newCustomizeValues;
};

export default filterByAllowances;
