import { get, reverse, isFinite } from 'lodash';
import cleanTransformedData from '../shared/clean-transformed-data';
import { transform as genericNumberTransform } from '../per-visualisation/number';
import {
  composeTransformers,
  composeApplyConfigTransformers,
} from '../compose-transformers';
import { getCellValue } from './helpers';

const getFirstCol = (row) => getCellValue(row.values[0]);

const numberTransform = (data) => {
  const rows = get(data, 'data.databaseQuery.rows');

  if (!rows) {
    return {};
  }

  const value = reverse(rows.map(getFirstCol)).filter(isFinite);

  return {
    value,
    format: 'decimal',
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(numberTransform, genericNumberTransform);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data, config) => {
  const cleanedData = cleanTransformedData(data);
  return composeApplyConfigTransformers(genericNumberTransform)(
    cleanedData,
    config,
  );
};

export { transform, applyConfig };
