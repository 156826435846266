import createAction from '../../lib/redux/create-action';
import { isUndefined, isNull } from 'lodash';
import fetchJSON, { fetchAll } from '../../universal/fetch';
import { formatMessage } from '../../lib/i18n';
import { patchUser } from '../../services/management-service';

const WHATS_NEW_ENDPOINT =
  'https://s3.amazonaws.com/geckoboard-rss-feed/whats-new.json';

// Actions
export const toggleModalAction = createAction('WhatsNew:TOGGLE_MODAL');
export const toggleNotification = createAction('WhatsNew:TOGGLE_NOTIFICATION');
export const markAsReadAction = createAction('WhatsNew:MARK_AS_READ');

export const fetchDataStart = createAction('WhatsNew:FETCH_DATA_START');
export const fetchDataSuccess = createAction('WhatsNew:FETCH_DATA_SUCCESS');
export const fetchDataFailed = createAction('WhatsNew:FETCH_DATA_FAILED');

// Async Actions
export const toggleModal = (visible) => async (dispatch, getState) => {
  dispatch(toggleModalAction(visible));

  const { whatsNew = {} } = getState();
  if (whatsNew.items) {
    return;
  }

  try {
    dispatch(fetchDataStart());

    const { items } = await fetchJSON(WHATS_NEW_ENDPOINT);
    dispatch(fetchDataSuccess({ items }));
  } catch (e) {
    const message = formatMessage('whatsnew.errorMessage');
    dispatch(fetchDataFailed(message));
  }
};

export const markAsRead = () => async (dispatch, getState) => {
  try {
    const { whatsNew: { etag } = {} } = getState();

    dispatch(toggleNotification(false));

    const user = await patchUser({ whats_new_etag: etag });
    dispatch(markAsReadAction(user));
  } catch (err) {
    // Don't worry if this fails for now
  }
};

export const checkForNewUpdate = (userEtag) => async (dispatch, getState) => {
  if (isUndefined(userEtag)) {
    return;
  }

  try {
    const res = await fetchAll(WHATS_NEW_ENDPOINT);
    const etag = res.headers.get('ETag');
    const firstRun = isNull(userEtag);

    if (!firstRun && etag !== userEtag) {
      dispatch(toggleNotification(true));
    }

    const { items } = await res.json();
    dispatch(fetchDataSuccess({ items, etag }));

    // don't show notification for first time user
    if (firstRun) {
      await markAsRead()(dispatch, getState);
    }
  } catch (err) {
    // Don't worry for now
  }
};
