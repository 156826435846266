import { cloneDeep, partial } from 'lodash';
import { pickFormat } from './helpers';
import { transform as genericLineTransform } from '../per-visualisation/line';
import { composeTransformers } from '../compose-transformers';

const transformSeries = (
  { secondaryIndex, legends = [] } = {},
  { data, name: seriesName },
  index,
) => {
  const name = legends[index] || seriesName;

  // We clone because the line chart mutates the datetime for each point
  if (index === secondaryIndex) {
    // Set comparison secondary line
    return { data: cloneDeep(data), name, type: 'secondary' };
  }
  return { data: cloneDeep(data), name };
};

const generateYAxis = (metricUnit, format) => {
  const yAxis = {
    format,
  };

  if (metricUnit) {
    yAxis.unit = metricUnit;
  }

  return yAxis;
};

const lineTransform = (data, cfg) => {
  if (!data) {
    return {};
  }

  const { series, metric_type: metricType, metric_unit: metricUnit } = data;

  // Iris will return `null` for the `data` in each series if the dataset is empty
  // In that case we cannot render
  if (!series.length || series.some((s) => s.data === null)) {
    return {};
  }

  const format = pickFormat(metricType);
  const yAxis = generateYAxis(metricUnit, format);

  const context = {
    series: series.map(partial(transformSeries, cfg)),
    x_axis: {
      type: 'datetime',
    },
    y_axis: yAxis,
    format,
  };

  return context;
};

const transform = composeTransformers(lineTransform, genericLineTransform);

export { transform };
