import { isUndefined } from 'lodash';
import { parseNumberBasedOnFormat } from '../../../lib/parse-number';

function transform(
  payload,
  { minYAxis, maxYAxis } = {},
  timezone,
  initialContext = {},
) {
  const { format } = initialContext;
  const context = {};

  if (!isUndefined(minYAxis)) {
    context.minYAxis = parseNumberBasedOnFormat(minYAxis, format);
  }

  if (!isUndefined(maxYAxis)) {
    context.maxYAxis = parseNumberBasedOnFormat(maxYAxis, format);
  }

  return { ...initialContext, ...context };
}

export { transform };
