import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';
import { LOCATIONS } from '@Tracking/constants';

const sharedDashboardLoopSchemaProperties = {
  'Dashboard loop ID': SchemaTypes.string.isRequired,
};

export const dashboardLoopCreated = defineEvent(
  'Dashboard loop - Created',
  sharedDashboardLoopSchemaProperties,
);

export const dashboardLoopDeleted = defineEvent(
  'Dashboard loop - Deleted',
  sharedDashboardLoopSchemaProperties,
);

export const dashboardLoopRegeneratedSharingLink = defineEvent(
  'Dashboard loop - Regenerated sharing link',
  sharedDashboardLoopSchemaProperties,
);

export const dashboardLoopCopiedSharingLink = defineEvent(
  'Dashboard loop - Copied sharing link',
  {
    ...sharedDashboardLoopSchemaProperties,
    Location: SchemaTypes.oneOf([LOCATIONS.AdminLoops, LOCATIONS.Toast])
      .isRequired,
  },
);
