import { transform as genericNumberTransform } from '../per-visualisation/number';
import { composeTransformers } from '../compose-transformers';
import { get, first, last, isUndefined, isNil } from 'lodash';
import {
  getDatapointValue,
  getColumnFormat,
  formatDurationValue,
} from './helpers';

const extractNumberValue = (data) => {
  const { rows, columns } = data;

  if (!columns) return { value: [], format: undefined };

  const format = getColumnFormat(columns);

  if (!rows || rows.length === 0) return { value: [], format };

  return {
    format,
    value: first(rows).values.map(getDatapointValue).reverse(),
  };
};

const numberTransform = (payload) => {
  const data = get(payload, 'data.bauhausDataRequest.data');

  if (!data) {
    return {};
  }

  const { value, format } = extractNumberValue(data);

  if (isNil(last(value))) {
    return {};
  }

  return {
    value,
    ...format,
  };
};

const comparisonTransform = (payload, config, timezone, initialContext) => {
  const goal = get(config, 'goal');
  const comparison = get(config, 'bauhaus.comparison');

  if (!isUndefined(goal)) {
    return initialContext;
  }

  if (!comparison) {
    return initialContext;
  }

  if (comparison.display_as === 'PERCENTAGE') {
    return {
      ...initialContext,
      comparison: {
        type: 'percentage',
        reversed: comparison.direction === 'DOWN',
      },
    };
  }

  return {
    ...initialContext,
    comparison: {
      type: 'number',
      reversed: comparison.direction === 'DOWN',
    },
  };
};

const durationTransform = (_, __, ___, initialContext) => {
  const { format, unit, startingValue, threshold, value } = initialContext;

  if (format !== 'duration') {
    return initialContext;
  }

  return {
    ...initialContext,
    /*
     * the threshold and starting values saved in seconds, we must convert to ms
     * if we want to reuse the existing formatDurationValue helper
     */
    threshold: threshold && formatDurationValue(threshold * 1000, unit),
    startingValue:
      startingValue && formatDurationValue(startingValue * 1000, unit),
    value: value.map((v) => formatDurationValue(v, unit)),
  };
};

const transform = composeTransformers(
  numberTransform,
  genericNumberTransform,
  comparisonTransform,
  durationTransform,
);

const applyConfig = (data, config) => {
  return { ...data, numberFormat: config.numberFormat };
};

export { transform, applyConfig, extractNumberValue };
