/* eslint-disable func-style */
import fetchJSON, { pollJSON, FetchError } from '../fetch';
import config from '../config';
import 'isomorphic-fetch';

class SalesforceError extends FetchError {}

const POLLING_OPTS = {
  defaultPollInterval: 1000,
  maxNumberOfPolls: 60,
};

function fetchList(serviceName, { serviceAccountId = '', searchTerm } = {}) {
  const { PegasusBaseURL } = config.get();

  let path = `${PegasusBaseURL}/reports?service_account_id=${serviceAccountId}`;

  if (searchTerm) {
    const q = encodeURIComponent(searchTerm);
    path = `${PegasusBaseURL}/search_reports?service_account_id=${serviceAccountId}&q=${q}`;
  }

  return fetchJSON(path, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
}

function obtainUUID(serviceName, fileOptions) {
  const { PegasusBaseURL } = config.get();
  const { id, serviceAccountId } = fileOptions;

  return fetchJSON(
    `${PegasusBaseURL}/reports/${id}/import?service_account_id=${serviceAccountId}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    },
  );
}

function getMeta(uuid, refreshFile) {
  const { PegasusBaseURL } = config.get();
  let parameters = '';

  if (refreshFile === true) {
    // the presence of the parameter makes the backend already refresh
    parameters = '?refresh=true';
  }

  return fetchJSON(`${PegasusBaseURL}/reports/${uuid}/meta${parameters}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
}

function refreshReport(uuid, maxAge = 0, requestHeaders = {}) {
  const { PegasusBaseURL } = config.get();
  const headers = { ...requestHeaders, Accept: 'application/json' };

  return fetchJSON(
    `${PegasusBaseURL}/reports/${uuid}/refresh?max_age=${maxAge}`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
    },
  );
}

function fetchData(
  uuid,
  { xAxis = '', yAxis = '', series = '', columns, type } = {},
  requestHeaders = {},
) {
  const { PegasusBaseURL } = config.get();
  let params;
  const opts = {};

  if (type === 'table') {
    params = '?format=table';
  } else {
    params = `?x_axis=${xAxis}&y_axis=${yAxis}&series=${series}`;
  }

  // When fetching a table we have the option to limit the columns
  // returned by making a post with the query
  if (columns) {
    opts.body = JSON.stringify({ columns });
    opts.method = 'POST';
  }

  return pollJSON(
    `${PegasusBaseURL}/reports/${uuid}/data${params}`,
    { ...opts, headers: requestHeaders },
    POLLING_OPTS,
  );
}

function get(cfg, requestHeaders) {
  const { config: newConfig = {} } = cfg;

  return fetchData(newConfig.report_id, newConfig, requestHeaders).catch(
    ({ message, type, status }) => {
      throw new SalesforceError(message, status, type);
    },
  );
}

function getConfig({ config: cfg }) {
  return Promise.resolve(cfg);
}

export {
  fetchList,
  obtainUUID,
  getMeta,
  refreshReport,
  fetchData,
  get,
  getConfig,
  POLLING_OPTS,
  SalesforceError,
};
