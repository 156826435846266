import {
  LabelContentFormat,
  UtilityInstrumentForEditingQuery,
} from '@Generated/graphql';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type UtilityWidgetEditState = {
  isEditMode: boolean;
  initialized: boolean;
  instrumentId?: string;
  labelWidget?: LabelWidgetConfig;
  imageWidget?: ImageWidgetConfig;
  canUseMarkdown: boolean;
};

type LabelWidgetConfig = {
  messages: string[];
  focusedMessageIndex: number;
  contentFormat: LabelContentFormat;
};

type ImageWidgetConfig = {
  sourceType: 'link' | 'file';
  linkSource?: string;
  uploadedSource?: string;
};

export const initialState: UtilityWidgetEditState = {
  isEditMode: false,
  initialized: false,
  canUseMarkdown: false,
};

export const slice = createSlice({
  name: 'utilityWidgetEdit',
  initialState,
  reducers: {
    /** Initialises a utility widget */
    initFromGraphQLConfig: (
      state: UtilityWidgetEditState,
      action: PayloadAction<{
        instrument: NonNullable<UtilityInstrumentForEditingQuery['instrument']>;
        canUseMarkdown: boolean;
        isEditMode: boolean;
      }>,
    ) => {
      // Ensure we only initialize once to avoid accidentally clearing component
      // state on rerender, e.g. when editing a message, and then changing the widget title
      if (state.initialized) {
        return;
      }

      state.initialized = true;
      state.isEditMode = action.payload.isEditMode;
      state.instrumentId = action.payload.instrument.id;

      switch (action.payload.instrument.__typename) {
        case 'Label': {
          const { messages = [], contentFormat } = action.payload.instrument;
          const mappedMessages = messages.map((msg) => msg.text || '');
          if (!mappedMessages.length) {
            // When there are no messages, add an empty one to show a textarea
            mappedMessages.push('');
          }

          state.labelWidget = {
            messages: mappedMessages,
            contentFormat:
              contentFormat === 'PLAINTEXT' && action.payload.canUseMarkdown
                ? 'MARKDOWN'
                : contentFormat,
            focusedMessageIndex: 0,
          };

          break;
        }
        case 'Image': {
          const { source, isUploaded } = action.payload.instrument;

          state.imageWidget = {
            // Determine the sourcetype based on if the image was uploaded. If we don't
            // have an image yet, then default to file
            sourceType: isUploaded || !source ? 'file' : 'link',
            linkSource: isUploaded ? undefined : source,
            uploadedSource: isUploaded ? source : undefined,
          };
          break;
        }
        default:
          throw new Error(
            `${action.payload.instrument.__typename} type not supported when editing utility widget`,
          );
      }
    },

    /** Sets the text for one of the messages on a Label/Text widget */
    setMessage: (
      state: UtilityWidgetEditState,
      action: PayloadAction<{ value: string; index: number }>,
    ) => {
      if (!state.labelWidget) {
        throw new Error('Initialise config before setting messages');
      }

      const { index, value } = action.payload;
      state.labelWidget.messages[index] = value;
    },

    /** Add a new message to the Label/Text widget */
    addMessage(state: UtilityWidgetEditState) {
      state.labelWidget?.messages.push('');
    },
    deleteMessage(
      state: UtilityWidgetEditState,
      action: PayloadAction<number>,
    ) {
      if (!state.labelWidget) {
        return;
      }

      state.labelWidget.messages.splice(action.payload, 1);

      const lastMessageIndex = state.labelWidget.messages.length - 1;

      // Make sure the preview has a valid index to show and keep a focused text
      // when a deleted one is not focused.
      state.labelWidget.focusedMessageIndex = Math.min(
        state.labelWidget.focusedMessageIndex,
        lastMessageIndex,
      );
    },
    /** Set the index of the focused message textarea */
    setFocusedMessage(
      state: UtilityWidgetEditState,
      action: PayloadAction<number>,
    ) {
      state.labelWidget!.focusedMessageIndex = action.payload;
    },
    /** Sets the source type when editing an image widget */
    setImageSourceType(
      state: UtilityWidgetEditState,
      action: PayloadAction<'link' | 'file'>,
    ) {
      state.imageWidget!.sourceType = action.payload;
    },
    /** Sets the source URL when editing an image widget */
    setLinkImageSource(
      state: UtilityWidgetEditState,
      action: PayloadAction<string>,
    ) {
      state.imageWidget!.linkSource = action.payload;
    },
    /** Sets uploaded image source */
    setUploadedImageSource(
      state: UtilityWidgetEditState,
      action: PayloadAction<string | undefined>,
    ) {
      state.imageWidget!.uploadedSource = action.payload;
    },
    /** Clear everything when shutting down the config */
    closeConfigPanel(state: UtilityWidgetEditState) {
      delete state.imageWidget;
      delete state.labelWidget;
      state.initialized = false;
    },
  },
});

export const actions = {
  initFromGraphQLConfig: slice.actions.initFromGraphQLConfig,
  label: {
    setMessage: slice.actions.setMessage,
    addMessage: slice.actions.addMessage,
    deleteMessage: slice.actions.deleteMessage,
    setFocusedMessage: slice.actions.setFocusedMessage,
  },
  image: {
    setSourceType: slice.actions.setImageSourceType,
    setLinkSource: slice.actions.setLinkImageSource,
    setUploadedImageSource: slice.actions.setUploadedImageSource,
  },
  closeConfigPanel: slice.actions.closeConfigPanel,
};

export type RootState = {
  utilityWidgetEdit: UtilityWidgetEditState;
  [key: string]: unknown;
};

const store = (state: RootState): UtilityWidgetEditState =>
  state.utilityWidgetEdit;

export const selectors = {
  shouldHighlightInstrumentPosition:
    (instrumentId?: string) => (state: RootState) =>
      selectors.isEditing(instrumentId)(state) && !store(state).isEditMode,

  isEditing: (instrumentId?: string) => (state: RootState) => {
    return (
      instrumentId === store(state).instrumentId &&
      Boolean(selectors.isEditingType(state))
    );
  },
  isEditingAWidget: (state: RootState) =>
    Boolean(selectors.isEditingType(state)),
  isEditingType: (state: RootState) => {
    if (store(state).labelWidget) {
      return 'label';
    }
    if (store(state).imageWidget) {
      return 'image';
    }
    return undefined;
  },
  label: {
    /**
     * Grab the complete set of messages for persisting
     */
    messages: (state: RootState) => store(state).labelWidget?.messages ?? [],
    contentFormat: (state: RootState) =>
      store(state).labelWidget?.contentFormat || 'HTML',

    /** Config for saving back to Concierge */
    configToSave: (state: RootState) => {
      return {
        contentFormat: selectors.label.contentFormat(state),
        messages: selectors.label
          .messages(state)
          .filter((text) => text !== '')
          .map((text) => ({
            text,
          })),
      };
    },
  },
  /**
   * Configuration for an Image Utility widget
   */
  image: {
    sourceType: (state: RootState) =>
      store(state).imageWidget?.sourceType || 'file',

    linkSource: (state: RootState) =>
      store(state).imageWidget?.linkSource || '',

    userUploadSource: (state: RootState) =>
      store(state).imageWidget?.uploadedSource || '',

    /** Config for saving back to Concierge */
    configToSave: (state: RootState) => {
      return {
        source:
          selectors.image.sourceType(state) === 'link'
            ? selectors.image.linkSource(state)
            : selectors.image.userUploadSource(state),
      };
    },
  },
  /** Config for live previews to be merged onto saved config */
  configOverrides: (state: RootState): Record<string, unknown> | null => {
    const { labelWidget, imageWidget } = store(state);

    if (labelWidget) {
      let text = labelWidget.messages[labelWidget.focusedMessageIndex];

      if (
        labelWidget.contentFormat !== 'MARKDOWN' &&
        MAYBE_HTML_REGEX.test(text)
      ) {
        text = 'Live preview has been disabled due to HTML content';
      }

      if (text === '') {
        text = 'Start writing to preview your message';
      }

      return {
        contentFormat: labelWidget.contentFormat,
        messages: [{ type: 'TEXT', text }],
      };
    }

    if (imageWidget) {
      return selectors.image.configToSave(state);
    }

    return null;
  },
};

const MAYBE_HTML_REGEX = /<[?a-z]/i;

export default slice.reducer;
