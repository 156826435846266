import { reject } from 'lodash';
import * as manageScreensActions from '../actions/manage-screens-actions';
import { formatMessage } from '../../lib/i18n';

const initialState = {
  isModalOpen: false,
  isConnecting: false,
  currentView: undefined,
  isFetchingScreens: false,
  error: undefined,
};

const manageScreensReducer = (state = initialState, action) => {
  const { type, payload } = action;

  let screens;

  switch (type) {
    case manageScreensActions.setView.type:
      return {
        ...state,
        currentView: payload,
        isModalOpen: true,
        error: undefined,
      };

    case manageScreensActions.openModal.type:
      return {
        ...state,
        currentView: 'manage',
        isModalOpen: true,
        error: undefined,
      };

    case manageScreensActions.closeModal.type:
      return {
        ...state,
        currentView: undefined,
        isModalOpen: false,
        error: undefined,
      };

    case manageScreensActions.openUpgradeModal.type:
      return {
        ...state,
        currentView: 'upgrade',
        isModalOpen: true,
      };

    case manageScreensActions.setScreens.type:
      screens = payload || [];

      return {
        ...state,
        screens,
        currentView: screens.length === 0 ? 'add' : state.currentView,
      };

    case manageScreensActions.fetchScreensStart.type:
      return {
        ...state,
        isFetchingScreens: true,
        error: undefined,
      };

    case manageScreensActions.fetchScreensSuccess.type:
      screens = payload || [];

      return {
        ...state,
        screens,
        isFetchingScreens: false,
        currentView: screens.length === 0 ? 'add' : state.currentView,
      };

    case manageScreensActions.fetchScreensFailed.type:
      return {
        ...state,
        isFetchingScreens: false,
        error: formatMessage('screens.errorFetchingScreens'),
      };

    case manageScreensActions.connectScreenStart.type:
      return {
        ...state,
        isConnecting: true,
        error: undefined,
      };

    case manageScreensActions.connectScreenSuccess.type:
      return {
        ...state,
        isConnecting: false,
        isModalOpen: false,
      };

    case manageScreensActions.connectScreenFailed.type:
      return {
        ...state,
        isConnecting: false,
        error: payload.message,
      };

    case manageScreensActions.deleteScreenStart.type:
      screens = reject(state.screens, (screen) => screen.id === payload);

      return {
        ...state,
        screens,
        currentView: screens.length === 0 ? 'add' : state.currentView,
      };

    case manageScreensActions.setScreenNameStart.type:
      screens = state.screens.map((screen) => {
        if (screen.id === payload.id) {
          return {
            ...screen,
            name: payload.name,
          };
        }

        return screen;
      });

      return {
        ...state,
        screens,
      };

    case manageScreensActions.setScreenConnectionStart.type:
      screens = state.screens.map((screen) => {
        if (screen.id === payload.id) {
          return {
            ...screen,
            content: {
              id: payload.contentId,
              legacyId: payload.contentLegacyId,
              type: payload.type,
            },
          };
        }

        return screen;
      });

      return {
        ...state,
        screens,
      };

    default:
      return state;
  }
};

export default manageScreensReducer;
