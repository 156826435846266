import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';
import { DashboardContext } from '@Tracking/contexts';
import { VISUALISATION_TYPES } from '@Tracking/constants';

const sharedWidgetSchemaProperties = {
  'Instrument ID': SchemaTypes.string,
  'Legacy widget ID': SchemaTypes.string.isRequired,
  Visualisation: SchemaTypes.oneOf(VISUALISATION_TYPES).isRequired,
  'Widget type ID': SchemaTypes.string,
  'Widget type name': SchemaTypes.string,
  'Integration name': SchemaTypes.string.isRequired,
  'Integration slug': SchemaTypes.string.isRequired,
};

export const widgetMovedOffCanvas = defineEvent(
  'Widget - Moved off canvas',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetMovedToCanvas = defineEvent(
  'Widget - Moved to canvas',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetCreated = defineEvent(
  'Widget - Created',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetCopied = defineEvent(
  'Widget - Copied',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetDeleted = defineEvent(
  'Widget - Deleted',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetConfigEdited = defineEvent(
  'Widget - Config edited',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetIndicatorsAdded = defineEvent(
  'Widget - Indicators added',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetIndicatorsEdited = defineEvent(
  'Widget - Indicators edited',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetIndicatorsDeleted = defineEvent(
  'Widget - Indicators deleted',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetNumberFormattingEdited = defineEvent(
  'Widget - Number formatting edited',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetGoalAdded = defineEvent(
  'Widget - Goal added',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetGoalEdited = defineEvent(
  'Widget - Goal edited',
  sharedWidgetSchemaProperties,
  DashboardContext,
);

export const widgetGoalRemoved = defineEvent(
  'Widget - Goal removed',
  sharedWidgetSchemaProperties,
  DashboardContext,
);
