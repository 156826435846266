import * as instrumentDataActions from '../actions/instrument-data-actions';
import * as universalConfigSaveActions from '../../../universal-config/actions/universal-config-save-actions';

const initialState = {};

const instrumentDataReducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case instrumentDataActions.dataReceived.type:
      return {
        ...state,
        [payload.instrumentId]: {
          payload: payload.data,
          dataUpdatedAt: payload.dataUpdatedAt,
          isDataFromCache: payload.isDataFromCache,
          dataError: null,
          isLoading: false,
        },
      };

    case instrumentDataActions.dataError.type: {
      const instrument = state[payload] || {};

      return {
        ...state,
        [payload]: {
          ...instrument,
          payload: null,
          dataError: error,
        },
      };
    }

    // Update instrument data when the config has been updated in the universal UI.
    // That way the instrument can show the fresh data, that was fetched while
    // editing the widget.
    case universalConfigSaveActions.configUpdated.type: {
      const instrument = state[payload.instrumentId] || {};

      return {
        ...state,
        [payload.instrumentId]: {
          ...instrument,
          payload: payload.data,
          dataError: undefined,
        },
      };
    }

    case instrumentDataActions.setIsLoading.type: {
      const instrument = state[payload.instrumentId] || {};

      return {
        ...state,
        [payload.instrumentId]: {
          ...instrument,
          isLoading: true,
        },
      };
    }

    default:
      return state;
  }
};

export default instrumentDataReducer;
