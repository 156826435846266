import moment from 'moment-timezone';
import { truncate } from '../../lib/timespan';
import { trim } from 'lodash';

export const getIncompleteFromNow = (timezone, unit) => {
  const now = timezone ? moment.tz(timezone) : moment();
  const start = now.clone();

  truncate(unit, start);

  return start.format();
};

export const getDisplayValue = (value) => {
  if (value && typeof value === 'object') {
    return getDisplayValue(value.label);
  }

  if (trim(value) === '') {
    return 'No value';
  }

  return value;
};
