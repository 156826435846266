import localForage from 'localforage';
import createAction from '../../lib/redux/create-action';
import { map, compact } from 'lodash';

export const setImports = createAction('Imports:SET_IMPORTS');
export const hideImport = createAction('Imports:HIDE_IMPORT');
export const queueServiceAccountId = createAction(
  'Imports:QUEUE_SERVICE_ACCOUNT_ID',
);

export const importsReceived = (imports) => async (dispatch) => {
  // Filter out any imports that have already been dismissed by this user (stored in localStorage for now)
  const activeImports = await Promise.all(
    map(imports, async (i) => {
      const storedImport = await localForage.getItem(`universalImport:${i.id}`);

      return !!storedImport && storedImport.acknowledged ? undefined : i;
    }),
  );

  dispatch(setImports(compact(activeImports)));
};
