import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';

export const welcomeSurveySubmitted = defineEvent(
  'Welcome survey - Submitted',
  {
    Role: SchemaTypes.string,
    'Job title': SchemaTypes.string,
    'Expected frequency': SchemaTypes.oneOf([
      'Several times a day',
      'Daily',
      'Weekly',
      'Monthly',
      'Less often',
    ]),
    'Expected integrations': SchemaTypes.listOfStrings,
  },
);
