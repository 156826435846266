import { isUndefined, isString, isFinite } from 'lodash';

const parseNumberBasedOnFormat = (value, format) => {
  if (!isString(value) && !isFinite(value)) {
    // __DEV__ doesn't exist in megatron at the moment!
    if (
      typeof __DEV__ !== 'undefined' &&
      __DEV__ &&
      !isUndefined(value) &&
      value !== null
    ) {
      console.warn(
        'Unexpected value passed to `parseNumberBasedOnFormat`, was expecting a string.',
        value,
      );
    }

    return undefined;
  }

  let number = value;

  if (isString(number)) {
    number = parseFloat(value.replace(/[^0-9-.E]/gi, ''));
  }

  if (isNaN(number)) {
    return undefined;
  }

  if (format === 'percent') {
    return number / 100;
  }

  return number;
};

export { parseNumberBasedOnFormat };
