import { createServiceAccount } from './management-service';
import { auth } from '../lib/oauth';
import { open as openPopup } from '../lib/popup-window';

const createConnection = async (service, extraParams) => {
  if ('basic' === service.auth_type) {
    const serviceAccount = await createServiceAccount(
      service.name,
      extraParams,
    );

    return serviceAccount.id;
  }

  return auth(openPopup(), service, extraParams);
};

export { createConnection };
