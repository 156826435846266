import { isNil, get } from 'lodash';

// return the "most represented column type"
const getColumnType = (cells) => {
  // first, we only keep rows with non-nil values
  const filteredRows = cells.filter((cell) => !isNil(get(cell, 'value')));

  /*
   * Then, we'll do a bit of complex manipulations in order to pop the dominant
   * type for a row.
   *
   * To achieve this, we'll reduce the filteredRows and create an object that
   * will hold { type: number } so for example, a row with two number and one
   * date will give us { number: 2, date: 1 }
   *
   * Then, we transform this object into an array of [type, number] so in that
   * previous example, we'll get [[number, 2], [date, 1]]
   * Then, we'll sort this, we'll get [[date, 1], [number, 2]]
   *
   * And finally, we pop the last, which is [number, 2] and we get the first
   * entry which is 'number'!
   */
  const types = Object.entries(
    filteredRows.reduce((acc, { type }) => {
      if (!acc[type]) acc[type] = 0;
      acc[type] += 1;
      return acc;
    }, {}),
  ).sort((a, b) => a[1] - b[1]);

  return get(types.pop(), 0) || 'none';
};

export { getColumnType };
