import d3 from 'd3';
import { find, some, chain } from 'lodash';
import moment from 'moment-timezone';

/**
 * Date/time management is really specific in geckoland:
 * Rules are the following:
 * - Any input datetime containing TZ will be converted to the supplied TZ (e.g Dashboad TZ)
 * - If no TZ in input, display data as it is! No matter the dashboard TZ or browser TZ
 *
 * To simplify datetime manipulation this module take care of applying these
 rules. ALWAYS use UTC for displaying or extra manipulation
**/

const tzRegex = /Z|[-+]/;

function hasTZ(datetime) {
  const [, time] = datetime.split('T');
  return tzRegex.test(time);
}

function getParseFormat(datetime) {
  const componentCount = datetime.split(/-| |:/).length;

  switch (componentCount) {
    case 1:
      return 'YYYY';
    case 2:
      return 'YYYY-MM';
    default:
      return null;
  }
}

function normalise(datetime, timezone = 'UTC') {
  let date;
  const parseFormat = getParseFormat(datetime);
  if (hasTZ(datetime)) {
    date = moment.tz(datetime, parseFormat, timezone);
  } else {
    date = moment(datetime, parseFormat);
  }

  return new Date(
    Date.UTC(
      date.year(),
      date.month(),
      date.date(),
      date.hours(),
      date.minutes(),
      date.seconds(),
    ),
  );
}

function pickFormat(ticks) {
  if (ticks.length === 0) {
    return '';
  }

  const rules = [
    ['.%L', (d) => d.getUTCMilliseconds()],
    [':%S', (d) => d.getUTCSeconds()],
    ['%H:%M', (d) => d.getUTCMinutes()],
    ['%H:%M', (d) => d.getUTCHours()],
    ['%e %b', (d) => d.getUTCDate() !== 1],
    ['%b', (d) => d.getUTCMonth()],
    ['%Y', () => true],
  ];

  return find(rules, ([, rule]) => some(ticks, rule))[0];
}

function pickBestFormatForDuration(ticks, timezone = 'UTC') {
  const now = moment().tz(timezone);
  const today = now.format('YYYYMMDD');
  const thisYear = now.format('YYYY');
  const [hours, days, years] = chain(ticks)
    .map((tick) => moment(tick).utc())
    .map((m) => [
      m.format('YYYYMMDDHH'),
      m.format('YYYYMMDD'),
      m.format('YYYY'),
    ])
    .unzip()
    .value();

  // If all ticks are today
  if (days.every((day) => day === today)) {
    // If all ticks are within the same hour
    // then display hours, minutes and seconds
    if (hours.every((hour) => hour === hours[0])) {
      return '%H:%M:%S';
    }

    // otherwise display hours and minutes
    return '%H:%M';
  }

  // if all ticks this year just display day and month
  if (years.every((year) => year === thisYear)) {
    return '%e %b';
  }

  // Otherwise display the day, month and year
  return '%e %b %Y';
}

function pickTooltipFormat(ticks) {
  if (ticks.length === 0) {
    return '';
  }

  const rules = [
    ['%H:%M:%S.%L - %e %b %Y', (d) => d.getUTCMilliseconds()],
    ['%H:%M:%S - %e %b %Y', (d) => d.getUTCSeconds()],
    ['%H:%M - %e %b %Y', (d) => d.getUTCMinutes()],
    ['%H:%M - %e %b %Y', (d) => d.getUTCHours()],
    ['%e %b %Y', (d) => d.getUTCDate() !== 1],
    ['%b %Y', (d) => d.getUTCMonth()],
    ['%Y', () => true],
  ];

  return find(rules, ([, rule]) => some(ticks, rule))[0];
}

function prettyDateFormat(dateStrings, timezone = 'UTC') {
  const dates = dateStrings.map((str) => normalise(str, timezone));
  const format = pickFormat(dates);
  const formatter = d3.time.format.utc(format);

  return dates.map((date) =>
    date.toString() === 'Invalid Date' ? '' : formatter(date),
  );
}

function prettyDateFormatForDuration(dateStrings, timezone = 'UTC') {
  const dates = dateStrings.map((str) => normalise(str, timezone));
  const format = pickBestFormatForDuration(dates, timezone);
  const formatter = d3.time.format.utc(format);

  return dates.map((date) =>
    date.toString() === 'Invalid Date' ? '' : formatter(date),
  );
}

function prettyDateOnlyFormat(dateStrings, timezone = 'UTC') {
  const dates = dateStrings.map((str) => normalise(str, timezone));
  const format = '%e %b %Y';
  const formatter = d3.time.format.utc(format);

  return dates.map((date) =>
    date.toString() === 'Invalid Date' ? '' : formatter(date),
  );
}

function prettyDateAndTimeFormat(dateString, timezone = 'UTC') {
  const date = normalise(dateString, timezone);
  const format = '%e %b %Y %H:%M:%S';
  const formatter = d3.time.format.utc(format);

  return date.toString() === 'Invalid Date' ? '' : formatter(date);
}

export {
  prettyDateFormat as default,
  prettyDateAndTimeFormat,
  normalise,
  pickFormat,
  pickTooltipFormat,
  pickBestFormatForDuration,
  prettyDateFormatForDuration,
  prettyDateOnlyFormat,
};
