/**
 * Visualisation conformer
 *
 * Converts a visualisation type into valid tracking properties.
 *
 * It handles one edge case where Roadie text instruments are actually
 * called "Labels". For consistency we want to consider them "text" widgets.
 *
 * @param {string} vizType - The visualisation type string.
 *
 * @returns {Object} - A sanitized version of the viz type that the event schema expects.
 */
function visualisationConform(vizType) {
  if (!vizType) {
    console.warn(`
      No visualisation type was passed to the visualisation conformer.
      Make sure you're sending a valid visualisation type with the tracking event.
    `);

    return {};
  }

  const Visualisation = vizType.toLowerCase();

  if (Visualisation === 'label') {
    return { Visualisation: 'text' };
  }

  return { Visualisation };
}

export default visualisationConform;
