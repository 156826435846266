import { get } from 'lodash';
import themes from '../../';

const getWidgetColor = (themeName) => {
  const theme = get(themes, themeName, {});

  return theme['--widget-background-color'];
};

export default getWidgetColor;
