import {
  chain,
  cloneDeep,
  filter,
  find,
  forEach,
  forOwn,
  includes,
  intersectionWith,
  isArray,
  pick,
  template,
  uniq,
  zipObject,
  without,
} from 'lodash';
import { formatMessage } from '../lib/i18n';
import { runQueryInContext } from './query-helpers';
import { TYPE_TO_GROUP } from './ui-field-options';

const BASE_NODE_PROPS = ['key', 'type', 'name', 'values', 'unit'];

export class Node {
  constructor(node) {
    Object.assign(this, node);

    if (this.query_template_for_values) {
      this.query_custom_values = JSON.parse(
        template(this.query_template_for_values)(node),
      );
    }
  }

  getRoot() {
    if (this.root) {
      return this.root;
    }

    return this.primary_resource;
  }

  getPath(useFK) {
    if (this.type === 'count') return null;
    const path = [];
    if (this.virtual_join) {
      path.push(this.virtual_join);
    }
    if (useFK && this.foreign_key) path.push(this.foreign_key);
    else if (!useFK && this.target_key) {
      path.push(this.key, this.target_key);
    } else {
      path.push(this.key);
    }

    return path.join('.');
  }

  getCustom() {
    return this.custom;
  }

  isCustomField() {
    return this.category === Node.CUSTOM_FIELD;
  }

  getResourceName() {
    if (this.resource) return this.resource;
    if (this.values_from) return this.values_from.resource;
    if (this.query_custom_values) return this.key;
    return null;
  }

  formatKey(value) {
    if (this.values_from) {
      return value[this.values_from.key];
    }

    if (this.foreign_key) {
      return value[this.primary_key];
    }

    return value.id;
  }

  formatValue(value) {
    if (this.values_from) {
      return value[this.values_from.key];
    }

    if (this.foreign_key) {
      return value[this.target_key];
    }

    return value.name;
  }

  getValues(collection, grouped = false) {
    if (this.values) return this.values;
    const data = collection[this.getResourceName()];
    if (!data) return null;

    if (grouped && this.groupValues) {
      // /!\ This returns a nested array
      const groupedOptions = [];
      chain(data)
        .groupBy(this.groupValues)
        .forIn((options, name) => {
          groupedOptions.push({
            name,
            options: options.map((t) => ({
              key: this.formatKey(t),
              value: this.formatValue(t),
              ...this.getValueExtras(t),
            })),
          });
        })
        .value();

      return groupedOptions;
    }

    return data.map((t) => ({
      key: this.formatKey(t),
      value: this.formatValue(t),
      ...this.getValueExtras(t),
    }));
  }

  getValueExtras(value) {
    const extras = {};
    if (this.combined_filters) {
      extras.combinedFilters = this.combined_filters.map((cf) => cf(value));
    }
    return extras;
  }

  getMatchingValue(collection, q) {
    const values = this.getValues(collection);
    return find(values, { key: q });
  }

  getQueryForValue() {
    if (this.query_custom_values) {
      return this.query_custom_values;
    }
    if (this.values_from) {
      return {
        select: [
          {
            path: this.values_from.key,
            resource: this.values_from.resource,
          },
        ],
      };
    }
    if (this.foreign_key) {
      const tk = isArray(this.target_key) ? this.target_key : [this.target_key];
      return {
        select: [
          { path: this.primary_key, resource: this.resource },
          ...tk.map((p) => ({ path: p, resource: this.resource })),
        ],
      };
    }
    return null;
  }

  hasValues() {
    return !!(
      this.type === 'enum' ||
      this.foreign_key ||
      this.values_from ||
      this.query_custom_values
    );
  }

  hasGroupedValues() {
    return !!this.groupValues;
  }

  isEqualTo(node) {
    const { primary_resource: resource, key, custom } = node;
    const possibleIds = [];
    if (this.equivalents && this.equivalents[resource]) {
      possibleIds.push(`${resource}:${this.equivalents[resource]}`);
    }

    possibleIds.push(`${this.primary_resource}:${this.custom || this.key}`);

    return possibleIds.indexOf(`${resource}:${custom || key}`) !== -1;
  }
}

Node.CUSTOM_FIELD = 'Custom field';
Node.DATETIME = 'Datetime';

export const getDetailsNode = (graph, resource) => {
  const entryNode = find(graph, { root: resource });
  return find(entryNode.output, { type: 'details' });
};

export const getNodeFromSubquery = (graph, subquery, tryFK = false) => {
  const { resource, path, custom } = subquery;
  const entryNode = find(graph, { root: resource });
  const requestedPath = custom || path;

  if (!path && !custom) {
    // case of countable resource.
    return entryNode;
  }

  const requestedNode = find(entryNode.output, (n) => {
    if (custom) {
      return n.getCustom() === custom;
    }
    return n.getPath(tryFK) === requestedPath;
  });

  // In the event no node is found, we should give a helpful warning in the console. Often, a node
  // will not be found, but execution will continue as normal, causing confusing / broken states.

  // However, I am reluctant to throw an error here as it's possible the config will recover from these
  // failure states more often than not. A warning will at least highlight that there is a problem and
  // allow us to track it down quickly if we need to.
  if (!requestedNode) {
    const failedPath = path ? `${resource}.${path}` : resource;
    console.warn(
      `Universal attempted to find a node via the resource / path: ${failedPath}, but was unable to find a matching node.`,
    );
  }

  return requestedNode;
};

export const buildBaseSubquery = (graph, entry, inputNode, tryFK = false) => {
  const path = inputNode.getPath(tryFK);
  const subquery = { resource: entry };
  if (path) {
    subquery.path = path;
  }

  if (inputNode.getCustom()) {
    subquery.custom = inputNode.getCustom();
  }

  return subquery;
};

// Extend graph with custom fields. Use the specified query to fetch
// extra fields and merge them with correct format with original fields.
// DOES NOT RETURN BUT MUTATE!
export const injectCustomFieldsInGraph = async (graph, universalState) => {
  const roots = filter(graph, 'root');
  const allCustomNodes = [];

  await Promise.all(
    roots.map((root) => {
      const { custom_fields_queries: customQueries } = root;
      if (!customQueries) {
        return null;
      }
      return Promise.all(
        customQueries.map(({ query }) => {
          return runQueryInContext(query, universalState);
        }),
      ).then((queriesResults) => {
        queriesResults.forEach((results, i) => {
          if (!results) {
            return;
          }

          const customQuery = customQueries[i];
          const {
            query: { select: selectQuery },
          } = customQuery;
          const fieldTemplates = customQueries[i].field_templates || [];

          const customNodes = results.reduce((nodes, result) => {
            const fields = [];

            fieldTemplates.forEach((fieldTemplate) => {
              // Default field properties, can be overridden by field_templates
              const field = {
                type: 'enum',
                category: Node.CUSTOM_FIELD,
              };

              // Map query paths to fetched values
              const templateValues = zipObject(
                selectQuery.map((s) => s.path),
                result,
              );

              // Add each prop from template to field
              forEach(fieldTemplate, (value, prop) => {
                // When prop is a string, compile template, else pass prop to
                // field as is. Don't compile query_template_for_values as this
                // is done in Node.
                if (
                  typeof value === 'string' &&
                  prop !== 'query_template_for_values'
                ) {
                  field[prop] = template(value)(templateValues);
                } else {
                  field[prop] = value;
                }
              });

              // For some integrations, we want to provide the list of supported
              // values for a given field. In order to support that feature
              // we will consider the last tuple to be the list of values if
              // this one is an array.
              const fieldValues = result[result.length - 1];
              if (isArray(fieldValues)) {
                field.values = fieldValues.map((s) => ({ key: s, value: s }));
              }

              fields.push(field);
            });

            const newNodes = fields.map((f) => {
              return new Node({
                primary_resource: root.root,
                ...f,
              });
            });

            return [...nodes, ...newNodes];
          }, []);

          root.output.push(...customNodes);
          allCustomNodes.push(...customNodes);
        });
      });
    }),
  ).then(() => {
    graph.push(...allCustomNodes);
  });
};

// Add relation between resources
// DOES NOT RETURN BUT MUTATE!
export const addVirtualFields = (graph) => {
  const roots = filter(graph, 'root');
  const rootsWithRel = filter(roots, (r) => r.relations);

  // Collect and create new node following relation
  const extraNodes = {};
  rootsWithRel.forEach((r) => {
    r.relations.forEach((relation) => {
      const targetedResource = find(roots, { root: relation.res });
      extraNodes[r.root] = chain(targetedResource.output)
        .filter((n) => {
          if (
            !r.equivalent_fields ||
            !n.key ||
            !r.equivalent_fields[relation.res]
          ) {
            return true;
          }
          return !r.equivalent_fields[relation.res][n.key];
        })
        .map((n) => {
          return new Node({
            ...cloneDeep(n),
            input: [r],
            virtual_join: relation.name,
          });
        })
        .value();
    });
  });

  // Append new node to each root
  rootsWithRel.forEach((r) => {
    r.output.push(...extraNodes[r.root]);
  });
  // Append all new node ref to base graph
  forOwn(extraNodes, (nodes) => graph.push(...nodes));

  // recompute rules
  graph.forEach((node) => {
    forOwn(node.rules, (rules, ruleName) => {
      if (node[ruleName]) {
        // Node has been updated already
        return;
      }
      node[ruleName] = rules.map((rule) => {
        if (!rule) return null;
        const siblings = node.input[0].output;
        return find(siblings, (n) => n.isEqualTo(rule));
      });
    });
  });
};

export const buildGraph = (metadata) => {
  const graph = [];
  const { resources = [], equivalent_fields: allEquivalentFields = {} } =
    metadata;

  const nodesWithBase = [];
  const foreignNodes = [];
  const nodesWithColumns = [];

  resources.forEach((res) => {
    const entry = new Node({
      root: res.name,
      primary_key: res.primary_key,
      custom_fields_queries: res.custom_fields_queries,
      relations: res.rel,
      equivalent_fields: allEquivalentFields[res.name],
    });

    if (res.countable) {
      entry.type = 'count';
      entry.name = formatMessage('universal.config.metric.count', {
        value: res.human_readable_name,
      });
    }
    const children = [];

    const allFields = [...res.fields];

    if (res.details) {
      allFields.push({
        ...res.details,
        type: 'details',
      });
    }

    allFields.forEach((field) => {
      const node = new Node({
        primary_resource: res.name,
        ...field,
        input: [entry],
      });

      children.push(node);

      if (node.base) {
        nodesWithBase.push(node);
      }
      if (node.foreign_key) {
        foreignNodes.push(node);
      }
      if (node.default_columns) {
        nodesWithColumns.push(node);
      }
      if (!node.category && node.type === 'datetime') {
        // Group all datetime fields together in dropdowns
        node.category = Node.DATETIME;
      }
    });

    entry.output = children;
    graph.push(entry, ...children);
  });

  // Copy all properties from base node
  nodesWithBase.forEach((node) => {
    const baseNode = getNodeFromSubquery(graph, node.base);
    const baseNodeProps = pick(baseNode, BASE_NODE_PROPS);
    const props = { ...baseNodeProps, ...node, base: baseNode };
    Object.assign(node, props);
  });

  // Add primary key to all foreignKey
  foreignNodes.forEach((node) => {
    const targetResource = getNodeFromSubquery(graph, {
      resource: node.resource,
    });
    const props = { ...node, primary_key: targetResource.primary_key };
    Object.assign(node, props);
  });

  nodesWithColumns.forEach((node) => {
    node.defaultColumns = node.default_columns.map((column) => {
      const siblings = node.input[0].output;
      return find(siblings, (n) => n.isEqualTo(column));
    });
  });

  return graph;
};

const traverse = (node, visited = []) => {
  if (visited.indexOf(node) !== -1) {
    return null;
  }
  visited.push(node);

  return node.output ? node.output.map((n) => traverse(n, visited)) : node;
};

const removeBaseFields = (fields) => {
  const baseFields = fields.filter((f) => !!f.base).map((f) => f.base);
  return without(fields, ...baseFields);
};

export const getFieldsFromEntry = (graph, entry) => {
  const entryNode = find(graph, { root: entry });

  const fields = chain(traverse(entryNode)).flattenDeep().compact().value();

  const withoutBaseFields = removeBaseFields(fields);
  return withoutBaseFields;
};

export const getMetricFields = (graph, types) => {
  const roots = filter(graph, 'root');

  const fields = chain(roots)
    .reduce((nodes, n) => {
      if (n.type) {
        // root node with type should be added
        nodes.push(n);
      }
      const nodesFromRoot = chain(traverse(n)).compact().value();
      nodes.push(nodesFromRoot);
      return nodes;
    }, [])
    .flatten()
    .filter(
      (n) =>
        !n.virtual_join && types.includes(n.type) && !n.exclude_from_metrics,
    )
    .value();

  return removeBaseFields(fields);
};

export const getFieldsByType = (
  graph,
  types,
  entries,
  vizType,
  predicateFn = () => true,
) => {
  // We're filtering out hidden fields as this is currently only used from
  // ui-options. If we want to use this in other places we should think about
  // refactoring and making it possible to return hidden fields too.
  const uniqEntries = uniq(entries);
  const allCandidateNodes = uniqEntries.map((entry) => {
    const root = find(graph, { root: entry });
    return root.output.filter(
      (node) =>
        (!node.supported_visualisations ||
          includes(node.supported_visualisations, vizType)) &&
        includes(types, node.type) &&
        !node.hidden &&
        predicateFn(node),
    );
  });
  // compute intersection of equivalents node
  // this is expensive...
  let nodes = allCandidateNodes;
  if (uniqEntries.length > 1) {
    nodes = intersectionWith(...allCandidateNodes, (a, b) => a.isEqualTo(b));
  }

  return chain(nodes)
    .flatten()
    .sortBy(({ type }) => types.indexOf(type))
    .value();
};

export const getChildrenFromEntry = (graph, entryResource) => {
  const root = find(graph, { root: entryResource });
  return root.output;
};

export const getCompatibleMetrics = (
  metrics,
  metricType,
  vizType,
  entryResource,
) => {
  return filter(
    metrics,
    ({ type, supported_visualisations: sv, primary_resource: pm }) => {
      const compatibleViz = sv ? sv.includes(vizType) : true;
      const compatibleResource = pm === entryResource;
      return (
        compatibleViz &&
        compatibleResource &&
        TYPE_TO_GROUP[type] === TYPE_TO_GROUP[metricType]
      );
    },
  );
};
