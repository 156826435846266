import { transform as genericFeedTransform } from '../per-visualisation/feed';
import { composeTransformers } from '../compose-transformers';
import { DEFAULT_LABEL, getFormat } from './helpers';
import universalConfig from '../../config';
import { find, isNull, some } from 'lodash';

const UNIVERSAL_ASSETS_FOLDER = 'universal';

const feedTransform = (payload, config = {}, timezone, initialContext = {}) => {
  if (!payload || !payload.length) {
    return {};
  }

  const { queryMetas: [{ select = [] } = {}] = [], integrationName } = config;

  let data = payload;
  if (some(select, (s) => s.type === 'enum' || s.type === 'image')) {
    const fmtFunctions = select.map((n) => {
      let fn = (v) => v; // default identity

      if (n.type === 'enum') {
        if (n.iconSuffix) {
          // Apply icon path
          fn = (v) => {
            const path = [
              `/${UNIVERSAL_ASSETS_FOLDER}`,
              integrationName,
              n.primary_resource,
              n.key,
              isNull(v) ? 'null' : v,
            ];
            return `${path.join('/')}${n.iconSuffix}`;
          };
        } else {
          // Use values from enum values
          fn = (v) => {
            const newValue = find(n.values, (t) => t.key === v);
            return newValue ? newValue.value : DEFAULT_LABEL;
          };
        }
      } else if (n.type === 'image') {
        const placeholderPath = [
          universalConfig.STATIC_ASSET_URL,
          UNIVERSAL_ASSETS_FOLDER,
          integrationName,
          n.primary_resource,
          n.key,
          'placeholder.svg',
        ].join('/');

        fn = (v) => {
          return v || placeholderPath;
        };
      }
      return fn;
    });

    data = data.map((row) =>
      row.map((field, index) => fmtFunctions[index](field)),
    );
  }

  const visualisationConfig = {
    data,
    columns: select.map((m) => {
      const col = {};
      if (!['string', 'enum'].includes(m.type)) {
        return { ...col, ...getFormat(m, config, true) };
      }

      if (m.iconSuffix) {
        return { ...col, format: 'icon' };
      }

      return col;
    }),
  };

  return { ...initialContext, ...visualisationConfig };
};

const transform = composeTransformers(feedTransform, genericFeedTransform);

export { transform };
