import { merge } from 'lodash';
import getVisualisationSerializer from './visualisations';
import getServiceSerializer from './services';

function serialize(service, visualisation, config) {
  const serviceSerializer = getServiceSerializer(service);

  if (service === 'salesforce') {
    return serviceSerializer.serialize(config);
  }

  const visualisationSerializer = getVisualisationSerializer(visualisation);

  return merge(
    serviceSerializer.serialize(config),
    visualisationSerializer.serialize(config),
  );
}

function deserialize(service, visualisation = 'line', json) {
  const serviceSerializer = getServiceSerializer(service);

  if (
    ['salesforce', 'datasets', 'universal', 'bauhaus'].indexOf(service) !== -1
  ) {
    return serviceSerializer.deserialize(json);
  }

  const visualisationSerializer = getVisualisationSerializer(visualisation);

  return merge(
    serviceSerializer.deserialize(json),
    visualisationSerializer.deserialize(json),
  );
}

export { serialize, deserialize };
