import adminReducer from '../admin/reducer';
import allDashboardsReducer from '../admin/reducer/all-dashboards-reducer';
import allDataSourcesReducer from '../admin/reducer/all-data-sources-reducer';
import allUsersReducer from '../admin/reducer/all-users-reducer';
import billingStatusReducer from '../billing/reducer';
import instrumentDataReducer from '../components/instrument/reducer';
import customizeReducer from '../customize/reducer';
import containerMenuReducer from '../dashboard/container-layout/container/reducer';
import containerLayoutReducer from '../dashboard/container-layout/reducer';
import dashboardReducer from '../dashboard/reducer';
import defaultDataSourceConnectorReducer from '../data-source-connector/default-data-source-connector/reducer';
import dataSourceConnectorReducer from '../data-source-connector/reducer';
import dataSourcesReducer from '../data-sources/reducer';
import datasetReducer from '../dataset-config/reducer';
import filePickerReducer from '../file-picker/reducer';
import importsReducer from '../imports/reducer/imports-reducer';
import instrumentMenuReducer from '../instrument-menu/reducer';
import loopReducer from '../loop/reducer/';
import manageScreensReducer from '../manage-screens/reducer';
import peopleReducer from '../people/reducer';
import serviceAccountsReducer from '../reducers/service-accounts-reducer';
import salesforceReducer from '../salesforce-config/reducer';
import spreadsheetReducer from '../spreadsheets-config/reducer';
import spreadsheetDataSelectionReducer from '../spreadsheets-config/reducer/spreadsheet-data-selection-slice';
import themesReducer from '../themes/themes-slice';
import toastLauncherReducer from '../toast/reducer';
import tvPageReducer from '../tv-page/reducer';
import universalReducer from '../universal-config/reducer';
import userReducer from '../user/reducer';
import utilityWidgetEditReducer from '../edit-instrument/utility-widget/store/utility-widget-edit-slice';
import whatsNewReducer from '../whats-new/whats-new-reducer';
import widgetDataReducer from '../widget-data/reducer';
import widgetMenuReducer from '../widget-menu/reducer';
import editWidgetReducer from '../widget/edit-widget/reducer';
import widgetsReducer from '../widget/reducer';

const reducers = {
  admin: adminReducer,
  allDashboards: allDashboardsReducer,
  allDataSources: allDataSourcesReducer,
  allUsers: allUsersReducer,
  billingStatus: billingStatusReducer,
  containerLayout: containerLayoutReducer,
  customize: customizeReducer,
  dashboard: dashboardReducer,
  datasets: datasetReducer,
  dataSourceConnector: dataSourceConnectorReducer,
  dataSources: dataSourcesReducer,
  defaultDataSourceConnector: defaultDataSourceConnectorReducer,
  editWidget: editWidgetReducer,
  filePicker: filePickerReducer,
  imports: importsReducer,
  loop: loopReducer,
  manageScreens: manageScreensReducer,
  people: peopleReducer,
  salesforce: salesforceReducer,
  serviceAccounts: serviceAccountsReducer,
  spreadsheets: spreadsheetReducer,
  spreadsheetsDataSelection: spreadsheetDataSelectionReducer,
  themes: themesReducer,
  toastLauncher: toastLauncherReducer,
  tvPage: tvPageReducer,
  universal: universalReducer,
  user: userReducer,
  utilityWidgetEdit: utilityWidgetEditReducer,
  whatsNew: whatsNewReducer,
  widgets: widgetsReducer,
  widgetData: widgetDataReducer,
  widgetMenu: widgetMenuReducer,
  instrumentMenu: instrumentMenuReducer,
  instrumentData: instrumentDataReducer,
  containerMenu: containerMenuReducer,
};

export default reducers;
