import type { Cell, LegacyRange, VisualizationType } from '../../types';
import { Visualizations } from '../../consts';
import { getGeckometerMinMax } from './getGeckometerMinMax';

const EMPTY_LEGACY_RANGE = [[], []];

/**
 * Creates a visualization config that is specific to the visualization type that we're interested in
 */
export const createVisualizationConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseConfig: any,
  vizType: VisualizationType,
  series: LegacyRange[],
  xAxis: LegacyRange | undefined,
  hasHeader: boolean,
  cells: Cell[],
) => {
  const config = {
    ...baseConfig,
    hasHeader,
  };

  const { maxSeries } = Visualizations[vizType] ?? {};

  switch (vizType) {
    case 'text':
    case 'number':
      return {
        ...config,
        value: series?.length
          ? series.slice(0, maxSeries).flat()
          : EMPTY_LEGACY_RANGE,
      };

    case 'geckometer': {
      // eslint-disable-next-line no-case-declarations
      const field = series.length > 0 ? series[0] : undefined;
      // eslint-disable-next-line no-case-declarations
      const { min, max } = getGeckometerMinMax(baseConfig, cells, field);

      return {
        ...config,
        value: series?.length
          ? series.slice(0, maxSeries).flat()
          : EMPTY_LEGACY_RANGE,
        min,
        max,
      };
    }

    case 'line':
    case 'bar':
    case 'column':
      return {
        ...config,
        xAxis,
        series: series.slice(0, maxSeries),
      };

    case 'table':
      return {
        ...config,
        columns: series,
      };

    case 'leaderboard':
      return {
        ...config,
        labels: xAxis,
        values: series.slice(0, maxSeries).flat(),
      };

    default:
      throw new Error(`Unsupported visualization type ${vizType}`);
  }
};
