import { merge, isUndefined, findIndex, pickBy, isNil } from 'lodash';
import { transform as genericTableTransform } from '../per-visualisation/table';
import { composeTransformers } from '../compose-transformers';

const pickFormat = (type) => {
  switch (type) {
    case 'percentage':
      return 'percent';
    case 'money':
      return 'currency';
    case 'duration':
      return 'duration';
    case 'string':
      return undefined;
    case 'date':
      return 'date';
    case 'datetime':
      return 'datetime';
    default:
      return 'decimal';
  }
};

const injectFormats = (columnTypes) =>
  columnTypes.map((metric) => ({
    format: pickFormat(metric.type),
    unit: metric.unit,
  }));

const injectHeadings = (columns, headings, hasHeader) => {
  if (!hasHeader) {
    return columns;
  }

  return merge(
    columns,
    headings.map((heading) => ({
      heading,
    })),
  );
};

const injectSortOrder = (columns, query) => {
  if (!query.order_by) {
    return columns;
  }

  let queryColumns = [...query.columns];

  if (query.group_by) {
    queryColumns = [query.group_by, ...queryColumns];
  }

  const { field, aggregate, direction } = query.order_by;
  const sortedColIndex = findIndex(queryColumns, pickBy({ field, aggregate }));

  if (sortedColIndex === -1) {
    return columns;
  }

  columns[sortedColIndex] = columns[sortedColIndex] || {};
  columns[sortedColIndex].sort = direction;

  return columns;
};

/**
 * Datasets table transformer
 * @param {Object} data - Data that is returned from the backend for the table visualisation.
 * @param {Object[]} data.data - The visualisation data.
 * @param {Object[]} data.data[] - A data row.
 * @param {(string|number)} data.data[][] - The value of the field.
 * @param {Object[]} data.column_types - Describes the format of the column and the unit in case of a currency.
 * @param {string} data.column_types[].type - Describes the type of the column.
 * Can be `date`, `datetime`, `number`, `percentage`, `string`, `money`, `duration`.
 * @param {string} [data.column_types[].unit] - If the type is `money` or `duration` a unit will be given.
 * Units can be "USD" or other currency codes for `money` fields, and "milliseconds", "seconds", "minutes" or
 * "hours" for `duration` fields.
 * @param {Object[]} data.headings - An array with the field names.
 * @param {string} data.headings[] - The name of a field.
 */
const tableTransform = (data, cfg = {}) => {
  if (!data) {
    return {};
  }

  const context = {};

  const { column_types: columnTypes = [], headings = [] } = data;
  const visualisationData = isNil(data.data) ? [] : data.data;
  const { hasHeader, query = {}, tableType } = cfg;

  let columns = injectFormats(columnTypes);
  columns = injectHeadings(columns, headings, hasHeader);
  columns = injectSortOrder(columns, query);

  if (columns.length) {
    context.columns = columns;

    // In a summarized table the first column is our `group_by` which
    // we ignore for the purposes of number formatting
    const columnOffset = tableType === 'summarized' ? -1 : undefined;
    if (!isUndefined(columnOffset)) {
      context.columnOffset = columnOffset;
    }
  }

  context.data = visualisationData;

  return context;
};

const transform = composeTransformers(tableTransform, genericTableTransform);

export { transform };
