import { chain, isEqual } from 'lodash';
import createAction from '../../../lib/redux/create-action';
import { GRID_ROWS, GRID_COLUMNS } from '../container-layout-constants';
import {
  getSections,
  isIntersecting,
  isContainedBy,
} from '../container-layout-helpers';
import * as instrumentMenuActions from '../../../instrument-menu/actions/instrument-menu-actions';
import * as containerMenuActions from '../container/actions/container-menu-actions';

export const DASHBOARD_AREA = {
  rowStart: 1,
  columnStart: 1,
  rowSpan: GRID_ROWS,
  columnSpan: GRID_COLUMNS,
};

export const resizeContainerStart = createAction(
  'ContainerLayout:RESIZE_CONTAINER_START',
);
export const resizeContainerEnd = createAction(
  'ContainerLayout:RESIZE_CONTAINER_END',
);
export const setEmptySpaces = createAction('ContainerLayout:SET_EMPTY_SPACES');
export const removeEmptySpaces = createAction(
  'ContainerLayout:REMOVE_EMPTY_SPACES',
);
export const setSelectedContainer = createAction(
  'ContainerLayout:SET_SELECTED_CONTAINER',
);
export const deselectContainer = createAction(
  'ContainerLayout: DESELECT_CONTAINER',
);

export const deselectAll = () => (dispatch) => {
  dispatch(deselectContainer());
  dispatch(instrumentMenuActions.closeMenu());
  dispatch(containerMenuActions.closeMenu());
};

export const getEmptySpaces = (containers) => {
  if (!containers.length) {
    return [DASHBOARD_AREA];
  }

  let emptySpaces = [DASHBOARD_AREA];

  containers.forEach((container) => {
    emptySpaces = chain(emptySpaces)
      .reduce((accumulatedSpaces, space, index) => {
        const isOverlapping = isIntersecting(space, container.area);

        if (!isOverlapping) {
          return accumulatedSpaces;
        }

        // Remove the space that the container is intersecting with and
        // create new empty spaces around the container.
        accumulatedSpaces[index] = null;
        const sections = getSections(space, container.area);
        return [...accumulatedSpaces, ...sections];
      }, emptySpaces)
      .filter((space) => space !== null)
      .uniqWith(isEqual)
      .value();
  });

  const filteredEmptySpaces = emptySpaces.filter((emptySpace, index) => {
    return chain(emptySpaces)
      .map((comparisonSpace, comparisonIndex) => {
        // prevents comparing a space to itself
        if (index === comparisonIndex) {
          return false;
        }

        return isContainedBy(comparisonSpace, emptySpace);
      })
      .every((spaceToKeep) => spaceToKeep === false)
      .value();
  });

  return filteredEmptySpaces;
};

export const selectContainer = (containerId) => (dispatch, getState) => {
  const { selectedContainer } = getState().containerLayout;
  if (containerId === selectedContainer) {
    return;
  }
  dispatch(setSelectedContainer(containerId));
};

export const moveContainerStart =
  (containerId, containers) => (dispatch, getState) => {
    const { selectedContainer } = getState().containerLayout;

    if (containerId !== selectedContainer) {
      dispatch(deselectContainer());
    }

    const emptySpaces = getEmptySpaces(containers);
    dispatch(setEmptySpaces(emptySpaces));
  };

export const moveFrameStart = (_, containers) => (dispatch) => {
  const emptySpaces = getEmptySpaces(containers);
  dispatch(setEmptySpaces(emptySpaces));
};
