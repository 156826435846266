import defineEvent from '@Tracking/define-event';
import { DashboardContext } from '@Tracking/contexts';

export const groupCreated = defineEvent(
  'Group - Created',
  {},
  DashboardContext,
);

export const groupDeleted = defineEvent(
  'Group - Deleted',
  {},
  DashboardContext,
);

export const groupWidgetsReordered = defineEvent(
  'Group - Widgets re-ordered',
  {},
  DashboardContext,
);

export const groupWidgetsResized = defineEvent(
  'Group - Widgets re-sized',
  {},
  DashboardContext,
);
