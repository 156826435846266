import { get, last } from 'lodash';
import { transform as genericGeckometerTransform } from '../per-visualisation/geckometer';
import { composeTransformers } from '../compose-transformers';
import { getCellValue } from './helpers';
import { roundToPowerOfTen } from '../../lib/round-to-power-of-ten';

const getFirstCol = (row) => getCellValue(row.values[0]);

const geckometerTransform = (data) => {
  const rows = get(data, 'data.databaseQuery.rows');

  if (!rows) {
    return {};
  }

  const value = last(rows.map(getFirstCol));

  return {
    item: value,
    format: 'decimal',
    min: { value: 0 },
    max: { value: roundToPowerOfTen(value) },
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(
  geckometerTransform,
  genericGeckometerTransform,
);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data) => data;

export { transform, applyConfig };
