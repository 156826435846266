import {
  getSeries,
  getXLabels,
  getYAxisFormat,
  destructureSalesforceConfigWrap,
} from './helpers';
import { transform as genericBarTransform } from '../per-visualisation/bar';
import { composeTransformers } from '../compose-transformers';

const barTransform = (pegasusPayload, timezone, initialContext = {}) => {
  const {
    axis_metadata: axisMetadata = {},
    x_labels: xLabels,
    series = [],
  } = pegasusPayload;

  if (!xLabels || !xLabels.length) {
    return {};
  }

  const context = {};
  const xAxisLabels = getXLabels(axisMetadata.x_type, xLabels);
  const seriesValues = getSeries(series);
  const [format, unit] = getYAxisFormat(
    axisMetadata.y_type,
    axisMetadata.y_unit,
  );

  context.series = seriesValues;
  context.format = format;
  context.x_axis = xAxisLabels;
  context.y_axis = { format, unit };

  // Bar does not currently support anything but date time formatting on the x-axis
  if ('datetime' !== xAxisLabels.type) {
    context.x_axis.type = 'standard';
  }

  return { ...initialContext, ...context };
};

const transform = destructureSalesforceConfigWrap(
  composeTransformers(barTransform, genericBarTransform),
);

export { transform };
