import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';
import { DashboardContext } from '@Tracking/contexts';

const sharedExploreSchemaProperties = {
  'Instrument ID': SchemaTypes.string.isRequired,
  'Integration name': SchemaTypes.string.isRequired,
  'Integration slug': SchemaTypes.string.isRequired,
};

export const closeExploreView = defineEvent(
  'Explore view - Close',
  {
    'Instrument ID': SchemaTypes.string.isRequired,
  },
  DashboardContext,
);

export const viewSourceData = defineEvent(
  'Explore view - View source data',
  {
    ...sharedExploreSchemaProperties,
    'Source data target': SchemaTypes.oneOf([
      'Number & breakdown',
      'Line chart',
    ]),
  },
  DashboardContext,
);
