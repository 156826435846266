import { get, map, lowerCase, isEmpty } from 'lodash';
import { transform as genericLineTransform } from '../per-visualisation/line';
import { composeTransformers } from '../compose-transformers';
import {
  getColumnFormat,
  processSeries,
  processSplitSeries,
  getValuesFromRow,
} from './helpers';
import { getIncompleteFromNow } from '../shared/helpers';
import { legendTransform, durationTransform } from './shared';

const formatTransform = (payload, __, ___, initialContext) => {
  const data = get(payload, 'data.bauhausDataRequest.data');

  if (!data) {
    return initialContext;
  }

  const yAxisFormat = getColumnFormat(data.columns.slice(1), data.rows);

  return {
    ...initialContext,
    x_axis: { type: 'datetime' },
    y_axis: yAxisFormat,
    format: yAxisFormat.format,
  };
};

const lineTransform = (payload, config, timezone) => {
  const data = get(payload, 'data.bauhausDataRequest.data');

  if (!data) {
    return {};
  }

  const hasSplitBy = !isEmpty(get(config, 'bauhaus.split_by', []));
  const bucket = lowerCase(get(config, 'bauhaus.group_by[0].time_unit'));

  const rowValues = data.rows.map(({ values }) =>
    getValuesFromRow(values, data.columns),
  );

  let series = hasSplitBy
    ? processSplitSeries(rowValues)
    : processSeries(rowValues);

  series = map(series, (s) => ({
    ...s,
    incomplete_from: getIncompleteFromNow(timezone, bucket),
  }));

  const comparison = get(config, 'bauhaus.comparison');

  if (comparison && series.length > 1) {
    series[1].type = 'secondary';
  }

  return { series };
};

const transform = composeTransformers(
  lineTransform,
  formatTransform,
  legendTransform,
  genericLineTransform,
  durationTransform,
);

const applyConfig = (data, config) => {
  return { ...data, numberFormat: config.numberFormat };
};

export { transform, applyConfig };
