import { isArray, isNil } from 'lodash';
import type {
  Comparison,
  ConfigValue,
  ComparisonInput,
  DecimalPlacesStyle,
  MapFocus as GraphQLMapFocus,
  MapType as GraphQLMapType,
  NumberFormat,
  NumberFormatAbbreviation,
  ProgressIndicator,
  NumberFormatInput,
  ProgressIndicatorInput,
  StatusIndicatorInput,
  StatusIndicator,
  UnitPlacement,
} from '../generated/graphql';
import {
  MapRegion as WidgetConfigMapRegion,
  MapType as WidgetConfigMapType,
} from '@Lib/maps/map-data';
import {
  transformThresholdsToFormState,
  transformFormStateToThresholds,
} from '../universal-config/components/status-indicators-form/status-indicators-form-component';
import { MapRegion, MapType } from '@Lib/maps/map-data';
import { ColumnRow } from '../spreadsheets-config/types';

/**
 * Mappers and types for mapping the redux store widget config
 * to/from concierge create/edit instrument mutations.
 */

export interface ConfigStatusIndicators {
  thresholds: Array<string | number | SpreadsheetCellReference>;
  _currentUpperState: 'warning' | 'positive';
}

type SpreadsheetCellReference = [ColumnRow, ColumnRow];

export interface ConfigComparison {
  type: 'sparkline' | 'percentage' | 'number' | 'goal';
  reversed?: boolean;
  startingValue?: number | Array<number> | string;
  value?: Array<number>;
}

export interface WrapOptions {
  mode: 'AUTO' | 'ALWAYS' | 'OFF';
  autoDecision?: boolean | null;
}

export interface ConfigNumberFormat {
  abbreviation?: 'none' | 'K' | 'M' | 'B';
  precision?: number;
  prefix?: string;
  suffix?: string;
}

export interface NumberConfig {
  type: 'number';
  title?: string;
  label?: string;
  goal?: string | number;
  comparison?: ConfigComparison;
  indicators?: ConfigStatusIndicators;
  numberFormat?: ConfigNumberFormat;
}

export interface GeckometerConfig {
  type: 'geckometer';
  title?: string;
  min?: string;
  max?: string;
  indicators?: ConfigStatusIndicators;
  numberFormat?: ConfigNumberFormat;
}

export interface LineConfig {
  type: 'line';
  title?: string;
  goal?: string | number;
  reverseGoalDirection?: boolean;
  minYAxis?: string;
  maxYAxis?: string;
  numberFormat?: ConfigNumberFormat;
  secondaryIndex?: number;
  legends?: string[];
}

export interface ColumnConfig {
  type: 'column';
  title?: string;
  goal?: string | number;
  reverseGoalDirection?: boolean;
  numberFormat?: ConfigNumberFormat;
  legends?: string[];
}

export interface BarConfig {
  type: 'bar';
  title?: string;
  goal?: string | number;
  reverseGoalDirection?: boolean;
  numberFormat?: ConfigNumberFormat;
}

export interface LeaderboardConfig {
  type: 'leaderboard';
  title?: string;
  reverseSort?: boolean;
  numberFormat?: ConfigNumberFormat;
  showImages?: boolean;
  wrap?: WrapOptions;
}

export interface TableConfig {
  type: 'table';
  title?: string;
  hasHeader?: boolean;
  tableType?: 'raw' | 'summarized';
  numberFormat?: (ConfigNumberFormat | null)[];
  columnWidths?: number[];
  showImages?: boolean;
  wrap?: WrapOptions;
}

export interface MapConfig {
  type: 'map';
  title?: string;
  map?: MapType;
  region?: MapRegion;
}

export interface TextConfig {
  type: 'text';
  title?: string;
}

export interface FeedConfig {
  type: 'feed';
  title?: string;
}

export interface FunnelConfig {
  type: 'funnel';
  title?: string;
  numberFormat?: ConfigNumberFormat;
}

export const graphQLToReduxConfig = {
  mapComparison: (
    graphqlComparison?: Comparison | null,
  ): ConfigComparison | undefined => {
    switch (graphqlComparison?.__typename) {
      case 'SparklineComparison':
        return {
          type: 'sparkline',
          value: graphqlComparison.value
            ? JSON.parse(graphqlComparison.value)
            : undefined,
        };
      case 'PercentageComparison':
        return {
          type: 'percentage',
          reversed: graphqlComparison.positiveDirection === 'DOWN',
          value: graphqlComparison.value
            ? JSON.parse(graphqlComparison.value)
            : undefined,
        };
      case 'NumberComparison':
        return {
          type: 'number',
          reversed: graphqlComparison.positiveDirection === 'DOWN',
          value: graphqlComparison.value
            ? JSON.parse(graphqlComparison.value)
            : undefined,
        };
      default:
        return undefined;
    }
  },

  mapAbbreviation: (
    graphqlAbbreviation?: NumberFormatAbbreviation | null,
  ): ConfigNumberFormat['abbreviation'] => {
    switch (graphqlAbbreviation) {
      case 'NONE':
        return 'none';
      case 'K':
        return 'K';
      case 'M':
        return 'M';
      case 'B':
        return 'B';
      default: {
        return undefined;
      }
    }
  },
  mapPrecision: (
    decimalPlacesStyle?: DecimalPlacesStyle | null,
    decimalPlacesPrecision?: number | null,
  ): number | undefined => {
    if (decimalPlacesStyle === 'FIXED' && !isNil(decimalPlacesPrecision)) {
      return decimalPlacesPrecision;
    }
    return undefined;
  },
  mapUnitPlacement: (
    unitPlacement?: UnitPlacement | null,
    unitValue?: string | null,
  ): { prefix?: string; suffix?: string } => {
    if (unitPlacement === 'PREFIX' && unitValue) {
      return { prefix: unitValue };
    }
    if (unitPlacement === 'SUFFIX' && unitValue) {
      return { suffix: unitValue };
    }
    return {};
  },
  mapNumberFormat: (
    graphqlNumberFormat?: NumberFormat | null,
  ): ConfigNumberFormat | undefined => {
    if (!graphqlNumberFormat) {
      return undefined;
    }

    return {
      abbreviation: graphQLToReduxConfig.mapAbbreviation(
        graphqlNumberFormat.abbreviation,
      ),
      precision: graphQLToReduxConfig.mapPrecision(
        graphqlNumberFormat.decimalPlacesStyle,
        graphqlNumberFormat.decimalPlacesPrecision,
      ),
      ...graphQLToReduxConfig.mapUnitPlacement(
        graphqlNumberFormat.unitPlacement,
        graphqlNumberFormat.unitValue,
      ),
    };
  },

  mapConfigValue: (configValue?: ConfigValue | null): number | undefined => {
    if (!configValue) {
      return undefined;
    }
    if (configValue.__typename === 'FloatValue') {
      return configValue.value;
    }

    if (configValue.__typename === 'SpreadsheetCellReference') {
      return JSON.parse(configValue.cell);
    }

    throw new Error('SpreadsheetCellReference are not supported on datasets');
  },

  mapStatusIndicators: (
    graphqlIndicator?: StatusIndicator | null,
  ): ConfigStatusIndicators | undefined => {
    if (!graphqlIndicator) {
      return undefined;
    }

    const { upperThreshold, lowerThreshold, positiveDirection } =
      graphqlIndicator;

    const _currentUpperState =
      positiveDirection === 'UP' ? 'positive' : 'warning';
    const lowerState =
      _currentUpperState === 'positive' ? 'warning' : 'positive';

    const lowerValue = graphQLToReduxConfig.mapConfigValue(lowerThreshold);
    const upperValue = graphQLToReduxConfig.mapConfigValue(upperThreshold);

    const thresholds = transformFormStateToThresholds({
      lowerState,
      lowerValue,
      upperState: _currentUpperState,
      upperValue,
    });

    return {
      thresholds,
      _currentUpperState,
    };
  },

  mapStatusIndicatorsToChartGoal: (
    graphqlIndicator?: StatusIndicator | null,
  ): { goal?: string | number; reverseGoalDirection?: boolean } => {
    if (!graphqlIndicator) {
      return {};
    }

    if (
      graphqlIndicator.positiveDirection === 'DOWN' &&
      graphqlIndicator.lowerThreshold
    ) {
      const lowerThreshold = graphQLToReduxConfig.mapConfigValue(
        graphqlIndicator.lowerThreshold,
      );
      const goal =
        graphqlIndicator.lowerThreshold.__typename ===
        'SpreadsheetCellReference'
          ? lowerThreshold
          : lowerThreshold!.toString();

      return {
        goal,
        reverseGoalDirection: true,
      };
    }

    if (graphqlIndicator.upperThreshold) {
      const upperThreshold = graphQLToReduxConfig.mapConfigValue(
        graphqlIndicator.upperThreshold,
      );

      const goal =
        graphqlIndicator.upperThreshold.__typename ===
        'SpreadsheetCellReference'
          ? upperThreshold
          : upperThreshold!.toString();

      return {
        goal,
      };
    }

    return {};
  },

  mapProgressIndicator: (
    progressIndicator?: ProgressIndicator | null,
  ): { goal?: string | number; comparison?: ConfigComparison } => {
    if (!progressIndicator) {
      return {};
    }

    const targetValue = graphQLToReduxConfig.mapConfigValue(
      progressIndicator.targetValue,
    );

    const goal =
      progressIndicator.targetValue.__typename === 'SpreadsheetCellReference'
        ? targetValue
        : targetValue!.toString();

    const startingValue = graphQLToReduxConfig.mapConfigValue(
      progressIndicator.startingValue,
    );

    if (goal) {
      return {
        goal,
        comparison: { type: 'goal', startingValue },
      };
    }

    return {};
  },

  mapMapFocus: (
    mapType: GraphQLMapType,
    mapFocus: GraphQLMapFocus,
  ): { map: WidgetConfigMapType; region: WidgetConfigMapRegion } => {
    switch (mapFocus) {
      case 'WORLD':
        return { map: 'World', region: undefined };
      case 'EUROPE':
        return { map: 'Europe', region: undefined };
      case 'NORTH_AMERICA':
        return { map: 'North America', region: undefined };
      case 'SOUTH_AMERICA':
        return { map: 'South America', region: undefined };
      case 'ASIA':
        return { map: 'Asia', region: undefined };
      case 'AFRICA':
        return { map: 'Africa', region: undefined };
      case 'OCEANIA':
        return { map: 'Oceania', region: undefined };
      default:
        switch (mapType) {
          case 'REGION':
            return {
              map: undefined,
              region: `REGION:${mapFocus}` as WidgetConfigMapRegion,
            };
          case 'COUNTRY':
            return {
              map: undefined,
              region: mapFocus as WidgetConfigMapRegion,
            };
          default:
            throw new Error(
              'Invalid map type. Must be one of: REGION, COUNTRY',
            );
        }
    }
  },
};

export const reduxConfigToGraphQL = {
  /**
   * mapComparison doesn't yet support DynamicComparison.
   * It also doesn't support adding a comparison label as it's
   * not possible to set the comparison label from the preview
   * on the dataset config form page.
   */
  mapComparison: (
    configComparison?: ConfigComparison,
  ): ComparisonInput | undefined => {
    if (!configComparison) {
      return undefined;
    }

    const { type, reversed, value } = configComparison;

    switch (type) {
      case 'sparkline':
        return {
          type: 'SPARKLINE',
          value: value ? JSON.stringify(value) : undefined,
        };
      case 'percentage':
        return {
          type: 'PERCENTAGE',
          positiveDirection: reversed ? 'DOWN' : 'UP',
          value: value ? JSON.stringify(value) : undefined,
        };
      case 'number':
        return {
          type: 'NUMBER',
          positiveDirection: reversed ? 'DOWN' : 'UP',
          value: value ? JSON.stringify(value) : undefined,
        };
      default:
        return undefined;
    }
  },

  mapAbbreviation: (
    configAbbreviation: ConfigNumberFormat['abbreviation'],
  ): NumberFormatAbbreviation => {
    switch (configAbbreviation) {
      case 'none':
        return 'NONE';
      case 'K':
        return 'K';
      case 'M':
        return 'M';
      case 'B':
        return 'B';
      default: {
        return 'AUTO';
      }
    }
  },
  mapUnitPlacement: (prefix?: string, suffix?: string): UnitPlacement => {
    if (prefix || prefix === '') {
      return 'PREFIX';
    }
    if (suffix || suffix === '') {
      return 'SUFFIX';
    }
    return 'AUTO';
  },
  mapNumberFormat: (
    configNumberFormat?: ConfigNumberFormat | null,
  ): NumberFormatInput | undefined => {
    if (!configNumberFormat) {
      return undefined;
    }

    return {
      abbreviation: reduxConfigToGraphQL.mapAbbreviation(
        configNumberFormat.abbreviation,
      ),
      decimalPlacesPrecision: configNumberFormat.precision,
      unitPlacement: reduxConfigToGraphQL.mapUnitPlacement(
        configNumberFormat.prefix,
        configNumberFormat.suffix,
      ),
      unitValue: configNumberFormat.prefix || configNumberFormat.suffix,
    };
  },

  mapConfigValue: (value: number | string | Array<number>) => {
    if (isArray(value)) {
      return {
        cell: JSON.stringify(value),
      };
    }

    // when the min/max default values are a currency, we have to strip those out so it doesn't return NaN
    // the regex removes a monetary symbol from the default value i.e '$1000' and returns 1000
    if (typeof value === 'string') {
      return { floatValue: parseFloat(value.replace(/[^\d.-]/g, '')) };
    }

    return {
      floatValue: parseFloat(value.toString()),
    };
  },

  mapProgressIndicator: (
    goal?: string | number,
    comparison?: ConfigComparison,
  ): ProgressIndicatorInput | undefined => {
    if (!goal || comparison?.type !== 'goal') {
      return undefined;
    }

    const indicator: ProgressIndicatorInput = {
      targetValue: reduxConfigToGraphQL.mapConfigValue(goal),
    };

    // when removing starting goal value it returns '' so we need to add the condition
    // so that the '' value doesn't pass down to mapConfigValue
    // otherwise it returns NaN
    if (!isNil(comparison?.startingValue) && comparison?.startingValue !== '') {
      indicator.startingValue = reduxConfigToGraphQL.mapConfigValue(
        comparison.startingValue,
      );
    }

    return indicator;
  },

  mapStatusIndicators: (
    configIndicators?: ConfigStatusIndicators,
  ): StatusIndicatorInput | null => {
    if (!configIndicators) {
      return null;
    }

    const { lowerValue, upperValue } = transformThresholdsToFormState(
      configIndicators.thresholds,
      configIndicators._currentUpperState,
    );

    if (isNil(upperValue) && isNil(lowerValue)) {
      return null;
    }

    return {
      upperThreshold: !isNil(upperValue)
        ? reduxConfigToGraphQL.mapConfigValue(upperValue)
        : undefined,
      lowerThreshold: !isNil(lowerValue)
        ? reduxConfigToGraphQL.mapConfigValue(lowerValue)
        : undefined,
      positiveDirection:
        configIndicators._currentUpperState === 'warning' ? 'DOWN' : 'UP',
    };
  },

  mapChartGoalToStatusIndicators: (config: {
    goal?: string | number;
    reverseGoalDirection?: boolean;
  }): StatusIndicatorInput | undefined => {
    if (!config.goal) {
      return undefined;
    }

    if (config.reverseGoalDirection) {
      return {
        lowerThreshold: reduxConfigToGraphQL.mapConfigValue(config.goal),
        positiveDirection: 'DOWN',
      };
    }

    return {
      upperThreshold: reduxConfigToGraphQL.mapConfigValue(config.goal),
      positiveDirection: 'UP',
    };
  },

  mapMapFocus: (
    map: WidgetConfigMapType,
    region: WidgetConfigMapRegion,
  ): { mapFocus: GraphQLMapFocus } => {
    if (!region) {
      // If there isn't a Region, map the `map` property to a `MapFocus`
      switch (map) {
        case 'World':
          return { mapFocus: 'WORLD' };
        case 'Europe':
          return { mapFocus: 'EUROPE' };
        case 'North America':
          return { mapFocus: 'NORTH_AMERICA' };
        case 'South America':
          return { mapFocus: 'SOUTH_AMERICA' };
        case 'Asia':
          return { mapFocus: 'ASIA' };
        case 'Africa':
          return { mapFocus: 'AFRICA' };
        case 'Oceania':
          return { mapFocus: 'OCEANIA' };
        default:
          throw new Error(
            'Invalid map property. Must be one of: World, Europe, North America, South America, Asia, Africa, Oceania',
          );
      }
    }

    // If we have a `REGION:` prefix, we strip it for the GraphQL format
    if (region.startsWith('REGION:')) {
      return {
        mapFocus: region.split(':')[1] as GraphQLMapFocus,
      };
    }

    // If there's a Region, we already have our `MapFocus`
    return { mapFocus: region as GraphQLMapFocus };
  },
};
