import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { history } from '../router';
import idempotentInitApp from '../init';
import { ConfiguredI18NProvider } from '../lib/i18n';
import HistoryProvider from '../lib/history-provider';
import { IntercomProvider } from '../intercom';
import { QueryClientProvider } from 'react-query';
import { FeatureFlagProvider } from '@Lib/feature-flags';
import { CurrentTimeProvider } from '@Hooks/use-current-time';

const passthrough = (props) => props;

const createReactRenderer = (
  Component,
  { propsTransform = passthrough } = {},
) => {
  return class ReactRenderer {
    constructor(domHost) {
      this.domHost = domHost;
      this.Component = Component;
      this.ctx = {};
    }

    render(ctx) {
      const app = idempotentInitApp();

      this.ctx = ctx || this.ctx;

      const props = propsTransform(this.ctx);
      window.__REACT_VERSION__ = React.version;

      return new Promise((resolve, reject) => {
        try {
          ReactDOM.render(
            <FeatureFlagProvider>
              <Provider store={app.getStore()}>
                <QueryClientProvider client={app.getQueryClient()}>
                  <Router history={history}>
                    <ConfiguredI18NProvider>
                      <HistoryProvider>
                        <IntercomProvider>
                          <CurrentTimeProvider>
                            <this.Component {...props} />
                          </CurrentTimeProvider>
                        </IntercomProvider>
                      </HistoryProvider>
                    </ConfiguredI18NProvider>
                  </Router>
                </QueryClientProvider>
              </Provider>
            </FeatureFlagProvider>,
            this.domHost,
            resolve,
          );
        } catch (e) {
          reject(e);
        }
      });
    }

    remove() {
      ReactDOM.unmountComponentAtNode(this.domHost);
    }
  };
};

export default createReactRenderer;
