import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import layout from '../../hoc/layout';

const Base = (props) => {
  const { tag: Tag, layoutClasses, className, children, ...rest } = props;
  const classes = classNames(layoutClasses, className);

  return (
    <Tag {...rest} className={classes}>
      {children}
    </Tag>
  );
};

Base.defaultProps = {
  tag: 'div',
};
Base.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  layoutClasses: PropTypes.string,
  tag: PropTypes.string,
};

const Layout = layout(Base);

const Row = ({ children, ...rest }) => (
  <Layout flex flexWrap {...rest}>
    {children}
  </Layout>
);
Row.propTypes = {
  children: PropTypes.node,
};

const Column = ({ children, ...rest }) => (
  <Layout flex flexColumn {...rest}>
    {children}
  </Layout>
);
Column.propTypes = {
  children: PropTypes.node,
};

export { Layout as default, Row, Column };
