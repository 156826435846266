import * as adminActions from '../../admin/actions/admin-actions';
import * as dashboardActions from '../../dashboard/actions/dashboard-actions';
import { omit } from 'lodash';

const initialState = {};

const allDashboardsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case dashboardActions.setDashboards.type: {
      const entities = payload.reduce(
        (acc, dashboard) => ({
          ...acc,
          [dashboard.id]: dashboard,
        }),
        {},
      );

      return { ...entities };
    }

    case dashboardActions.addDashboard.type:
    case dashboardActions.changeDashboard.type: {
      return {
        ...state,
        [payload.id]: payload,
      };
    }

    case dashboardActions.deleteDashboard.type: {
      return omit(state, payload);
    }

    case adminActions.regenerateDashboardSharingLinkSuccess.type: {
      const { dashboardId, sharingHash } = payload;

      return {
        ...state,
        [dashboardId]: {
          ...state[dashboardId],
          sharing_hash: sharingHash,
        },
      };
    }

    case adminActions.deleteDashboardSuccess.type: {
      return omit(state, [payload.dashboardId]);
    }

    default:
      return state;
  }
};

export default allDashboardsReducer;
export { initialState };
