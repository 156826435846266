import AuthStrategy from './auth-strategy';
import config from '../universal/config';
import { getCSRFToken } from '../universal/lib/csrf';

const pusherAdapter = (Pusher) => {
  const { ChannelAuthHost, pusher = {} } = config.get();
  const { applicationKey } = pusher;

  if (!applicationKey) {
    throw new Error('Pusher application key not found');
  }

  let authEndpoint = `${ChannelAuthHost}/pusher/auth`;

  const authQueryString = AuthStrategy.getInstance().getQueryParam();
  if (authQueryString) {
    authEndpoint += `?${authQueryString}`;
  }

  const options = {
    authEndpoint,
  };

  const csrfToken = getCSRFToken();
  if (csrfToken) {
    options.auth = {
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    };
  }

  return new Pusher(applicationKey, options);
};

export default pusherAdapter;
