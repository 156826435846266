import { reduce, cloneDeep } from 'lodash';

const composeTransformers =
  (...transformers) =>
  (data, config, timezone, initialContext = {}, integration) => {
    return reduce(
      transformers,
      (context, transformer) =>
        transformer(data, config, timezone, context, integration),
      cloneDeep(initialContext),
    );
  };

const composeApplyConfigTransformers =
  (...transformers) =>
  (data, config, timezone, integration) =>
    reduce(
      transformers,
      (context, transformer) =>
        transformer(data, config, timezone, context, integration),
      cloneDeep(data),
    );

export { composeTransformers, composeApplyConfigTransformers };
