import {
  TableHelpers,
  TextHelpers,
  GeckometerHelpers,
  LeaderboardHelpers,
  NumberHelpers,
  BarChartHelpers,
  ColumnChartHelpers,
  LineChartHelpers,
} from './visualisations';

const DATA_METHODS = {
  line: LineChartHelpers,
  column: ColumnChartHelpers,
  bar: BarChartHelpers,
  number: NumberHelpers,
  leaderboard: LeaderboardHelpers,
  geckometer: GeckometerHelpers,
  text: TextHelpers,
  table: TableHelpers,
};

const getVisualisationHelpers = (visualisation) => DATA_METHODS[visualisation];

export default getVisualisationHelpers;
