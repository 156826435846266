import * as queryActions from '../../actions/universal-query-actions';
import * as bootstrapActions from '../../actions/universal-bootstrap-actions';
import { MAX_NUMBER_OF_METRICS } from '../../ui-field-options';
import {
  getFilterNodesFromQueries,
  getPrerequisitesFromQueries,
  getNodesFromQueries,
  getBucket,
} from './other-ui-query-reducer-methods';
import {
  curry,
  find,
  flatten,
  get,
  has,
  isNull,
  isUndefined,
  isEmpty,
  omit,
  set,
  map,
} from 'lodash';

/**
 * [NEW] ui-query-reducer
 * This reducer is organized into chained transformers that are applied to
 * transform the full state. Each action has a corresponding chain of
 * transformation that are performed. Composing transformations this way makes
 * our complex logic between each part of the state and the current
 * visualisation more readable and easier to reason about.
 *
 * Functional helpers are functions to reduce boilerplate and help to
 * apply transformers based on condition in a functional manner.
 *
 * Core transformers are functions that handle only one part of the state with
 * an optional index when dealing with array.
 *
 * Composed transformers chain multiple core transformers (or composed
 * transformers) to make sure each action apply the correct set of
 * transformation
 **/

// TODO once switched - Old ui-query-reducer is deleted.
// 1) Remove omit call in each core transformer. We should be able to set to
//    empty array without having to worry about removing key. We can also set
//    undefined if necessary
//
const __ = curry.placeholder;

const DEFAULT_AGGREGATION = 'sum';

export const initialState = {
  metrics: [],
  groupBy: [],
  orderBy: [],
  timespans: [],
  filters: [],
  splitBy: [],
  detailsColumns: [],
  bucket: 'day',
  globalFilters: [],
  vipFilters: {},
  boundToMetricFilters: [],
  prerequisites: [],
};

//  -------------------
//  Functional helpers
//  -------------------
const applyForVizType = (matchingViz, currentViz, transformers) => {
  if (matchingViz.includes(currentViz)) {
    return transformers;
  }
  return [];
};

// Apply transformer when the specified rule is set.
const applyForRule = (matchingRule, node, transformer) => {
  if (node && node.rules && node.rules[matchingRule]) {
    // We pass the whole matching node list but most transformers
    // only use the first matching node. Only setFilters
    // supports setting multiple filter nodes for now.
    //
    // Node contains ref to rule node straight from props
    return transformer(node[matchingRule], node.rules[matchingRule]);
  }
  return [];
};

// Only check if statePath is set. If so it will return the list of
// transformers to apply
const applyWhenSet = (statePath, state, transformers) => {
  if (has(state, statePath)) {
    return transformers;
  }
  return [];
};

const applyWhenNotSet = (statePath, state, transformers) => {
  if (!has(state, statePath)) {
    return transformers;
  }
  return [];
};

// Apply exactly same element as the one specify by the statePath
const applyFrom = (statePath, state, transformer) => {
  if (has(state, statePath)) {
    const { node, extra } = get(state, statePath);
    return transformer(node, extra);
  }
  return [];
};

const applyOn = (statePath, state, callback) => {
  if (state[statePath]) {
    return flatten(state[statePath].map(callback));
  }
  return [];
};

const applyAgainstUiOptions = (
  statePath,
  uiOptions,
  element,
  transformer,
  isMetrics,
) => {
  const { node: currentNode, extra: currentExtra } = element;
  let options;
  if (isMetrics) {
    options = flatten(uiOptions[statePath].map((g) => g.options));
  } else {
    options = flatten(uiOptions[statePath]);
  }

  // If no options are available, remove from state
  if (!options || !flatten(options).length) {
    return transformer(null, null);
  }

  if (options.includes(currentNode)) {
    return transformer(currentNode, currentExtra);
  }
  return transformer(options[0], undefined);
};

const dropFiltersAgainstUiOptions = curry((state, uiOptions) => {
  const { filters = [] } = state;

  if (!filters.length) {
    return state;
  }

  const options = flatten(uiOptions.filters);
  const filtersToKeep = filters.filter((filter) =>
    options.includes(filter.node),
  );

  return {
    ...state,
    filters: filtersToKeep,
  };
});

// Replace ui state part by adding, deleting or settings element (node + extra)
// node and extra can take 3 possibles values:
// - undefined: The current value from state is kept
// - null: The current value will be removed (delete)
// - {}: The current value will be replaced with the given value
const prepareNew = (current = [], node, extra, index) => {
  let elements = [...current];

  // Remove index
  if (isNull(node) && isNull(extra)) {
    elements = [...elements.slice(0, index), ...elements.slice(index + 1)];
  } else {
    // We apply default here to allow to create any new node like orderBy
    // even if the state was not defined before.
    const { node: currentNode, extra: currentExtra } = elements[index] || {};
    // TODO can we not find a better way to set correct element here?
    const el = {};
    if (isUndefined(node) && !isUndefined(currentNode)) {
      el.node = currentNode;
    } else if (!isNull(node) && !isUndefined(node)) {
      el.node = node;
    }

    if (isUndefined(extra) && !isUndefined(currentExtra)) {
      el.extra = currentExtra;
    } else if (!isNull(extra) && !isUndefined(extra)) {
      el.extra = extra;
    }
    set(elements, `[${index}]`, el);
  }

  return elements;
};

//  -------------------
//  Core transformers
//  -------------------
/**
 * Note that all core transformers are curried. This allow us to return
 * a function partially applied from composed transformers.
 **/

const _setMetric = curry((state, _node, _extra, index) => {
  const { metrics: current } = state;
  let extra;

  if (isUndefined(_extra)) {
    const {
      type: metricType,
      aggregated,
      default_aggregation: defaultAggregation,
    } = _node;

    let { extra: { aggregate = DEFAULT_AGGREGATION } = {} } =
      current[index] || [];

    if (metricType === 'count') {
      aggregate = 'count';
    } else if (aggregated) {
      aggregate = undefined;
    } else if (!!defaultAggregation) {
      aggregate = defaultAggregation;
    } else {
      // always go back to the default aggregation
      aggregate = DEFAULT_AGGREGATION;
    }
    extra = { aggregate };
  } else {
    extra = _extra;
  }

  return {
    ...state,
    metrics: prepareNew(current, _node, extra, index),
  };
});

const _setGroupBy = curry((state, _node, _extra, index) => {
  const { groupBy: current } = state;

  const groupBy = prepareNew(current, _node, _extra, index);
  if (groupBy.length) {
    return {
      ...state,
      groupBy,
    };
  }
  return omit(state, 'groupBy');
});

const _setOrderBy = curry((state, _node, _extra, index) => {
  const { orderBy: current } = state;

  const orderBy = prepareNew(current, _node, _extra, index);
  if (orderBy.length) {
    return {
      ...state,
      orderBy,
    };
  }
  return omit(state, 'orderBy');
});

const _setSplitBy = curry((state, _node, _extra, index) => {
  const { splitBy: current } = state;

  const splitBy = prepareNew(current, _node, _extra, index);
  if (splitBy.length) {
    return {
      ...state,
      splitBy,
    };
  }
  return omit(state, 'splitBy');
});

const _setTimespan = curry((state, _node, _extra, index) => {
  const { timespans } = state;

  return {
    ...state,
    timespans: prepareNew(timespans, _node, _extra, index),
  };
});

const _setLimit = curry((state, limit) => {
  if (isNull(limit)) {
    return omit(state, 'limit');
  }
  return { ...state, limit };
});

const _setBucket = curry((state, bucket) => {
  return { ...state, bucket };
});

const _setFilter = curry((state, _node, _extra, index) => {
  const { filters: prevFilters } = state;
  const isCustomFilter = _node && _node.type === 'custom';
  const filters = prepareNew(
    isCustomFilter ? [] : prevFilters,
    _node,
    _extra,
    isCustomFilter ? 0 : index,
  );

  return {
    ...state,
    filters,
  };
});

const _setGlobalFilter = curry((state, _node, _extra, index) => {
  const { globalFilters } = state;

  return {
    ...state,
    globalFilters: prepareNew(globalFilters, _node, _extra, index),
  };
});

const _setVIPFilter = curry((state, _node, _extra, index) => {
  const { vipFilters } = state;

  return {
    ...state,
    vipFilters: prepareNew(vipFilters, _node, _extra, index),
  };
});

const _setDetailsColumn = curry((state, _node, _extra, index) => {
  const { detailsColumns: current } = state;

  return {
    ...state,
    detailsColumns: prepareNew(current, _node, _extra, index),
  };
});

const _updateBoundToMetricFilters = curry((state, node, index) => {
  const { filters = [] } = state;

  // If the new node is empty, the metric has been removed, so we remove the
  // associated bound filters from state
  // Alternatively, if there are no rules to apply for this metric, empty
  // the bound filters for the relevant index
  if (isNull(node) || !get(node, 'rules.filters.length')) {
    const currentBoundFilters = state.boundToMetricFilters || [];

    // If a filter has been removed, we want to replace the relevant index in the
    // `boundToMetricFilters` array with an empty array so we know there aren't
    // any applicable bound filters for that metric
    const newBoundFilters = [...currentBoundFilters];
    newBoundFilters[index] = [];

    // If there are no rules, just empty arrays, we can just ignore or omit the
    // `boundToMetricFilters` part of state
    if (isEmpty(flatten(currentBoundFilters))) return state;
    if (isEmpty(flatten(newBoundFilters))) {
      return omit(state, 'boundToMetricFilters');
    }

    return {
      ...state,
      boundToMetricFilters: newBoundFilters,
    };
  }

  const { rules = [] } = node;

  let boundToMetricFilters = [];
  const boundToMetricFilterKeys = [];
  rules.filters &&
    rules.filters.forEach((rule, i) => {
      boundToMetricFilterKeys.push(rule.key);

      boundToMetricFilters = prepareNew(
        boundToMetricFilters,
        node.filters[i],
        {
          bound_to_metric: true,
          operator: rule.operator,
          operands: rule.operands,
        },
        i,
      );
    });

  // Remove any any user defined filters that clash with the new
  // bound to metric filters
  const filtersWithoutClashes = filters.filter(
    (filter) => !boundToMetricFilterKeys.includes(filter.node.key),
  );

  const stateBoundToMetricFilters =
    state.boundToMetricFilters || new Array(state.metrics.length).fill([]);
  stateBoundToMetricFilters[index] = boundToMetricFilters;

  return {
    ...state,
    filters: filtersWithoutClashes,
    boundToMetricFilters: stateBoundToMetricFilters,
  };
});

//  ------------------------------------------
//  Composed transformers for each UI actions
//  ------------------------------------------
/* eslint-disable no-use-before-define */

// This function is needed for now because getUiQueryBasedOnOptions is called from
// config reducer and it has to pass a mainTransformer to apply()
const applyAllAgainstUIOptions = (state, uiOptions, extra, _index, vizType) => {
  // TODO: Check if timespan is available in uiOptions
  // In old reducer we only checked that first timespan is still available as (for now)
  // it's the only one represented in the UI. This is because all Zendesk metrics
  // are associated with only one timefield. When we add a new integration where a metric
  // can have more than one timefield we have to do some work to support that here.

  return [
    ...applyForVizType(
      ['line', 'column', 'bar', 'leaderboard', 'table'],
      vizType,
      applyOn('groupBy', state, (groupBy, index) =>
        applyAgainstUiOptions(
          'groupBy',
          uiOptions,
          groupBy,
          setGroupByGeneric(state, __, __, index, vizType),
        ),
      ),
    ),
    ...applyForVizType(
      ['line', 'column'],
      vizType,
      applyOn('splitBy', state, (splitBy, index) =>
        applyAgainstUiOptions(
          'splitBy',
          uiOptions,
          splitBy,
          setSplitBy(state, __, __, index, vizType),
        ),
      ),
    ),
    [`dropFiltersAgainstUiOptions`, dropFiltersAgainstUiOptions(__, uiOptions)],
  ];
};

export const getUiQueryBasedOnOptions = (_state, uiOptions, vizType) => {
  // TODO: Remove this function and do this inside se†Metric instead
  let state = { ..._state };
  state = apply(applyAllAgainstUIOptions, state, uiOptions, null, 0, vizType);

  return state;
};

const setMetric = curry((state, node, extra, index, vizType) => {
  // TODO use the metric reverse_comparison prop here instead?
  const { type: nodeType = '' } = node || {};

  const extraForOrder = node
    ? { order: nodeType === 'duration' ? 'asc' : 'desc' }
    : undefined;
  const metricExtra = nodeType === 'details' ? null : extra;

  // TODO: When uiOptions are available in state, move functionality from
  // getUiQueryBasedOnOptions here
  // For now we expect all metric to have associated timespans. If not, we'll
  // have to set a default timespans when switching from a null timespans rule.
  // To do so, we need the uiOptions here to pick-up the first one available.
  // We should also be able to set a default groupBy here for viz that require it
  // when changing from details metric, but this is also blocked by not having
  // access to uiOptions in here and so is handled with getUiQueryBasedOnOptions.
  return [
    [`setMetric_${index}`, _setMetric(__, node, metricExtra, index)],
    ...applyWhenSet(
      // Always remove detailsColumns when changing metric
      'detailsColumns',
      state,
      applyOn('detailsColumns', state, (_, i) => [
        ...setDetailsColumn(
          state,
          null,
          null,
          state.detailsColumns.length - (i + 1), // reverse order to delete
          vizType,
        ),
      ]),
    ),
    ...applyWhenSet(
      // Add
      'defaultColumns',
      node || {},
      applyOn('defaultColumns', node || {}, (n, i) => [
        ...setDetailsColumn(state, n, null, i, vizType),
      ]),
    ),
    ...applyForVizType(
      ['leaderboard'],
      vizType,
      setOrderBy(state, node, extraForOrder, index, vizType),
    ),
    ...applyForRule('time_fields', node, (nodes) =>
      setTimespan(state, nodes[0], undefined, index, vizType),
    ),
    [
      `updateBoundToMetricFilters`,
      _updateBoundToMetricFilters(__, node, index),
    ],
    ...applyForRule('optimisation_order', node, (nodes) =>
      setOrderBy(state, nodes[0], extraForOrder, index, vizType),
    ),
  ];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setGroupBy = curry((state, node, extra, index, vizType) => {
  return [[`setGroupBy_${index}`, _setGroupBy(__, node, extra, index)]];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setOrderBy = curry((state, node, extra, index, vizType) => {
  return [[`setOrderBy_${index}`, _setOrderBy(__, node, extra, index)]];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setSplitBy = curry((state, node, extra, index, vizType) => {
  return [[`setSplitBy_${index}`, _setSplitBy(__, node, extra, index)]];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setLimit = curry((state, limit) => {
  return [['setLimit', _setLimit(__, limit)]];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setDetailsColumn = curry((state, node, extra, index, vizType) => {
  return [
    [`setDetailsColumn_${index}`, _setDetailsColumn(__, node, extra, index)],
  ];
});

const setDetailsColumns = curry((state, nodes, extra, index, vizType) => {
  return flatten(
    nodes.map((node, i) => setDetailsColumn(state, node, extra, i, vizType)),
  );
});

const addDetailsColumn = curry((state, node, extra, index, vizType) => {
  return setDetailsColumn(state, node, extra, index, vizType);
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setBucket = curry((state, bucket, vizType) => {
  return [
    ['setBucket', _setBucket(__, bucket)],
    ...applyOn('groupBy', state, ({ node }, index) => [
      ...applyWhenSet(`groupBy[${index}].extra.bucket_by`, state, [
        ...setGroupByBucket(state, node, { bucket_by: bucket }, index, vizType),
      ]),
    ]),
  ];
});

const setTimespan = curry((state, node, extra, index, vizType) => {
  const extraForOptOrder = { order: 'desc' };
  return [
    [`setTimespan_${index}`, _setTimespan(__, node, extra, index)],
    ...applyWhenSet('groupBy[0].extra.bucket_by', state, [
      // we want to sync only node not extra part here
      ...setGroupBy(state, node, undefined, index, vizType),
      ...applyWhenSet(
        'orderBy',
        state,
        setOrderBy(state, node, undefined, index, vizType),
      ),
    ]),
    ...applyForVizType(
      ['line', 'number', 'geckometer'], // Other viz has order already specified
      vizType,
      [
        ...applyWhenSet(
          // always remove orderBy if set
          'orderBy',
          state,
          setOrderBy(state, null, null, index, vizType),
        ),
        ...applyForRule(
          // Apply optimisation order if set for new timespan
          'optimisation_order',
          node,
          (nodes) =>
            setOrderBy(state, nodes[0], extraForOptOrder, index, vizType),
        ),
      ],
    ),
  ];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setTimespanOperands = curry((state, node, operands, _index, vizType) => {
  // Set new operands for all timespans
  const bucket = getBucket(operands);

  return [
    ...applyOn('timespans', state, ({ extra: extraTs }, index) => [
      [
        `setTimespan_${index}`,
        _setTimespan(__, undefined, { ...extraTs, operands }, index),
      ],
    ]),
    ...setBucket(state, bucket, vizType),
  ];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setFilter = curry((state, node, extra, index, vizType) => {
  return [[`setFilter_${index}`, _setFilter(__, node, extra, index)]];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setGlobalFilter = curry((state, node, extra, index, vizType) => {
  return [
    [`setGlobalFilter_{index}`, _setGlobalFilter(__, node, extra, index)],
  ];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setVIPFilter = curry((state, node, extra, index, vizType) => {
  return [[`setVIPFilter_{index}`, _setVIPFilter(__, node, extra, index)]];
});

// Changing to GroupBy bucket means applying timespan node + selected bucket_by
const setGroupByBucket = curry((state, node, _extra, _index, vizType) => {
  const extraForOrder = { order: 'asc' };
  const extraForOptOrder = { order: 'desc' };
  const extra = _extra || { bucket_by: 'day' };

  return applyOn('timespans', state, ({ node: timespanNode }, index) => [
    ...setGroupBy(state, timespanNode, extra, index, vizType),
    ...applyForVizType(
      ['column', 'bar'],
      vizType,
      setOrderBy(state, timespanNode, extraForOrder, index, vizType),
    ),
    ...applyForVizType(
      // column, bar, leaderboard and table are already sorted
      ['line'],
      vizType,
      applyForRule('optimisation_order', timespanNode, (nodes) =>
        setOrderBy(state, nodes[0], extraForOptOrder, index, vizType),
      ),
    ),
    ...setLimit(state, null),
  ]);
});

const setGroupByCategory = curry((state, node, extra, _index, vizType) => {
  const extraForOrder = { order: 'asc' };
  // Use groupBy to loop on all defined groupBy
  return applyOn('groupBy', state, (_, index) => [
    ...setGroupBy(state, node, null, index, vizType),
    ...applyForVizType(['column', 'bar', 'table'], vizType, [
      ...setOrderBy(state, node, extraForOrder, index, vizType),
      ...setLimit(state, 20),
    ]),
  ]);
});

const setGroupByGeneric = curry((state, node, extra, index, vizType) => {
  if (!node) {
    return setGroupBy(state, null, extra, index, vizType);
  }
  if (node.type === 'datetime') {
    return setGroupByBucket(
      state,
      node,
      { bucket_by: state.bucket },
      index,
      vizType,
    );
  }
  return setGroupByCategory(state, node, extra, index, vizType);
});

const addMetric = curry((state, node, extra, index, vizType) => {
  return [
    // Start by applying ts as metric might define timespan rules after
    ...applyFrom(
      'timespans[0]',
      state,
      setTimespan(state, __, __, index, vizType),
    ),
    ...applyFrom(
      'groupBy[0]',
      state,
      setGroupBy(state, __, __, index, vizType),
    ),
    ...applyFrom(
      'orderBy[0]',
      state,
      setOrderBy(state, __, __, index, vizType),
    ),
    ...setMetric(state, node, extra, index, vizType),
  ];
});

const removeMetric = curry((state, node, extra, index, vizType) => {
  return [
    ...setMetric(state, node, extra, index, vizType),
    ...setTimespan(state, node, extra, index, vizType),
    ...setGroupBy(state, node, extra, index, vizType),
    ...setOrderBy(state, node, extra, index, vizType),
  ];
});

const truncateMaxMetrics = (_state, vizType) => {
  const maxMetrics = MAX_NUMBER_OF_METRICS[vizType];
  const state = { ..._state };
  ['metrics', 'timespans', 'groupBy', 'orderBy'].forEach((statePath) => {
    if (state[statePath]) {
      state[statePath] = state[statePath].slice(0, maxMetrics);
    }
  });

  return state;
};

const cleanStateSetMetric = curry((state, node, extra, _index, vizType) => {
  /**
   * Set an empty groupBy here just like we do cleanStateSetVisType so that
   * a default will be set when getUiQueryBasedOnOptions is called (from config reducer)
   * This is messy and can be simplified in the refactor of uiOptions state into here.
   * Can also investigate having applyAgainstUIOptions always setting a default but it's
   * tricky because applyOn is used everywhere and it only applies if the statePath
   * is already defined.
   */
  const { type: metricType } = node;
  if (metricType !== 'details') {
    return [
      ...applyWhenNotSet(
        'groupBy',
        state,
        applyForVizType(
          ['line', 'bar', 'column', 'leaderboard', 'table'],
          vizType,
          setGroupBy(state, {}, null, 0, vizType),
        ),
      ),
    ];
  }
  return [];
});

const cleanStateSetVisType = curry(
  (state, uiOptions, extra, _index, vizType) => {
    // This transformer allow us to clean the state before to get all the
    // transformers from the mainTransformer. This is because we generate the
    // transformer from the entry state.
    return [
      ...applyWhenNotSet(
        'groupBy',
        state,
        applyForVizType(
          ['line', 'bar', 'column', 'leaderboard', 'table'],
          vizType,
          setGroupBy(state, {}, null, 0, vizType),
        ),
      ),
      // Remove groupBy, orderBy and limit if not supported
      ...applyForVizType(['number', 'geckometer', 'feed'], vizType, [
        ...setGroupBy(state, null, null, 0, vizType),
        ...setOrderBy(state, null, null, 0, vizType),
        ...setLimit(state, null),
      ]),
      ...applyForVizType(['line'], vizType, [
        ...setOrderBy(state, null, null, 0, vizType),
      ]),
      ...applyForVizType(['leaderboard'], vizType, [
        // Remove group by extra here since leaderboard does not support
        // time based grouping.
        ...setGroupBy(state, undefined, null, 0, vizType),
      ]),
      // Remove splitBy if not supported
      ...applyForVizType(
        ['number', 'geckometer', 'bar', 'leaderboard', 'table', 'feed'],
        vizType,
        setSplitBy(state, null, null, 0, vizType),
      ),
    ];
  },
);

const setVisualisationType = curry(
  (state, uiOptions, extra, _index, vizType) => {
    // TODO: Remove applyAgainstUiOptions and remove filters when they are
    // handled by setMetric transformer
    return [
      // Re-apply all metric to make sure rules for are sync
      ...applyOn('metrics', state, (metric, index) =>
        applyAgainstUiOptions(
          'metrics',
          uiOptions,
          metric,
          setMetric(state, __, __, index, vizType),
          true,
        ),
      ),
      ...applyForVizType(
        ['line', 'column', 'bar', 'leaderboard', 'table'],
        vizType,
        applyOn('groupBy', state, (groupBy, index) =>
          applyAgainstUiOptions(
            'groupBy',
            uiOptions,
            groupBy,
            setGroupByGeneric(state, __, __, index, vizType),
          ),
        ),
      ),
      ...applyForVizType(['feed'], vizType, setLimit(state, 20)),
      [
        `dropFiltersAgainstUiOptions`,
        dropFiltersAgainstUiOptions(__, uiOptions),
      ],
    ];
  },
);

/* eslint-enable no-use-before-define */
/**
 * Accepts a mainTransformer that returns an array of core transformers
 * based on current state which will be applied in order, transforming the state.
 */
const apply = (mainTransformer, state, node, extra, index, vizType) => {
  const transformers = mainTransformer(state, node, extra, index, vizType);
  // compact duplicated transformation
  // TODO CHECK THIS OPTIMISATION
  // Only ADD_METRIC fails when applying this optimisation since adding a metric
  // rely on duplicating state in a first step. A solution might be to prefix
  // randomly the queryActions.Transformer in that case to enforce the step.
  // const toApply = reverse(uniqBy(reverse([...transformers]), ([name]) => name));

  let tmpState = state;
  transformers.forEach(([, transformer]) => {
    tmpState = transformer(tmpState);
  });
  return tmpState;
};

const uiQueryReducer = (state = initialState, action, vizType) => {
  const { type, payload = {} } = action;

  switch (type) {
    case queryActions.setMetricField.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const cleaned = apply(
        cleanStateSetMetric,
        state,
        payload.field,
        undefined,
        payload.index,
        vizType,
      );
      return apply(
        setMetric,
        cleaned,
        payload.field,
        undefined,
        payload.index,
        vizType,
      );
    case queryActions.setMetricAggregate.type:
      return apply(
        setMetric,
        state,
        undefined,
        { aggregate: payload.aggregate },
        payload.index,
        vizType,
      );
    case queryActions.addMetric.type:
      return apply(
        addMetric,
        state,
        payload,
        undefined,
        state.metrics.length,
        vizType,
      );
    case queryActions.setDetailsColumns.type:
      return apply(
        setDetailsColumns,
        state,
        payload,
        undefined,
        state.detailsColumns.length,
        vizType,
      );
    case queryActions.addDetailsColumn.type:
      return apply(
        addDetailsColumn,
        state,
        payload,
        undefined,
        state.detailsColumns.length,
        vizType,
      );
    case queryActions.removeDetailsColumn.type:
      return apply(setDetailsColumn, state, null, null, payload, vizType);
    case queryActions.removeMetric.type:
      return apply(removeMetric, state, null, null, payload, vizType);
    case queryActions.setTimeField.type:
      return apply(setTimespan, state, payload, undefined, 0, vizType);
    case queryActions.setTimeOperands.type:
      return apply(setTimespanOperands, state, undefined, payload, 0, vizType);
    case queryActions.setSplitBy.type:
      return apply(setSplitBy, state, payload, null, 0, vizType);
    case queryActions.setOrderBy.type:
      return apply(setOrderBy, state, payload, undefined, 0, vizType);
    case queryActions.setOrderByExtra.type:
      return apply(setOrderBy, state, undefined, payload, 0, vizType);
    case queryActions.setGroupByBucket.type:
      return apply(
        setGroupByBucket,
        state,
        undefined,
        { bucket_by: payload },
        null,
        vizType,
      );
    case queryActions.setGroupByCategory.type:
      return apply(
        setGroupByCategory,
        state,
        payload,
        undefined,
        null,
        vizType,
      );
    case queryActions.setFilter.type:
      return apply(
        setFilter,
        state,
        payload.field,
        payload.value,
        payload.index,
        vizType,
      );
    case bootstrapActions.setGlobalFilter.type:
    case queryActions.setGlobalFilter.type:
      return apply(
        setGlobalFilter,
        state,
        payload.field,
        payload.value,
        payload.index,
        vizType,
      );
    case queryActions.setVIPFilter.type:
      return apply(
        setVIPFilter,
        state,
        payload.field,
        payload.value,
        payload.index,
        vizType,
      );
    case queryActions.removeFilter.type:
      return apply(setFilter, state, null, null, payload, vizType);
    case queryActions.setVisualisationType.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const cleanedState = apply(
        cleanStateSetVisType,
        truncateMaxMetrics(state, vizType),
        payload.uiOptions,
        null,
        0,
        vizType,
      );
      return apply(
        setVisualisationType,
        cleanedState,
        payload.uiOptions,
        null,
        0,
        vizType,
      );
    case bootstrapActions.setGlobalFiltersFromQueries.type:
      return {
        ...getFilterNodesFromQueries(payload.graph, payload.queries),
      };
    case bootstrapActions.setInitialQueries.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const nodesFromQuery = getNodesFromQueries(
        payload.graph,
        payload.queries,
        payload.uiOptions,
        payload.isTimespanComparisonOn,
        payload.detailsMetricResource,
        state.prerequisites,
      );

      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { vipFilters: allVIPNodes = [] } = payload;
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { vipFilters: savedVIPFilters = [] } = nodesFromQuery;

      // Set vipFilters state. If filter was saved on query use that,
      // otherwise map node and set empty extra
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const vipFilters = allVIPNodes.map((node) => {
        const savedFilter = find(savedVIPFilters, ({ node: n }) =>
          n.isEqualTo(node),
        );
        return savedFilter || { node, extra: {} };
      });

      return {
        ...state,
        ...nodesFromQuery,
        vipFilters,
      };
    case queryActions.setPrerequisites.type:
      return {
        ...state,
        prerequisites: payload,
      };
    case queryActions.clearPrerequisites.type: {
      if (isUndefined(state.prerequisites)) {
        return state;
      }

      const prerequisites = map(state.prerequisites, (node) => ({
        ...node,
        extra: omit(node.extra, 'operands'),
      }));

      return {
        ...state,
        prerequisites,
      };
    }
    case bootstrapActions.setPrerequisitesFromQueries.type:
      return {
        ...state,
        prerequisites:
          getPrerequisitesFromQueries(
            payload.graph,
            payload.queries,
            payload.serviceAccountFilters,
          ) || state.prerequisites,
      };
    default:
      return state;
  }
};

export default uiQueryReducer;
