import {
  findIndex,
  get,
  includes,
  isArray,
  isUndefined,
  pick,
  set,
  take,
  times,
  cloneDeep,
} from 'lodash';
import { applyAndMerge } from '../../../lib/config-mapper';
import { FORM_TYPES } from './table-form-constants';

class TableSummaryModeNotSupportedError extends Error {
  constructor() {
    super();
    this.type = 'ErrTableSummaryModeNotSupported';
  }
}

const MAX_COLUMNS = 10;
const DATETIME_BUCKETING_ONLY = ['minute', 'hour'];
const DEFAULT_AGGREGATE = 'sum';

const isTimeField = (field) => {
  return ['date', 'datetime'].includes(field.type);
};

const getDefaultQueryForRaw = (fieldGroups) => {
  let columnFields = take(fieldGroups.string, 2);
  if (columnFields.length !== 2) {
    const takeLength = 2 - columnFields.length;
    columnFields = [...columnFields, ...take(fieldGroups.numeric, takeLength)];
  }
  if (columnFields.length !== 2) {
    const takeLength = 2 - columnFields.length;
    columnFields = [
      ...columnFields,
      ...take(fieldGroups.groupable, takeLength),
    ];
  }
  const columns = columnFields.map(({ key }) => ({ field: key }));
  return { columns };
};

const getDefaultQueryForSummary = (fieldGroups) => {
  const firstGroupBy = get(fieldGroups, 'groupable[0]');
  const firstNumericField = get(fieldGroups, 'numeric[0].key');

  if (!firstGroupBy) {
    throw new TableSummaryModeNotSupportedError();
  }

  const { key: groupByField, type: groupByType } = firstGroupBy;
  const groupBy = { field: groupByField };
  const column = {};

  if (firstNumericField) {
    column.field = firstNumericField;
    column.aggregate = DEFAULT_AGGREGATE;
  } else {
    // Apply record count aggregation when there are no numeric fields
    column.aggregate = 'count';
    column._aggregate = DEFAULT_AGGREGATE;
  }

  if (groupByType !== 'string') {
    groupBy.bucket_by = 'day';
  }

  return { group_by: groupBy, columns: [column] };
};

const getDefaultQuery = (fieldGroups, config) => {
  if (config.tableType === FORM_TYPES.SUMMARIZED) {
    return getDefaultQueryForSummary(fieldGroups);
  }

  return getDefaultQueryForRaw(fieldGroups);
};

const ensureConfig = (currentConfig) => {
  const config = pick(
    currentConfig,
    'title',
    'type',
    'tableType',
    'dataSetId',
    'numberFormat',
    'hasHeader',
    'columnWidths',
    'wrap',
    'juno',
  );

  const tableType = isUndefined(config.tableType)
    ? FORM_TYPES.RAW
    : config.tableType;
  const hasHeader = isUndefined(config.hasHeader) ? true : config.hasHeader;
  const numberFormat = isArray(config.numberFormat) ? config.numberFormat : [];
  const wrap = config.wrap ?? undefined;

  return { ...config, hasHeader, tableType, numberFormat, wrap };
};

const updateQueryForSummary = (query, fields) => {
  const newQuery = { ...query };
  const { columns = [] } = newQuery;
  const numberOfColumns = columns.length || 1;
  const bucketBy = get(newQuery, 'group_by.bucket_by');
  const groupByField = get(newQuery, 'group_by.field');
  const isGroupingByTime = groupByField && isTimeField(fields[groupByField]);

  // Ensure that we're bucketing when grouping by time
  if (isGroupingByTime && !bucketBy) {
    newQuery.group_by.bucket_by = 'day';
  }

  // Remove bucketing when grouping by strings
  if (groupByField && !isGroupingByTime) {
    delete newQuery.group_by.bucket_by;
  }

  // When switching to a date type from a datetime -
  // 'hour' and 'minutes' are no longer a valid bucketing option
  if (
    includes(DATETIME_BUCKETING_ONLY, bucketBy) &&
    fields[groupByField].type === 'date'
  ) {
    newQuery.group_by = {
      ...newQuery.group_by,
      bucket_by: 'day',
    };
  }

  newQuery.columns = times(numberOfColumns, (i) => {
    const column = { ...get(newQuery, `columns[${i}]`, {}) };

    // Default aggregate to sum
    if (!column.aggregate) {
      column.aggregate = DEFAULT_AGGREGATE;
    } else if (!isGroupingByTime) {
      // Latest aggregate is only supported when grouping by datetime
      if (column.aggregate === 'latest') {
        column.aggregate = DEFAULT_AGGREGATE;
      }
      if (column._aggregate === 'latest') {
        column._aggregate = DEFAULT_AGGREGATE;
      }
    }

    // Map the field value `aggregate:count` to the aggregate property
    if (get(column, 'field') === 'aggregate:count') {
      delete column.field;
      column._aggregate = column.aggregate;
      column.aggregate = 'count';
    }
    if (column.aggregate === column._aggregate) {
      delete column._aggregate;
    }

    // When a field is applied and we're currently aggregating by count then we must revert to the original
    // aggregate
    if (column.field && column.aggregate === 'count') {
      column.aggregate = column._aggregate || DEFAULT_AGGREGATE;
      delete column._aggregate;
    }

    return column;
  });

  return newQuery;
};

const updateQuery = (currentQuery, path, value, fields) => {
  const newQuery = cloneDeep(currentQuery);
  set(newQuery, path, value);

  // If there is an order by, make sure the field which is ordered by still exists
  if (newQuery.order_by) {
    const { field: orderByField, aggregate } = newQuery.order_by;
    const isOrderingByGroupBy = newQuery.group_by
      ? orderByField === newQuery.group_by.field
      : false;

    if (!isOrderingByGroupBy) {
      const columnToFind = { field: orderByField };

      // When on a summary table, the columns and order_by include aggregate.
      // We have to match the columns using the aggregate too as the same
      // field can be used multiple times with different aggregates.
      if (aggregate) {
        columnToFind.aggregate = aggregate;
      }

      const orderByFieldIndex = findIndex(newQuery.columns, columnToFind);
      if (orderByFieldIndex < 0) {
        delete newQuery.order_by;
      }
    }
  }

  // The presence of `group_by` indicates that we are in summary mode
  if (newQuery.group_by) {
    return updateQueryForSummary(newQuery, fields);
  }

  return newQuery;
};

const QUERY_MAPPING_RULES = {
  geckometer: [['filters']],
  number: [['filters']],
  leaderboard: [['filters']],
  line: [['filters']],
  column: [['filters']],
  bar: [['filters']],
};

const mapQuery = (fromType, ...rest) =>
  applyAndMerge(QUERY_MAPPING_RULES[fromType], ...rest);

const visualisationHelpers = {
  ensureConfig,
  getDefaultQuery,
  updateQuery,
  mapQuery,
  getDefaultQueryForRaw,
  getDefaultQueryForSummary,
  MAX_COLUMNS,
  TableSummaryModeNotSupportedError,
};

export default visualisationHelpers;
