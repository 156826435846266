import React from 'react';
import TvPage from './tv-page-component';
import FormattedMessage from '../../lib/i18n';

import style from './tv-page-style.scss';

const TvPageEmptyComponent = () => {
  return (
    <TvPage>
      <div className={style.descriptionTitle}>
        <FormattedMessage id="tvpage.empty.title" />
      </div>
      <div className={style.descriptionText}>
        <FormattedMessage id="tvpage.empty.text" />
      </div>
    </TvPage>
  );
};

export default TvPageEmptyComponent;
