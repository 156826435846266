import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TvPage from './tv-page-component';
import FormattedMessage from '../../lib/i18n';

import style from './tv-page-style.scss';

class TvPagePinComponent extends Component {
  constructor(props) {
    super(props);

    props.onLoad();
  }

  render() {
    const { code, error } = this.props;

    return (
      <TvPage error={error}>
        <div className={style.descriptionTitle}>
          <FormattedMessage id="tvpage.pin.title" />
        </div>
        <div className={style.pinCode}>
          {code || <span className={style.placeholderPin}>XXXXXX</span>}
        </div>
      </TvPage>
    );
  }
}

TvPagePinComponent.propTypes = {
  onLoad: PropTypes.func.isRequired,
  code: PropTypes.string,
  error: PropTypes.string,
};

export default TvPagePinComponent;
