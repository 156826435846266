import { transform as numberFormatTransform } from '../../shared/number-format';
import { transform as goalTransform } from '../../shared/goal';
import { transform as comparisonTransform } from './comparison';
import { transform as progressIndicator } from '../../shared/progress-indicator';
import { transform as statusIndicators } from '../../shared/status-indicators';
import { composeTransformers } from '../../compose-transformers';

const transform = composeTransformers(
  numberFormatTransform,
  goalTransform,
  comparisonTransform,
  progressIndicator,
  statusIndicators,
);

export { transform };
