import { transform as genericNumberTransform } from '../per-visualisation/number';
import { composeTransformers } from '../compose-transformers';
import { BauhausDataResponse } from '@Generated/graphql';
import { getColumnFormat, getDisplayValue, getValuesFromRow } from './helpers';
import { sumBy } from 'lodash';

type Payload = {
  version: 2;
  item?: Item[];
  format?: string;
  unit?: string;
};

type Item = {
  label: string;
  value: number;
};

function funnelTransform(input: {
  data: { bauhausDataRequest: BauhausDataResponse };
}): Payload {
  const data = input?.data?.bauhausDataRequest?.data;

  if (!data) {
    return { version: 2 };
  }

  const { rows, columns } = data;

  const item: Item[] = [];

  const justData = rows.map((r) => getValuesFromRow(r?.values, columns));

  if (!justData.some((i) => Number(i[1]) > 0)) {
    return { version: 2 };
  }

  justData.forEach((row, i) => {
    item.push({
      label: getDisplayValue(row[0]),
      value: sumBy(justData.slice(i), (r) => Number(r[1])),
    });
  });

  const format = getColumnFormat(columns, rows);

  return { version: 2, item, ...format };
}

const transform = composeTransformers(funnelTransform, genericNumberTransform);

const applyConfig = (data: object, config: { numberFormat: object }) => {
  return { ...data, numberFormat: config.numberFormat };
};

export { transform, applyConfig };
