import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { graphql } from '../../../generated/gql';
import {
  mapReduxToVisualizationInput,
  typedUseSelector,
} from '../../../compact-config/compact-config-graphql-redux-mapper';
import { trackEvent } from '@Tracking/index';
import { instrumentConfigEdited } from '@Tracking/events/instrument-events';
import { trackGoal } from '@Components/instrument/instrument-tracking-helpers';

export const editInstrumentMutation = graphql(`
  mutation InstrumentEditCompactConfig(
    $id: ID!
    $dataSourceConfigInput: DataSourceConfigInput!
    $visualizationInput: VisualizationInput!
  ) {
    instrumentEdit(
      id: $id
      dataSourceConfigInput: $dataSourceConfigInput
      visualizationInput: $visualizationInput
    ) {
      instrument {
        id
      }
    }
  }
`);

const useEditCompactConfigInstrumentMutation = () => {
  const {
    config,
    refresh_interval: refreshInterval,
    transformedData,
  } = typedUseSelector((state) => get(state, 'editWidget'));

  const originalGoal = transformedData?.data?.progressIndicator;

  const [editInstrument] = useMutation(editInstrumentMutation, {
    onCompleted: (data) => {
      const trackingDetails = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        'Instrument ID': data.instrumentEdit?.instrument?.id!,
        Visualisation: config.type,
        'Integration name': 'Databases',
        'Integration slug': 'database',
      };

      trackEvent(instrumentConfigEdited(trackingDetails));
      trackGoal(trackingDetails, originalGoal, config);
    },
  });

  return (id: string, visualisationPreviewManagerConfig: object) => {
    return editInstrument({
      variables: {
        id,
        dataSourceConfigInput: {
          database: {
            databaseId: config.databaseId,
            query: config.query,
            refreshIntervalSeconds: refreshInterval,
          },
        },
        visualizationInput: mapReduxToVisualizationInput({
          ...config,
          ...visualisationPreviewManagerConfig,
        }),
      },
    });
  };
};

export default useEditCompactConfigInstrumentMutation;
