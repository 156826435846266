import * as instrumentMenuActions from '../actions/instrument-menu-actions';
import { VIEWS } from '../instrument-menu-constants';
import { isUndefined } from 'lodash';

const initialState = {
  view: VIEWS.CLOSED,
  instrumentId: undefined,
  numberFormat: undefined,
};

const instrumentMenuReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case instrumentMenuActions.openMenu.type: {
      return {
        ...state,
        instrumentId: payload.instrumentId,
        view: payload.view || VIEWS.MENU,
      };
    }
    case instrumentMenuActions.closeMenu.type: {
      return initialState;
    }
    case instrumentMenuActions.openCopyModal.type: {
      return {
        ...state,
        view: VIEWS.COPY,
      };
    }
    case instrumentMenuActions.openUniversalConfig.type: {
      return {
        ...state,
        legacyWidgetKey: payload.legacyWidgetKey,
        instrumentId: payload.instrumentId || state.instrumentId,
        view: VIEWS.EDIT,
      };
    }
    case instrumentMenuActions.openDeleteModal.type: {
      return {
        ...state,
        view: VIEWS.DELETE,
      };
    }
    case instrumentMenuActions.closeDeleteModal.type: {
      return {
        ...state,
        view: VIEWS.CLOSED,
      };
    }
    case instrumentMenuActions.openInstrumentStatusIndicators.type: {
      return {
        ...state,
        view: VIEWS.STATUS_INDICATORS,
      };
    }
    case instrumentMenuActions.setStatusIndicators.type: {
      return {
        ...state,
        statusIndicators: payload,
      };
    }
    case instrumentMenuActions.openVisualizationOptions.type: {
      return {
        ...state,
        view: VIEWS.VISUALIZATION_OPTIONS,
      };
    }
    case instrumentMenuActions.openInstrumentNumberFormatting.type: {
      return {
        ...state,
        view: VIEWS.NUMBER_FORMATTING,
      };
    }
    case instrumentMenuActions.setNumberFormat.type: {
      return {
        ...state,
        numberFormat: payload,
      };
    }
    case instrumentMenuActions.updateNumberFormat.type: {
      const { format = {}, index } = action.payload;
      const { numberFormat: currentNumberFormat } = state;

      let numberFormat;

      // If index is defined, we're dealing with an array
      // of number formats (e.g. in case of a table visualisation)
      if (!isUndefined(index)) {
        numberFormat = [...(currentNumberFormat || [])];
        numberFormat[index] = {
          ...numberFormat[index],
          ...format,
        };
      } else {
        numberFormat = { ...(currentNumberFormat || {}), ...format };
      }

      return {
        ...state,
        numberFormat,
      };
    }

    default: {
      return state;
    }
  }
};

export { instrumentMenuReducer as default, initialState };
