import fetchJSON, { fetchAllJSONWithCredentials, fetchAllJSON } from '../fetch';
import config from '../config';

function deleteDataset(id) {
  const { IrisBaseURL } = config.get();
  return fetchAllJSONWithCredentials(`${IrisBaseURL}/datasets/${id}`, {
    method: 'DELETE',
  });
}

function fetchDataSet(id, { includesData = false } = {}) {
  const { IrisBaseURL } = config.get();
  const params = includesData ? '?includes=data' : '';

  return fetchAllJSONWithCredentials(`${IrisBaseURL}/datasets/${id}${params}`);
}

function queryDataSet(id, { requestHeaders = {}, query, type, timezone }) {
  const { IrisBaseURL } = config.get();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...requestHeaders,
  };

  return fetchAllJSON(`${IrisBaseURL}/datasets/${id}/query/${type}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({ ...query, timezone }),
  });
}

function fetchList(dataSetID, { query = '' } = {}) {
  const { IrisBaseURL } = config.get();

  return fetchAllJSONWithCredentials(
    `${IrisBaseURL}/datasets?query=${query}`,
  ).then((result) => {
    return result.data.map(({ id, demo, created_at: createdAt }) => {
      // Map the id to to the title so we can maintain compatibility
      // with other services. ERGH.
      return { id, title: id, demo, created_at: createdAt };
    });
  });
}

function get(
  { config: { dataSetId, query, type }, dashboard: { timezone } },
  requestHeaders,
) {
  return datasetService.queryDataSet(dataSetId, {
    requestHeaders,
    query,
    type,
    timezone,
  });
}

function getConfig({ config: cfg }) {
  return Promise.resolve(cfg);
}

function queryAutocompleteValue(datasetId, body) {
  const { IrisBaseURL } = config.get();
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  return fetchJSON(`${IrisBaseURL}/datasets/${datasetId}/autocomplete`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  });
}

// We need to spy on queryDataset so that we can test .get. So we're exporting here
// to ensure that it can be monkey patched
const datasetService = {
  deleteDataset,
  fetchDataSet,
  queryDataSet,
  fetchList,
  get,
  getConfig,
  queryAutocompleteValue,
};

export default datasetService;
