export default {
  api: {
    max_time_period: 1209600000,
  },
  resources: [
    {
      name: 'companies',
      human_readable_name: 'companies',
      countable: true,
      primary_key: 'id',
      fields: [
        {
          custom: 'current_companies',
          name: 'Number of companies',
          base: { resource: 'companies' },
          category: 'count_metrics',
          type: 'count',
          rules: {
            time_fields: [null],
          },
          additional_info: {
            content: 'The number of companies on this account.',
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'created_at',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'segments.id',
          name: 'Segment',
          type: 'string',
          resource: 'company_segments',
          restrict_single_value: true,
          filter_exclusion_groups: ['group'],
          query_custom_values: {
            select: [
              {
                resource: 'segments',
                path: 'id',
              },
              {
                resource: 'segments',
                path: 'name',
              },
            ],
            filter: [
              {
                resource: 'segments',
                path: 'type',
                operator: '=',
                operands: ['company'],
              },
            ],
          },
        },
        {
          key: 'tags',
          name: 'Tag',
          type: 'string',
          resource: 'tags',
          foreign_key: 'tags.id',
          target_key: 'name',
          restrict_single_value: true,
          filter_exclusion_groups: ['group'],
          equivalents: {
            leads: 'tags',
            users: 'tags',
          },
        },
      ],
    },
    {
      name: 'leads',
      human_readable_name: 'leads',
      countable: true,
      fields: [
        {
          custom: 'number_of_leads',
          name: 'Number of leads',
          base: { resource: 'leads' },
          category: 'count_metrics',
          type: 'count',
          rules: {
            time_fields: [null],
          },
          additional_info: {
            content:
              'The number of people who started a conversation or replied to an auto message.',
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'created_at',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'segments',
          name: 'Segment',
          type: 'string',
          resource: 'segments',
          foreign_key: 'segments.id',
          target_key: 'name',
          restrict_single_value: true,
          filter_exclusion_groups: ['group'],
          equivalents: {
            users: 'segments',
          },
        },
        {
          key: 'tags',
          name: 'Tag',
          type: 'string',
          resource: 'tags',
          foreign_key: 'tags.id',
          target_key: 'name',
          restrict_single_value: true,
          filter_exclusion_groups: ['group'],
          equivalents: {
            companies: 'tags',
            users: 'tags',
          },
        },
      ],
    },
    {
      name: 'users',
      human_readable_name: 'users',
      countable: true,
      fields: [
        {
          custom: 'current_users',
          name: 'Number of users',
          base: { resource: 'users' },
          category: 'count_metrics',
          type: 'count',
          rules: {
            time_fields: [null],
          },
          additional_info: {
            content:
              'The number of people who signed up for your product or logged into an existing account after starting a conversation.',
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'created_at',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'segments',
          name: 'Segment',
          type: 'string',
          resource: 'segments',
          foreign_key: 'segments.id',
          target_key: 'name',
          restrict_single_value: true,
          filter_exclusion_groups: ['group'],
          equivalents: {
            leads: 'segments',
          },
        },
        {
          key: 'tags',
          name: 'Tag',
          type: 'string',
          resource: 'tags',
          foreign_key: 'tags.id',
          target_key: 'name',
          restrict_single_value: true,
          filter_exclusion_groups: ['group'],
          equivalents: {
            companies: 'tags',
            leads: 'tags',
          },
        },
      ],
    },
    {
      name: 'segments',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Segment', type: 'string' }],
    },
    {
      name: 'tags',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Tag', type: 'string' }],
    },
    {
      name: 'admins',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Assignee', type: 'string' }],
    },
    {
      name: 'conversations',
      human_readable_name: 'conversations',
      countable: true,
      fields: [
        {
          custom: 'created_conversations',
          name: 'New conversations',
          base: { resource: 'conversations' },
          category: 'timeseries_metrics',
          type: 'count',
          rules: {
            time_fields: [
              { primary_resource: 'conversations', key: 'created_at' },
            ],
            filters: [
              {
                primary_resource: 'conversations',
                key: 'inbound',
                operator: 'in',
                operands: [true],
              },
            ],
          },
          additional_info: {
            content:
              'The number of new conversations started by an inbound message.',
          },
          reverse_comparison: true,
        },
        {
          custom: 'closed_conversations',
          name: 'Closed conversations',
          base: { resource: 'conversations' },
          category: 'timeseries_metrics',
          type: 'count',
          rules: {
            time_fields: [
              { primary_resource: 'conversations', key: 'last_closed_at' },
            ],
            filters: [
              {
                primary_resource: 'conversations',
                key: 'state',
                operator: 'in',
                operands: ['closed'],
              },
            ],
          },
          additional_info: {
            content: 'The number of conversations that are currently closed.',
          },
        },
        {
          key: 'assignee',
          name: 'Assignee',
          type: 'string',
          resource: 'admins',
          foreign_key: 'assignee_id',
          target_key: 'name',
          supports_null_values: true,
        },
        {
          key: 'time_to_close',
          name: 'Time to close',
          default_aggregation: 'median',
          type: 'duration',
          unit: 'seconds',
          rules: {
            time_fields: [
              { primary_resource: 'conversations', key: 'first_action_at' },
            ],
            filters: [
              {
                primary_resource: 'conversations',
                key: 'state',
                operator: 'in',
                operands: ['closed'],
              },
            ],
          },
          additional_info: {
            content:
              "The duration of any inbound or outbound conversation, calculated from the date of the first customer message or reply to the date it was closed. This metric doesn't discount the hours during which your office was closed as this information isn't currently made available to us by Intercom.",
          },
          reverse_comparison: true,
        },
        {
          key: 'first_response_time',
          name: 'First response time',
          default_aggregation: 'median',
          type: 'duration',
          unit: 'seconds',
          rules: {
            time_fields: [
              { primary_resource: 'conversations', key: 'created_at' },
            ],
            filters: [
              {
                primary_resource: 'conversations',
                key: 'inbound',
                operator: 'in',
                operands: [true],
              },
            ],
          },
          additional_info: {
            content:
              "The time it takes to reply to the first incoming message in a conversation. This metric doesn't discount the hours during which your office was closed as this information isn't currently made available to us by Intercom.",
          },
          reverse_comparison: true,
        },
        {
          key: 'created_at',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updated_at',
          name: 'Updated at',
          type: 'datetime',
        },
        {
          key: 'last_closed_at',
          name: 'Last closed at',
          type: 'datetime',
        },
        {
          key: 'first_action_at',
          name: 'First action at',
          type: 'datetime',
        },
        {
          key: 'state',
          name: 'State',
          type: 'enum',
          values: [
            { key: 'open', value: 'Open' },
            { key: 'closed', value: 'Closed' },
          ],
        },
        {
          key: 'inbound',
          name: 'Inbound',
          type: 'boolean',
        },
      ],
    },
  ],
  initialQueries: {
    number: {
      query: {
        select: [
          {
            resource: 'companies',
            aggregate: 'count',
            custom: 'current_companies',
          },
        ],
      },
    },
  },
};
