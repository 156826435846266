import createAction from '../../lib/redux/create-action';
import { omit, every, isUndefined, isEqual } from 'lodash';
import localForage from 'localforage';
import {
  createWithAutoPosition as create,
  update,
} from '../../lib/widget-service';
import { redirect, getWindow } from '../../lib/global';
import { dispatchToGeckoJS } from '../../lib/gecko-view';
import {
  STORAGE_KEY_LAST_ADDED_WIDGET,
  STORAGE_KEY_LAST_ADDED_INSTRUMENT,
} from '../../widget/constants/widget-location-constants';
import { STORAGE_IMPORTING_SERVICE } from '../../dashboard/dashboard-constants';
import { TEMPLATES } from '../../universal/serializers/services';
import { removeKeysWithUnderscore } from '../../universal/serializers/helpers';
import {
  cancelQueries,
  initConfig,
  universalSetError,
} from './universal-config-actions';
import * as toastActions from '../../toast/actions/toast-actions';
import * as instrumentDataActions from '../../components/instrument/actions/instrument-data-actions';
import { arrangeConfig } from '../reducer/config-helpers';
import { setPreferences } from '../persist-config-helpers';
import widgetStorage from '../../lib/widget-storage';
import { trackEvent } from '@Tracking';
import {
  widgetCreated,
  widgetConfigEdited,
  widgetGoalAdded,
  widgetGoalEdited,
  widgetGoalRemoved,
} from '@Tracking/events';
import delay from '@Lib/delay';
import { requiresImport } from '../../imports/helpers';
import * as pathHelpers from '../../lib/path';
import bugsnag from '../../lib/bugsnag';
import {
  createInstrument,
  editInstrument,
} from './universal-instrument-helpers';
import * as grapqhlDashboardHelpers from '../../dashboard/graphql-dashboard-helpers';

export const configUpdated = createAction('UniversalConfigSave:CONFIG_UPDATED');
export const isSaving = createAction('UniversalSave:IS_SAVING');

const prepareWidgetData = (
  dashboardId,
  getState,
  configFromVizPreviewManager = {},
) => {
  const { config, queries = [], source: rawSource = {} } = getState().universal;
  const { integration, service_account_id: serviceAccountId } = rawSource;

  const cleanConfig = removeKeysWithUnderscore(arrangeConfig(config));

  // It's possible that the title, label or comparison label has been updated via the viz preview manager
  // and we'll need to merge those in before we save the widget
  const {
    title: newTitle,
    label: newLabel,
    comparisonLabel: newComparisonLabel,
  } = configFromVizPreviewManager;

  if (newTitle) {
    cleanConfig.title = newTitle;
  }

  if (newLabel) {
    cleanConfig.label = newLabel;
  }

  if (newComparisonLabel) {
    cleanConfig.comparisonLabel = newComparisonLabel;
  }

  const { queryMetas = [] } = cleanConfig;
  const source = { integration };

  // Remove values when field has query_custom_values and add source to query
  const amendedMetas = queryMetas.map((meta) => {
    const { select = [] } = meta;
    const amendedSelect = select.map((selectMeta) =>
      selectMeta.query_custom_values
        ? omit(
            {
              ...selectMeta,
              query_custom_values: {
                ...selectMeta.query_custom_values,
                source,
              },
            },
            'values',
          )
        : selectMeta,
    );
    return {
      ...meta,
      select: amendedSelect,
    };
  });

  // Inject source into all queries
  const beQueries = queries.map((query) => ({ ...query, source }));

  const data = {
    dashboard_id: dashboardId,
    config: {
      ...cleanConfig,
      queries: beQueries,
      template: TEMPLATES[config.type],
      queryMetas: amendedMetas,
    },
    service_name: integration,
    service_account_id: serviceAccountId,
  };

  return data;
};

const saveUserPreferences = async (getState) => {
  const { universal } = getState();
  try {
    await setPreferences(universal);
  } catch (e) {
    // silently fails it's a nice to have
  }
  return Promise.resolve();
};

const createAndTrack = async (widgetData, type) => {
  const { service_name: serviceName } = widgetData;

  const widget = await create(widgetData);

  trackEvent(
    widgetCreated({
      'Integration name': widget.services[0].name,
      'Integration slug': serviceName,
      'Legacy widget ID': `${widget.id}`,
      Visualisation: type,
      'Widget type ID': `${widget.widget_type_id}`,
    }),
  );

  return widget;
};

export const createWidget = (dashboardId) => async (dispatch, getState) => {
  try {
    const {
      universal: {
        config: { type },
        preset: { id: presetId } = {},
        queriesFetchDone,
        transformedData,
      },
    } = getState();
    const widgetData = prepareWidgetData(dashboardId, getState);
    const { service_name: serviceName } = widgetData;
    await saveUserPreferences(getState);

    if (presetId) {
      // We are passing the presetId into the widget config, so we are able to track
      // which widgets were created from which presets in the DB
      widgetData.config.from_preset = presetId;
    }

    dispatch(isSaving(true));
    dispatch(cancelQueries('saved widget', true));

    const widget = await createAndTrack(widgetData, type);

    if (every(queriesFetchDone)) {
      widgetStorage.setItem(
        `widgetData:${widget.data_id}`,
        JSON.stringify(transformedData),
      );
    }

    redirect(
      pathHelpers.getDashboardPath(
        dashboardId.toString(),
        widget.id,
        serviceName,
      ),
      300,
    );
  } catch (error) {
    dispatch(isSaving(false));
    dispatch(universalSetError(error));
  }
};

export const updateWidget =
  (
    widgetKey,
    dashboardId,
    instrumentId,
    configFromVizPreviewManager = {},
    isOwnedByRoadie,
  ) =>
  async (dispatch, getState) => {
    let originalWidgetData;
    try {
      const {
        universal: {
          queriesFetchDone,
          transformedData,
          source,
          config: { type },
          queries,
          error,
          service,
        },
        widgetData = {},
      } = getState();

      // If we're saving from the dashboard we don't want to save
      // if there is an error in the config or if the initial
      // config bootstrap hsan't finished yet
      if (error || !queries || !queries.length) {
        return;
      }

      // Preview fully loaded. We've got the full data payload
      const widgetDataReady = every(queriesFetchDone);
      const newWidgetData = prepareWidgetData(
        dashboardId,
        getState,
        configFromVizPreviewManager,
      );

      originalWidgetData = widgetData[widgetKey];
      // We are optimistic! Saving the widget will succeed so we can replace the
      // content of your widget already

      if (!widgetDataReady) {
        // Force loading state for the widget
        dispatchToGeckoJS(
          'widget:waiting_initial_server_side_refresh',
          widgetKey,
        );
      }
      // TODO: Handle no data to visualize state

      const newWidgetContext = {
        config: newWidgetData.config,
        data: widgetDataReady ? transformedData : undefined,
        service_account_id: source.service_account_id,
        widgetKey,
        ...(instrumentId ? { instrumentId } : {}),
      };

      dispatch(configUpdated(newWidgetContext));
      dispatchToGeckoJS('widget:title:change', {
        widgetKey,
        title: newWidgetContext.config.title,
      });

      await saveUserPreferences(getState);

      // For Dashies, we force the Instrument into a loading state
      dispatch(instrumentDataActions.setIsLoading({ instrumentId }));

      dispatch(isSaving(true));
      dispatch(cancelQueries('saved widget', true));

      if (isOwnedByRoadie) {
        const newData = {
          ...newWidgetData,
          service_title: service.title,
          originalGoal: {
            targetValue: originalWidgetData?.data?.threshold,
            startingValue: originalWidgetData?.data?.comparison?.startingValue,
          },
        };
        await editInstrument(instrumentId, newData);

        widgetStorage.removeItem(`instrumentData:${instrumentId}`);
      } else {
        const widget = await update(widgetKey, newWidgetData);

        // It is necessary to add a delay here for the sake of Dashies. Due to some issues with Roadie replication,
        // we can sometimes end up triggering a refresh of the data before the roadie-replicator has had time to take
        // the new data and get it into Roadie. So we just give it a tiny bit of extra time to avoid this issue.
        await delay(500);

        trackEvent(
          widgetConfigEdited({
            'Legacy widget ID': `${widget.id}`,
            Visualisation: type,
            'Integration name': service.title,
            'Integration slug': service.name,
          }),
        );

        const graphqlDashboard =
          grapqhlDashboardHelpers.getDashboardFromStorage(dashboardId) || {};
        const isContainerLayout =
          grapqhlDashboardHelpers.isContainerLayout(graphqlDashboard);

        if (isContainerLayout && instrumentId) {
          widgetStorage.removeItem(`instrumentData:${instrumentId}`);
        } else {
          widgetStorage.removeItem(`widgetData:${widgetKey}`);
        }

        const { goal: originalGoal, comparision: originalComparision } =
          widget.config;

        const { goal: newGoal, comparision: newComparision } =
          newWidgetContext.config;

        const hasGoalValuesChanged =
          !isEqual(originalGoal, newGoal) ||
          !isEqual(originalComparision, newComparision);

        const hasOriginalGoalValues =
          !isUndefined(originalGoal) || !isUndefined(originalComparision);

        const hasNewGoalValues =
          !isUndefined(newGoal) || !isUndefined(newComparision);

        if (hasGoalValuesChanged) {
          if (!hasOriginalGoalValues) {
            trackEvent(
              widgetGoalAdded({
                'Legacy widget ID': `${widget.id}`,
                Visualisation: type,
                'Integration name': service.title,
                'Integration slug': service.name,
              }),
            );
          } else if (hasNewGoalValues) {
            trackEvent(
              widgetGoalEdited({
                'Legacy widget ID': `${widget.id}`,
                Visualisation: type,
                'Integration name': service.title,
                'Integration slug': service.name,
              }),
            );
          } else {
            trackEvent(
              widgetGoalRemoved({
                'Legacy widget ID': `${widget.id}`,
                Visualisation: type,
                'Integration name': service.title,
                'Integration slug': service.name,
              }),
            );
          }
        }
      }
    } catch (error) {
      dispatch(isSaving(false));
      dispatch(universalSetError(error));
      if (originalWidgetData) {
        // Restore widget state before opening the config and alert user
        dispatch(configUpdated(originalWidgetData));
        dispatchToGeckoJS('widget:title:change', {
          widgetKey,
          title: originalWidgetData.config.title,
        });
        dispatch(toastActions.showGenericErrorToast());
      }
    }
  };

export const addStraightToDashboard =
  (service, dashboardId, presetId, isOwnedByRoadie) =>
  async (dispatch, getState) => {
    await dispatch(initConfig(service.name, dashboardId, presetId, false));

    let widget;
    let instrument;

    const {
      universal: {
        config: { type },
        service: { title },
      },
      dataSourceConnector: { serviceName: connectedServiceName } = {},
    } = getState();

    try {
      const widgetData = prepareWidgetData(dashboardId, getState);

      dispatch(isSaving(true));
      dispatch(cancelQueries('saved widget', true));

      if (isOwnedByRoadie) {
        const data = { ...widgetData, service_title: title };

        instrument = await createInstrument(dashboardId, data);
      } else {
        widget = await createAndTrack(widgetData, type);
      }
    } catch (error) {
      dispatch(isSaving(false));
      dispatch(universalSetError(error));
    }

    if (requiresImport(service.name)) {
      // We are always going to show an importing modal after authenticating for now.
      // Later we will detect if importing is necessary or not, and then either
      // show a modal, or open the edit widget modal immediately.
      if (isUndefined(connectedServiceName)) {
        await getWindow().sessionStorage.setItem(
          STORAGE_IMPORTING_SERVICE,
          JSON.stringify({ name: service.name, title: service.title }),
        );
      }
    } else {
      try {
        if (widget) {
          await localForage.setItem(
            STORAGE_KEY_LAST_ADDED_WIDGET,
            widget.data_id,
          );
        } else if (instrument) {
          await localForage.setItem(
            STORAGE_KEY_LAST_ADDED_INSTRUMENT,
            instrument.id,
          );
        }
      } catch (e) {
        // We're swallowing errors here, as in some instances, this is throwing an exception that then
        // freezes the Preset Panel. If this does error, it just means that the compact config won't auto-open after
        // redirect, which I think we can live with. We'll still log it out / send it to Bugsnag though.
        bugsnag.send(e);
      }
    }

    // on dashies we don't use the widgetId in the URL, so if we're on a ROD
    // and have created an instrument, it's ok to leave widgetId undefined.
    redirect(
      pathHelpers.getDashboardPath(
        dashboardId.toString(),
        widget?.id || undefined,
        service.name,
      ),
      300,
    );
  };
