import PropTypes from 'prop-types';
import { Component } from 'react';
import { history } from '../router';

class HistoryProvider extends Component {
  getChildContext() {
    return { history };
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

HistoryProvider.childContextTypes = {
  history: PropTypes.object,
};

HistoryProvider.propTypes = { children: PropTypes.node };

export default HistoryProvider;
