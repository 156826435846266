import { useCallback, useContext, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import AuthStrategy from '@Lib/auth-strategy';
import FeatureFlagContext from './feature-flag-context';

export const ORG_QUERY = gql`
  query LaunchDarklyOrgId {
    whoAmI {
      organizationId
    }
  }
`;

const FeatureFlagSetup = () => {
  const { ffClientCreator } = useContext(FeatureFlagContext);

  const initializeClient = useCallback(
    (data) => {
      if (!data?.whoAmI?.organizationId) {
        // TODO: Error to bugsnag?
        return;
      }

      ffClientCreator.create(data.whoAmI.organizationId);
    },
    [ffClientCreator],
  );

  const { refetch } = useQuery(ORG_QUERY, {
    onCompleted: initializeClient,
  });

  useEffect(() => {
    // If we're on a public dashboard, the auth strategy has to be set
    // to make a request to concierge. This means we'll get an error on the
    // first try (before auth strategy has been set) and that we will refetch
    // once we have an auth strategy.
    // It's important to be aware that on edit dashboards we don't use the
    // auth strategy which is why we have to attempt to make the initial
    // query right away as onAuthStrategySet will never be called on the edit page.
    const authStrategy = AuthStrategy.getInstance();
    const listener = authStrategy.onAuthStrategySet(() => refetch());

    return () => {
      listener.destroy();
    };
  }, [refetch, initializeClient]);

  return null;
};

export default FeatureFlagSetup;
