import { isUndefined } from 'lodash';
import {
  getYAxisFormat,
  getGeckometerValue,
  destructureSalesforceConfigWrap,
} from './helpers';
import { transform as genericGeckometerTransform } from '../per-visualisation/geckometer';
import { composeTransformers } from '../compose-transformers';

const geckometerTransform = (
  pegasusPayload,
  config = {},
  timezone,
  initialContext = {},
) => {
  const {
    total,
    axis_metadata: axisMetadata = {},
    series = [],
  } = pegasusPayload;

  const [dataFormat, unit] = getYAxisFormat(
    axisMetadata.y_type,
    axisMetadata.y_unit,
  );

  const format = 'datetime' === dataFormat ? 'decimal' : dataFormat; // Cannot handle date
  const item = getGeckometerValue(config, total, series);

  if (isUndefined(item)) {
    return {};
  }

  return {
    ...initialContext,
    item,
    format,
    unit,
  };
};

const transform = destructureSalesforceConfigWrap(
  composeTransformers(geckometerTransform, genericGeckometerTransform),
);

export { transform };
