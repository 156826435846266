import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';
import { LOCATIONS, VISUALISATION_TYPES } from '@Tracking/constants';

const sharedIntegrationSchemaProperties = {
  'Auth type': SchemaTypes.oneOf(['oauth', 'oauth2', 'basic', 'null'])
    .isRequired,
  'Integration name': SchemaTypes.string.isRequired,
  'Integration slug': SchemaTypes.string.isRequired,
  'Data source': SchemaTypes.string,
};

export const integrationSelected = defineEvent('Integration - Selected', {
  ...sharedIntegrationSchemaProperties,
  Location: SchemaTypes.oneOf([
    LOCATIONS.DashboardAddWidgetDropdown,
    LOCATIONS.IntegrationsMainList,
    LOCATIONS.IntegrationsRecentList,
    LOCATIONS.IntegrationsPopularList,
  ]).isRequired,
});

export const integrationClickedConnect = defineEvent(
  'Integration - Clicked connect',
  sharedIntegrationSchemaProperties,
);

export const integrationSelectedDataSource = defineEvent(
  'Integration - Selected data source',
  sharedIntegrationSchemaProperties,
);

export const integrationSelectedWidget = defineEvent(
  'Integration - Selected widget',
  {
    ...sharedIntegrationSchemaProperties,
    'Widget name': SchemaTypes.string.isRequired,
  },
);

export const integrationSelectedFile = defineEvent(
  'Integration - Selected file',
  sharedIntegrationSchemaProperties,
);

export const integrationDeletedFile = defineEvent(
  'Integration - Deleted file',
  sharedIntegrationSchemaProperties,
);

export const spreadsheetsSubmittedUrl = defineEvent(
  'Spreadsheets - Submitted URL',
  sharedIntegrationSchemaProperties,
);

export const integrationQueryStarted = defineEvent(
  'Integration - Query started',
  sharedIntegrationSchemaProperties,
);

export const integrationQueryFinished = defineEvent(
  'Integration - Query finished',
  {
    ...sharedIntegrationSchemaProperties,
    'Query success': SchemaTypes.bool,
  },
);

export const integrationPresetSelectionDismissed = defineEvent(
  'Integration - Preset selection dismissed',
  {
    ...sharedIntegrationSchemaProperties,
  },
);

export const integrationConnectionSetupDismissed = defineEvent(
  'Integration - Connection setup dismissed',
  {
    ...sharedIntegrationSchemaProperties,
  },
);

export const spreadsheetsUrlSucceeded = defineEvent(
  'Spreadsheets - URL succeeded',
  sharedIntegrationSchemaProperties,
);

export const spreadsheetsUrlFailed = defineEvent('Spreadsheets - URL failed', {
  ...sharedIntegrationSchemaProperties,
  'URL failure reason': SchemaTypes.string.isRequired,
  'Failure Spreadsheet URL': SchemaTypes.string.isRequired,
});

export const spreadsheetsConfirmedSelection = defineEvent(
  'Spreadsheets - Confirmed selection',
  {
    'Spreadsheet selection': SchemaTypes.string.isRequired,
    'Spreadsheet selection format': SchemaTypes.listOfStrings,
    'Selections are invalid': SchemaTypes.bool,
    Visualisation: SchemaTypes.oneOf(VISUALISATION_TYPES),
  },
);

export const spreadsheetsLoadingFailed = defineEvent(
  'Spreadsheets - Loading failed',
  {
    'Failure reason': SchemaTypes.oneOf([
      'Worksheet too large',
      'File too large',
    ]).isRequired,
  },
);

export const integrationDatasetWorkflowSelected = defineEvent(
  'Workflow - Selected',
  {
    ...sharedIntegrationSchemaProperties,
    'Workflow ID': SchemaTypes.string.isRequired,
  },
);

export const integrationDatasetWorkflowSetupSubmitted = defineEvent(
  'Workflow - Submit parameters',
  {
    'Integration slug': SchemaTypes.string.isRequired,
    'Workflow ID': SchemaTypes.string.isRequired,
  },
);
