import * as dataSourcesActions from '../actions/data-sources-actions';
import { find } from 'lodash';

const dataSourcesReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case dataSourcesActions.set.type:
      // Services are used in multiple places throughout the app.
      // For the list of integrations we use useIntegrations() hook.
      return {
        ...state,
        services: payload.services,
        recentlyConnectedIds: payload.recently_connected,
      };
    case dataSourcesActions.update.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { services: current = [] } = state || {};
      if (!current || !current.length) {
        return {
          ...state,
          services: payload,
        };
      }

      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const services = current.map((s) => {
        const newService = find(payload, { id: s.id });
        return { ...s, ...newService };
      });
      return {
        ...state,
        services,
      };
    default:
      return state;
  }
};

export default dataSourcesReducer;
