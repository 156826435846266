import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { isFunction, isEmpty } from 'lodash';

// Allows us to pass Backbone views off as React components
// By proxying rendering through a slim wrapper component.
// The upside being that we can use them with react-router.

let COMPONENTS = {};
let broker;

function clearViews() {
  COMPONENTS = {};
}

const dispatchToGeckoJS = (name, options) => {
  if (broker) {
    broker.trigger(name, options);
  } else {
    // If there are no views registered, we probably aren't using Gecko-JS
    // However if there are views registered and there's no broker, we have a problem
    if (!isEmpty(COMPONENTS)) {
      throw new Error('No broker registered');
    }
  }
};

class GeckoComponent extends Component {
  componentDidMount() {
    const { View } = this.props;
    // eslint-disable-next-line react/no-find-dom-node -- Disabled by codemod when new recommended rulesets introduced
    const domNode = findDOMNode(this);
    const view = (this.__view__ = new View(this.props));

    // Expose the gecko-js broker to pass messages (while we work on theming)
    broker = view.getBroker();

    domNode.insertBefore(view.getDOMNode(), domNode.firstChild);
    view.render(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.__view__.render(nextProps);
  }

  componentWillUnmount() {
    this.__view__.remove();
  }

  render() {
    const { children } = this.props;
    return <div className="gecko-js-bridge">{children}</div>;
  }
}

GeckoComponent.propTypes = {
  View(props, propName) {
    const View = props[propName];

    if (!isFunction(View.prototype.render)) {
      return new Error(`\`render\` method on ${View.name} is missing`);
    }

    if (!isFunction(View.prototype.getDOMNode)) {
      return new Error(`\`getDOMNode\` method on ${View.name} is missing`);
    }

    return null;
  },
  children: PropTypes.node,
};

function useView(viewName) {
  if (__DEV__ && !COMPONENTS[viewName]) {
    throw new Error(`No GeckoView registered with key "${viewName}"`);
  }

  return COMPONENTS[viewName];
}

function registerView(viewName, View) {
  if (__DEV__ && COMPONENTS[viewName]) {
    throw new Error(
      `Attempt to register duplicate view with key "${viewName}"`,
    );
  }

  class WrappedGeckoComponent extends Component {
    render() {
      return <GeckoComponent {...this.props} View={View} />;
    }
  }
  COMPONENTS[viewName] = WrappedGeckoComponent;
}

export { GeckoComponent, registerView, clearViews, useView, dispatchToGeckoJS };
