import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';
import { LIMIT_GATE_TYPES } from '@Tracking/constants';

export const integrationListSearched = defineEvent(
  'Integration list - Searched',
  {
    'Search term': SchemaTypes.string,
    'Search results count': SchemaTypes.number,
  },
);

export const buttonClicked = defineEvent('Button - Clicked', {
  'Button identifier': SchemaTypes.string,
});

export const externalLinkClicked = defineEvent('External link - Clicked', {
  'Link identifier': SchemaTypes.string,
});

export const welcomeSurveyDismissed = defineEvent('Welcome survey - Dismissed');

export const welcomeSurveySubmitted = defineEvent('Welcome survey - Submitted');

export const visualisationPickerVisualisationsSuggested = defineEvent(
  'Visualisation picker - Visualisations suggested',
  {
    'Integration name': SchemaTypes.string.isRequired,
    'Integration slug': SchemaTypes.string.isRequired,
    'Data source': SchemaTypes.string,
    'Visualisation picker options': SchemaTypes.listOfStrings.isRequired,
  },
);

export const limitGateDisplayed = defineEvent('Limit gate - Displayed', {
  'Gate type': SchemaTypes.oneOf([
    LIMIT_GATE_TYPES.Dashboard,
    LIMIT_GATE_TYPES.User,
    LIMIT_GATE_TYPES.Screen,
  ]).isRequired,
});

export const customLogoUploaded = defineEvent('Customize - Logo uploaded');

export const customLogoRemoved = defineEvent('Customize - Logo removed');

export const dropdownSearched = defineEvent('Dropdown - Dropdown searched', {
  'Dropdown identifier': SchemaTypes.oneOf([
    'Metric dropdown',
    'Filter dropdown',
  ]),
  'Search term': SchemaTypes.string.isRequired,
  'Integration name': SchemaTypes.string.isRequired,
  'Integration slug': SchemaTypes.string.isRequired,
});

export const importFinished = defineEvent('Import - Finished', {
  'Service account ID': SchemaTypes.string.isRequired,
  'Event source': SchemaTypes.string.isRequired,
  'Integration slug': SchemaTypes.string.isRequired,
  'Import success': SchemaTypes.bool.isRequired,
});
