import {
  getServiceByName,
  getAllServiceAccounts,
} from '../../services/management-service';
import { auth } from '../../lib/oauth';
import { open as openPopup } from '../../lib/popup-window';
import { createConnection } from '../../services/data-source-service';
import servicePicker from '../../services/service-picker';
import { trackEvent, conform } from '@Tracking';
import { connectionCreated, connectionFailed } from '@Tracking/events';
import { result, filter, last } from 'lodash';
import initFlow from './init-flow-action';
import { fileFetchFailed } from './fetch-files-action';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { dispatchToGeckoJS } from '../../lib/gecko-view';
import { ERROR_TYPES } from '../components/services/spreadsheets-connector/types';

export const createConnectionSuccessful = createAction(
  'DataSourceConnector:CREATE_CONNECTION_SUCCESS',
);
export const createConnectionFailed = createErrorAction(
  'DataSourceConnector:CREATE_CONNECTION_ERROR',
);
export const connectionCancelled = createAction(
  'DataSourceConnector:CANCEL_CONNECTION',
);
export const fetchServiceSuccessful = createAction(
  'DataSourceConnector:FETCH_SERVICE_SUCCESS',
);
export const fetchServiceFailed = createErrorAction(
  'DataSourceConnector:FETCH_SERVICE_ERROR',
);
export const switchServiceStart = createAction(
  'DataSourceConnector:SWITCHING_SERVICE',
);
export const switchServiceSuccessful = createAction(
  'DataSourceConnector:SWITCH_SERVICE',
);
export const switchAccountStart = createAction(
  'DataSourceConnector:SWITCHING_ACCOUNT',
);
export const switchAccountSuccessful = createAction(
  'DataSourceConnector:SWITCH_ACCOUNT',
);
export const accountAddSuccessful = createAction(
  'DataSourceConnector:ADD_ACCOUNT',
);
export const accountAddFailed = createErrorAction(
  'DataSourceConnector:ADD_ACCOUNT_ERROR',
);
export const setState = createAction('DataSourceConnector:SET');

function getFreshServiceAccountId(serviceAccounts, serviceName) {
  const serviceAccount = filter(serviceAccounts, {
    service: { name: serviceName },
  });

  const serviceAccountId = result(last(serviceAccount), 'id');

  return serviceAccountId && parseInt(serviceAccountId, 10);
}

// **onaddconnectionsuccess** callback replace GeckoJS handler after successfully
// connect an account. GeckoJS handle closing the connection panel and redirect
// to service page.

export const addConnection =
  (service, extraParams, onAddConnectionSuccess) => async (dispatch) => {
    try {
      const serviceAccountId = await createConnection(service, extraParams);

      trackEvent(
        connectionCreated({
          'Auth type': service.auth_type,
          ...conform.integration({
            name: service.title,
            slug: service.name,
          }),
        }),
      );

      dispatch(createConnectionSuccessful(service));
      if (onAddConnectionSuccess) {
        onAddConnectionSuccess(serviceAccountId);
      } else {
        dispatchToGeckoJS('connect:service:success', service);
      }
    } catch (error) {
      // don't dispatch error if it's just the window closing
      if (error.message !== 'Window closed') {
        trackEvent(
          connectionFailed({
            'Auth type': service.auth_type,
            ...conform.integration({
              name: service.title,
              slug: service.name,
            }),
            'Connection failure reason': error.message,
          }),
        );
        dispatch(createConnectionFailed(error, error.message));
      }
    }
  };

export const cancelConnection = () => (dispatch) => {
  dispatch(setState({ isPanelVisible: false }));

  setTimeout(() => {
    dispatch(connectionCancelled());
    dispatchToGeckoJS('connect:account:cancel', {});
  }, 200);
};

export const initConnector = (serviceName) => async (dispatch) => {
  try {
    const service = await getServiceByName(serviceName);

    dispatch(fetchServiceSuccessful({ service }));
  } catch (error) {
    dispatch(fetchServiceFailed(error, error.message));
  }
};

export const switchService = (service) => async (dispatch) => {
  const { name, serviceAccounts } = service;
  const serviceAccountId = parseInt(result(last(serviceAccounts), 'id'), 10);

  dispatch(switchServiceStart({ serviceName: name, serviceAccountId }));

  try {
    const files = await servicePicker(name).fetchList(name, {
      serviceAccountId,
    });
    dispatch(
      switchServiceSuccessful({ serviceName: name, serviceAccountId, files }),
    );
  } catch (error) {
    dispatch(fileFetchFailed(error));
  }
};

// Used for spreadshees and salesforce
export const addAccount = (service) => async (dispatch) => {
  // Open the window syncronously so we don't fall foul of pop up blockers
  const popup = openPopup('geckoOauth');

  try {
    await auth(popup, service, {}); // Service connected: Fetch again

    trackEvent(
      connectionCreated({
        'Auth type': service.auth_type,
        ...conform.integration({
          name: service.title,
          slug: service.name,
        }),
      }),
    );

    // Get last service account for default unified service
    const serviceAccounts = await getAllServiceAccounts(service.name);
    const serviceAccountId = getFreshServiceAccountId(
      serviceAccounts,
      service.name,
    );

    if (!serviceAccountId) {
      throw new Error(`No service account for ${service.name}`);
    }

    dispatch(
      // Early dispatch to give feedback to ui
      switchAccountStart({ serviceAccountId }),
    );

    try {
      const files = await servicePicker(service.name).fetchList(service.name, {
        serviceAccountId,
      });

      dispatch(
        accountAddSuccessful({
          serviceName: service.name,
          serviceAccounts,
          serviceAccountId,
          files,
        }),
      );
    } catch (error) {
      dispatch(fileFetchFailed(error));
    }
  } catch (error) {
    trackEvent(
      connectionFailed({
        'Auth type': service.auth_type,
        ...conform.integration({
          name: service.title,
          slug: service.name,
        }),
        'Connection failure reason': error.message,
      }),
    );

    // don't dispatch error if it's just the window closing
    if (error.message !== 'Window closed') {
      dispatch(
        accountAddFailed(error, {
          message: error.message,
          serviceName: service.name,
          errorType: ERROR_TYPES.ACCOUNT_CONNECTION_FAILED,
        }),
      );
    }
  }
};

export const switchAccount =
  (serviceName, serviceAccountIdString) => async (dispatch) => {
    const serviceAccountId = parseInt(serviceAccountIdString, 10);

    dispatch(switchAccountStart({ serviceAccountId }));

    try {
      const files = await servicePicker(serviceName).fetchList(serviceName, {
        serviceAccountId,
      });
      dispatch(
        switchAccountSuccessful({
          serviceAccountId,
          serviceName,
          files,
        }),
      );
    } catch (error) {
      dispatch(fileFetchFailed(error));
    }
  };

export const initPanelOldIntegration = () => (dispatch) =>
  dispatch(setState({ isPanelVisible: true }));

export const init =
  ({ dashboardId, groupId, services, serviceName, serviceType }) =>
  (dispatch) => {
    dispatch(
      initFlow({
        dashboardId,
        groupId,
        services,
        serviceName,
        serviceType,
      }),
    );
  };
