import React, { Component, createContext } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { withHistory } from '../router';
import { getWindow } from '@Lib/global';

export const fakeIntercom = (...args) => {
  const argString = args.join('/');
  // eslint-disable-next-line no-console
  console.log('Intercom message:', argString);
};

export const messages = {
  support: "Hi! I'd like to request support for: ",
};

export const IntercomContext = createContext({
  available: false,
  intercom: undefined,
  messages: {},
});

export const intercomContextTypes = PropTypes.shape({
  available: PropTypes.bool,
  intercom: PropTypes.func,
  messages: PropTypes.object,
});

export class IntercomProvider extends Component {
  constructor(props) {
    super(props);

    /*
     * Sometimes the route of our app can change
     * multiple times very quickly. For example
     * when we automatically redirect users to
     * their current dashboard. Debouncing this
     * update method will mean we don't update
     * Intercom's context unnecessarily.
     */
    this.update = debounce(this.update.bind(this), 500);

    this.state = {
      intercom: (...args) => this.intercom(...args),
      available: this.available,
      messages,
    };
  }

  componentDidMount() {
    /*
     * We need to tell intercom to update its
     * context when the route changes.
     */
    this.unlisten = this.props.history.listen(this.update);

    /*
     * It's possible that this component is mounted
     * before Intercom is available on the window.
     *
     * In this case lets try one more time after one
     * second to find Intercom. This will hopefully
     * be enough to remove any race conditions.
     */
    if (!this.state.available) {
      this.timeout = setTimeout(() => {
        this.setState({
          intercom: (...args) => this.intercom(...args),
          available: this.available,
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    /*
     * Don't try and update state if the
     * component has been unmounted.
     */
    clearTimeout(this.timeout);

    if (typeof this.unlisten === 'function') this.unlisten();
  }

  get intercom() {
    return this.available ? getWindow().Intercom : fakeIntercom;
  }

  get available() {
    return getWindow() && !!getWindow().Intercom;
  }

  render() {
    return (
      <IntercomContext.Provider value={this.state}>
        {this.props.children}
      </IntercomContext.Provider>
    );
  }

  update() {
    this.intercom('update');
  }
}

IntercomProvider.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withHistory(IntercomProvider);
