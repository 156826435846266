import update from 'immutability-helper';
import { cloneDeep, isEmpty, set } from 'lodash';
import * as pickerActions from '../../file-picker/actions/picker-actions';
import * as dynamicConfigActions from '../actions/dynamic-config-actions';
import * as selectionActions from '../actions/selection-actions';
import * as spreadsheetActions from '../actions/spreadsheet-actions';
import * as spreadsheetConfigActions from '../actions/spreadsheet-config-actions';
import getVisualisationHelpers from '../helpers/get-visualisation-helpers';
import sanitizeConfig from '../helpers/sanitize-config';
import * as selectionHelpers from '../helpers/selection-helpers';
import {
  extractMeta,
  toValidConfig,
} from './spreadsheet-config-reducer-helpers.js';

export const initialState = {
  config: {},
  initialConfig: {},
  previousConfigs: {},
  activeFieldPath: undefined,
  fileId: undefined,
  title: undefined,
  worksheetId: '',
  worksheets: new Map(),
  loading: true,
  isInitLoading: true,
  bounds: {
    columns: 10,
    rows: 10,
  },
  fetchErrorType: undefined,
  showFilePicker: false,
  dynamicEditor: {
    config: {},
    inputValidity: {},
  },
};

const withoutIndex = (array, index) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

const spreadsheetConfigReducerInner = (state = initialState, action) => {
  let newConfig;
  const { type, payload } = action;

  switch (type) {
    case spreadsheetConfigActions.initSpreadsheetConfigStart.type:
      return {
        ...state,
        isInitLoading: true,
      };
    case spreadsheetActions.worksheetFetched.type:
      return {
        ...state,
        ...extractMeta(payload.worksheet, payload.spreadsheet),
        isInitLoading: false,
      };
    case spreadsheetActions.loading.type:
      return {
        ...state,
        loading: true,

        // Reset any previous state for errored speadsheet as
        // a new sheet has been selected
        fetchErrorType: undefined,
      };
    case selectionActions.clearPreviousConfigs.type: {
      return {
        ...state,
        previousConfigs: {},
      };
    }
    case spreadsheetConfigActions.setTitle.type:
      return {
        ...state,
        config: {
          ...state.config,
          title: payload,
        },
      };
    case spreadsheetConfigActions.initSpreadsheetConfigSuccessful.type:
      // Only apply the default config when no config
      // has previously been applied, this prevents us getting in
      // a state whereby config exists that can't be applied to
      // the default visualisation
      newConfig = state.config;

      if (isEmpty(state.config)) {
        newConfig = {
          title: '',
          ...payload.config,
        };
      }
      return {
        ...state,
        config: newConfig,
        isFileMissing: false,
        ...extractMeta(payload.worksheet, payload.spreadsheet),
        isInitLoading: false,
      };
    case spreadsheetConfigActions.widgetConfigOnlySuccessful.type: {
      const { key, config } = payload;
      const newConfig = { widgetKey: key, ...config };
      return {
        ...state,
        config: newConfig,
        initialConfig: newConfig,
      };
    }
    case spreadsheetConfigActions.widgetConfigFetchSuccessful.type: {
      const { key: widgetKey, spreadsheetErrorType, config } = payload;

      newConfig = { widgetKey, spreadsheetErrorType, ...config };

      /*
      Lets ensure both of the spreadsheets UI supports both of the potential config formats.

      We store values and comparison values differenly depending on the spreadsheets UI it was created or last edited with for number widgets with a number, percentage or sparkline comparison.

      The legacy spreadsheets config format:

        {
          "type": "number",
            "value": "[[1,0],[1,15]]",
            "comparison": {
              "type": "sparkline"
            }
        }

      The spreadsheets config format:

        {
          "type": "number",
            "value": "[[1,15],[1,15]]", // <-- value has changed to reference to last selected cell
            "comparison": {
              "type": "sparkline",
              "value:" [[1,0],[1,15]] // <-- new value property on the comparison objects which references the fully selected cell range
            }
        }

      The spreadsheets config format sports these two config changes:

      1) The array referenced via value is copied into comparison.value.
      2) The original value is changed to only reference the last selected cell.
      **/

      if (newConfig.type === 'number' && newConfig.comparison) {
        const { type } = newConfig.comparison;
        if (
          type === 'number' ||
          type === 'percentage' ||
          type === 'sparkline'
        ) {
          const hasLegacySpreadsheetsConfigFormat = !newConfig.comparison.value;

          if (hasLegacySpreadsheetsConfigFormat) {
            const { value } = newConfig;
            const [, lastCell] = value;

            newConfig = {
              ...newConfig,
              value: [lastCell, lastCell],
              comparison: {
                ...newConfig.comparison,
                value,
              },
            };
          }
        }
      }

      return {
        ...state,
        config: newConfig,
        initialConfig: newConfig,
        isFileMissing: false,
        ...extractMeta(payload.worksheet, payload.spreadsheet),
        isInitLoading: false,
      };
    }
    case spreadsheetConfigActions.initSpreadsheetConfigFailed.type:
    case spreadsheetActions.worksheetFetchFailed.type:
    case spreadsheetConfigActions.widgetConfigFetchFailed.type: {
      const { error: { type: errorType } = {} } = action;
      const handledErrors = [
        'ErrResourceNotFound',
        'ErrServiceAccountNotFound',
        'ErrFileTooBig',
      ];

      return {
        ...state,
        fetchErrorType: handledErrors.includes(errorType)
          ? errorType
          : 'ErrUnknown',
        isInitLoading: false,
        loading: false,
      };
    }

    case spreadsheetConfigActions.doSetValues.type:
      return {
        ...state,
        config: {
          ...state.config,
          ...payload.state,
        },
        activeFieldPath: payload.activeFieldPath || state.activeFieldPath,
      };

    case spreadsheetConfigActions.setConfig.type: {
      let { config } = action.payload;
      const { selections, vizType } = action.payload;

      if (!config?.type) {
        const vizHelper = getVisualisationHelpers(vizType);
        const defaultConfig = vizHelper.getDefaultConfig(selections);
        config = defaultConfig;
      }

      return {
        ...state,
        config,
      };
    }

    case spreadsheetConfigActions.updateNumberFormat.type: {
      const { config } = state;
      let numberFormat = state.config.numberFormat;

      if (config.type === 'table') {
        const { index, format = {} } = payload;
        numberFormat = numberFormat ? [...numberFormat] : [];
        numberFormat[index] = {
          ...numberFormat[index],
          ...format,
        };
      } else {
        numberFormat = {
          ...numberFormat,
          ...payload,
        };
      }

      return {
        ...state,
        config: {
          ...config,
          numberFormat,
        },
      };
    }

    case spreadsheetConfigActions.toggleFilePicker.type:
      return {
        ...state,
        showFilePicker: payload,
      };

    case spreadsheetActions.doSwitchVisualisationType.type: {
      const { accountId, title, type: visType } = state.config;

      return {
        ...state,
        activeFieldPath: null,
        config: {
          accountId,
          title,
          ...payload,
        },
        previousConfigs: {
          ...state.previousConfigs,
          [visType]: { ...state.config },
        },
      };
    }
    case selectionActions.clearAll.type:
      return {
        ...state,
        config: { ...state.config, type: undefined },
      };
    case pickerActions.closeFilePicker.type:
      return {
        ...state,
        showFilePicker: false,
      };
    case spreadsheetConfigActions.widgetCreationStart.type:
    case spreadsheetConfigActions.widgetUpdateStart.type:
      return {
        ...state,
        isSaving: true,
      };
    case spreadsheetConfigActions.widgetCreationFailed.type:
    case spreadsheetConfigActions.widgetUpdateFailed.type:
      return {
        ...state,
        isSaving: false,
      };
    case spreadsheetConfigActions.deleteTableColumn.type: {
      const { columns, numberFormat = [] } = state.config;

      return {
        ...state,
        config: {
          ...state.config,
          columns: withoutIndex(columns, payload),
          numberFormat: withoutIndex(numberFormat, payload),
        },
      };
    }
    case spreadsheetConfigActions.reorderTableColumn.type:
    case spreadsheetActions.reorderTableColumns.type: {
      const { sourceIndex, targetIndex } = payload;
      const { numberFormat, columns } = state.config;
      const column = columns[sourceIndex];
      const currentFormat = numberFormat[sourceIndex] || null;

      return update(state, {
        config: {
          columns: {
            $splice: [
              [sourceIndex, 1],
              [targetIndex, 0, column],
            ],
          },
          numberFormat: {
            $splice: [
              [sourceIndex, 1],
              [targetIndex, 0, currentFormat],
            ],
          },
        },
      });
    }

    case spreadsheetActions.hydrateConfigureView.type: {
      const { fieldRanges } = action.payload;

      const config = state.config;

      if (fieldRanges) {
        const selections = fieldRanges?.ranges;

        return {
          ...state,
          selections,
        };
      }

      const helpers = getVisualisationHelpers(config.type);
      let _selections = [];
      let selectionsAsObject = [];
      if (helpers) {
        _selections = helpers.getSelectionsFromConfig(config);
        selectionsAsObject = _selections.map(
          selectionHelpers.convertSelectionToObjectFormat,
        );
      }

      return {
        ...state,
        selections: selectionsAsObject,
      };
    }

    case dynamicConfigActions.hydrate.type: {
      const options = payload || {};

      return {
        ...state,
        dynamicEditor: {
          config: state.config,
          focusedInput: options.focusedInput,
          disableCanvas: options.disableCanvas,
        },
      };
    }

    case dynamicConfigActions.update.type: {
      return {
        ...state,
        dynamicEditor: {
          ...state.dynamicEditor,
          config: {
            ...state.dynamicEditor.config,
            ...payload,
          },
          inputValidity: {},
        },
      };
    }

    case dynamicConfigActions.clear.type: {
      return {
        ...state,
        dynamicEditor: {
          config: {},
        },
      };
    }

    case dynamicConfigActions.save.type: {
      return {
        ...state,
        config: sanitizeConfig(state.dynamicEditor.config),
        dynamicEditor: {
          config: {},
        },
      };
    }

    case dynamicConfigActions.focusInput.type: {
      return {
        ...state,
        dynamicEditor: {
          ...state.dynamicEditor,
          focusedInput: payload.path,
        },
      };
    }

    case dynamicConfigActions.setValue.type: {
      const { config, focusedInput } = state.dynamicEditor;
      newConfig = cloneDeep(config);

      const { value, valid } = payload;
      set(newConfig, focusedInput, value);

      return {
        ...state,
        dynamicEditor: {
          ...state.dynamicEditor,
          config: newConfig,
          inputValidity: {
            ...(state.dynamicEditor.inputValidity || {}),
            [focusedInput]: valid,
          },
        },
      };
    }

    case dynamicConfigActions.setCellRangeValue.type: {
      const { config, focusedInput } = state.dynamicEditor;
      newConfig = cloneDeep(config);

      const selectionAsObject =
        selectionHelpers.convertSelectionToObjectFormat(payload);
      const updatedSelection = selectionHelpers.convertSelectionToArrayFormat(
        selectionHelpers.updateSelection(
          selectionAsObject,
          selectionAsObject.end,
          state.options,
        ),
      );

      set(newConfig, focusedInput, updatedSelection);

      return {
        ...state,
        dynamicEditor: {
          ...state.dynamicEditor,
          config: newConfig,
          inputValidity: {
            ...(state.dynamicEditor.inputValidity || {}),
            [focusedInput]: true,
          },
        },
      };
    }

    default:
      return state;
  }
};

const ensureValidConfig = (state) => {
  return { ...state, config: toValidConfig(state) };
};

const spreadsheetConfigReducer = (state, action) => {
  return ensureValidConfig(spreadsheetConfigReducerInner(state, action));
};

export default spreadsheetConfigReducer;
