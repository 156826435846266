import { isUndefined } from 'lodash';
import { parseNumberBasedOnFormat } from '../../../lib/parse-number';

function transform(payload, { min, max } = {}, timezone, initialContext = {}) {
  const { format } = initialContext;
  const context = {};

  if (!isUndefined(min)) {
    const value =
      typeof min !== 'string' ? min : parseNumberBasedOnFormat(min, format);

    context.min = { value };
  }

  if (!isUndefined(max)) {
    const value =
      typeof max !== 'string' ? max : parseNumberBasedOnFormat(max, format);

    context.max = { value };
  }

  return { ...initialContext, ...context };
}

export { transform };
