import hsluv from 'hsluv';
import { generateAutoWidgetColor, isLight } from '../';
import themes from '../../../themes';
import { LIGHT_WIDGET_STYLE, AUTO_WIDGET_STYLE } from '../../theme-constants';

const { dark, light } = themes;

const getActiveWidgetColor = (widgetColor) => {
  const [h, s, l] = hsluv.hexToHsluv(widgetColor);

  return hsluv.hsluvToHex([h, s, l + 3]);
};

const getUserThemeVariables = (backgroundColor, widgetStyle) => {
  const baseTheme = widgetStyle === LIGHT_WIDGET_STYLE ? light : dark;

  let dashboardTextColor = dark['--dashboard-text-color'];
  let draggerColor = dark['--dragger-color'];
  let geckoboxGridColor = dark['--geckobox-grid-color'];

  if (isLight(backgroundColor)) {
    dashboardTextColor = light['--dashboard-text-color'];
    draggerColor = light['--dragger-color'];
    geckoboxGridColor = light['--geckobox-grid-color'];
  }

  const customTheme = {
    ...baseTheme,
    '--background-color': backgroundColor,
    '--dashboard-text-color': dashboardTextColor,
    '--dragger-color': draggerColor,
    '--geckobox-grid-color': geckoboxGridColor,
  };

  if (widgetStyle === AUTO_WIDGET_STYLE) {
    const widgetColor = generateAutoWidgetColor(backgroundColor);
    const [h, s, l] = hsluv.hexToHsluv(widgetColor);

    customTheme['--widget-background-color'] = widgetColor;
    customTheme['--chart-threshold-color'] = hsluv.hsluvToHex([h, s, l + 6]);
    customTheme['--map-subunit-color'] = hsluv.hsluvToHex([h, s, l + 15]);
    customTheme['--selected-widget-header-color'] = hsluv.hsluvToHex([
      h,
      s,
      l + 15,
    ]);
    customTheme['--widget-active-background-color'] = getActiveWidgetColor(
      customTheme['--widget-background-color'],
    );
  }

  return customTheme;
};

export default getUserThemeVariables;
