import { getSelectedCells } from '../../spreadsheet-helpers';
import { isFinite, map, isUndefined } from 'lodash';
import {
  getSelectionFormat,
  getMixedInputValue,
  getNumericCells,
  getGeckometerValue,
} from './helpers';
import { transform as numberFormatTransform } from '../shared/number-format';
import { transform as statusIndicatorsTransform } from './status-indicators';
import { composeTransformers } from '../compose-transformers';

const geckometerTransform = (data, config, timezone, initialContext = {}) => {
  const { cells } = data;
  const { value, goal, reverseGoalDirection, min = {}, max = {} } = config;

  const item = getGeckometerValue(value, cells);
  const [selectedCells] = getSelectedCells(cells, [value]);
  const numericCells = getNumericCells(selectedCells);
  const values = map(numericCells, 'value');

  let cellSelection = selectedCells;

  // Check format for the cell we will display not for the whole column/row
  cellSelection = selectedCells.slice(-1);

  const [format, unit] = getSelectionFormat(cellSelection);

  if (!isFinite(item)) {
    return {};
  }

  const threshold = getMixedInputValue(cells, goal, format);

  let minValue = getMixedInputValue(cells, min.value, format);
  if (isUndefined(minValue)) {
    minValue = Math.min(...values);
  }

  let maxValue = getMixedInputValue(cells, max.value, format);
  if (isUndefined(maxValue)) {
    maxValue = Math.max(...values);
  }

  return {
    ...initialContext,
    item,
    min: { value: minValue },
    max: { value: maxValue },
    threshold,
    format,
    unit,
    reverseGoalDirection,
  };
};

const transform = composeTransformers(
  geckometerTransform,
  numberFormatTransform,
  statusIndicatorsTransform,
);

export { transform };
