import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindAll } from 'lodash';
import classNames from 'classnames';

import style from './input-style.scss';

/**
 * This component is deprecated.
 *
 * Please use the Input component in @UI/forms/input
 */
class InputComponent extends Component {
  constructor(props) {
    super(props);
    bindAll(this, 'handleChange', 'handleBlur', 'focusTextInput');
  }

  handleChange(e) {
    const { onChange } = this.props;
    const value = e.target.value;

    if (onChange) {
      onChange({ value });
    }
  }

  handleBlur(e) {
    const { onBlur } = this.props;
    const value = e.target.value;

    if (onBlur) {
      onBlur({ value });
    }
  }

  render() {
    const {
      id,
      name,
      value,
      hasError,
      placeholder,
      disabled,
      isSmall,
      isUppercase,
      maxLength,
      autoFocus,
      onFocus,
      tabIndex,
      type,
    } = this.props;

    const classes = classNames(style.input, {
      [style.disabled]: disabled,
      [style.small]: isSmall,
      [style.error]: hasError,
      [style.uppercase]: isUppercase,
    });

    return (
      <input
        type={type}
        id={id}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        className={classes}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        ref={(input) => {
          this.textInput = input;
        }}
      />
    );
  }

  focusTextInput() {
    this.textInput.focus();
    const len = this.textInput.value.length;
    this.textInput.setSelectionRange(len, len);
  }
}
InputComponent.defaultProps = {
  type: 'text',
};

InputComponent.propTypes = {
  name: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  isSmall: PropTypes.bool,
  isUppercase: PropTypes.bool,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

export default InputComponent;
