import React from 'react';
import { isUndefined } from 'lodash';
import createAction from '../../lib/redux/create-action';
import * as dashboardService from '../../services/dashboard-service';
import * as dashboardActions from '../../dashboard/actions/dashboard-actions';
import * as toastActions from '../../toast/actions/toast-actions';
import * as toastConstants from '../../toast/toast-constants';
import { getSharingURL } from '../../lib/sharing';
import { getDashboardTrackingPropsById } from '../helpers';
import { trackEvent } from '@Tracking';
import { LOCATIONS } from '@Tracking/constants';
import {
  dashboardDeleted,
  dashboardRegeneratedSharingLink,
} from '@Tracking/events';
import FormattedMessage from '../../lib/i18n';

export const regenerateDashboardSharingLinkSuccess = createAction(
  'Admin:REGENERATE_DASHBOARD_SHARING_LINK_SUCCESS',
);

export const deleteDashboardSuccess = createAction(
  'Admin:DELETE_DASHBOARD_SUCCESS',
);

export const regenerateDashboardSharingLink =
  (dashboardId) => async (dispatch, getState) => {
    try {
      const {
        dashboard: { dashboard: currentDashboard },
      } = getState();
      const { sharing_hash: sharingHash } =
        await dashboardService.regenerateSharingLink(dashboardId);

      dispatch(
        regenerateDashboardSharingLinkSuccess({
          dashboardId,
          sharingHash,
        }),
      );

      if (
        !isUndefined(currentDashboard) &&
        dashboardId === currentDashboard.id
      ) {
        dispatch(dashboardActions.setCurrentDashboardSharingLink(sharingHash));
      }

      const link = getSharingURL(sharingHash);
      dispatch(
        toastActions.showToast({
          type: toastConstants.REGENERATED_SHARING_LINK,
          link,
        }),
      );

      trackEvent(
        dashboardRegeneratedSharingLink({
          ...getDashboardTrackingPropsById(dashboardId),
          Location: LOCATIONS.AdminDashboards,
        }),
      );
    } catch (error) {
      dispatch(toastActions.showGenericErrorToast());
    }
  };

export const deleteDashboard = (dashboardId) => async (dispatch) => {
  try {
    await dashboardService.deleteDashboard(dashboardId);
    dispatch(deleteDashboardSuccess({ dashboardId }));

    trackEvent(
      dashboardDeleted({
        ...getDashboardTrackingPropsById(dashboardId),
        Location: LOCATIONS.AdminDashboards,
      }),
    );

    const message = (
      <FormattedMessage id="dashboards.dashboardDeletionSuccessful" />
    );
    dispatch(
      toastActions.showToast({
        type: toastConstants.SUCCESS,
        message,
      }),
    );
  } catch (error) {
    dispatch(toastActions.showGenericErrorToast());
  }
};
