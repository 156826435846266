import { getNodeEnv } from './global';
import { attempt } from 'lodash';
import bugsnag from './bugsnag';

const actionErrorHandler = (isDevelopment, error) => {
  const isTest = attempt(getNodeEnv) === 'test';

  // `instanceof` is not supported in Babel when extending built-in classes
  if ('FetchError' === error.name || isTest) {
    return;
  }

  if (isTabloidWidgetError(error)) {
    return;
  }

  if (isDevelopment && console) {
    console.error(error);
  }

  attempt(() => bugsnag.send(error));
};

function isTabloidWidgetError(error) {
  return (
    typeof error === 'object' &&
    !('name' in error && 'message' in error) &&
    'type' in error
  );
}

export default actionErrorHandler;
