import { omit, get } from 'lodash';
import {
  createWithAutoPosition as create,
  update,
  getConfig,
} from '../../lib/widget-service';
import {
  getSpreadsheet,
  getWorksheet,
} from '../../universal/services/spreadsheet-service';
import { getDashboard } from '../../services/management-service';
import { setDashboard } from '../../dashboard/actions/dashboard-actions';
import { serialize, deserialize } from '../../universal/serializers';
import { redirect } from '../../lib/global';
import { trackEvent, conform } from '@Tracking';
import {
  widgetCreated,
  widgetConfigEdited,
  spreadsheetsLoadingFailed,
} from '@Tracking/events';
import * as pathHelpers from '../../lib/path';
import widgetStorage from '../../lib/widget-storage';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import {
  actions,
  selectors,
} from '../reducer/spreadsheet-data-selection-slice';
import * as grapqhlDashboardHelpers from '../../dashboard/graphql-dashboard-helpers';
import { pollForInstrumentIdFromWidgetKey } from '../../dashboard/pollForInstrumentIdFromWidgetKey';

import { handleGoalTracking } from '../../widget/edit-widget/actions/edit-widget-actions';
import {
  handleIndicatorTracking,
  handleNumberFormattingTracking,
} from '../../widget-menu/actions/widget-menu-actions';
import {
  getNotification,
  getSpreadsheetsInstrument,
} from './spreadsheets-instrument-helpers';
import { hydrateConfigureView } from './spreadsheet-actions';

export const doSetValues = createAction('SpreadsheetConfig:SET_VALUES');

export const widgetCreationStart = createAction(
  'SpreadsheetConfig:WIDGET_CREATION_START',
);
export const widgetCreationSuccessful = createAction(
  'SpreadsheetConfig:WIDGET_CREATION_SUCCESSFUL',
);
export const widgetCreationFailed = createErrorAction(
  'SpreadsheetConfig:WIDGET_CREATION_FAILED',
);

export const widgetUpdateStart = createAction(
  'SpreadsheetConfig:WIDGET_UPDATE_START',
);
export const widgetUpdateSuccessful = createAction(
  'SpreadsheetConfig:WIDGET_UPDATE_SUCCESSFUL',
);
export const widgetUpdateFailed = createErrorAction(
  'SpreadsheetConfig:WIDGET_UPDATE_FAILED',
);

export const widgetConfigFetchStart = createAction(
  'SpreadsheetConfig:WIDGET_CONFIG_FETCH_START',
);
export const widgetConfigOnlySuccessful = createAction(
  'SpreadsheetConfig:WIDGET_CONFIG_ONLY_SUCCESSFUL',
);
export const widgetConfigFetchSuccessful = createAction(
  'SpreadsheetConfig:WIDGET_CONFIG_FETCH_SUCCESSFUL',
);
export const widgetConfigFetchFailed = createErrorAction(
  'SpreadsheetConfig:WIDGET_CONFIG_FETCH_FAILED',
);
export const setConfig = createAction('Selections:SET_CONFIG');
export const initSpreadsheetConfigStart = createAction(
  'SpreadsheetConfig:INIT_SPREADSHEET_CONFIG_START',
);
export const initSpreadsheetConfigSuccessful = createAction(
  'SpreadsheetConfig:INIT_SPREADSHEET_CONFIG_SUCCESSFUL',
);
export const initSpreadsheetConfigFailed = createErrorAction(
  'SpreadsheetConfig:INIT_SPREADSHEET_CONFIG_FAILED',
);

export const toggleFilePicker = createAction(
  'SpreadsheetConfig:TOGGLE_FILE_PICKER',
);
export const updateNumberFormat = createAction(
  'SpreadsheetConfig:UPDATE_NUMBER_FORMAT',
);
export const deleteTableColumn = createAction(
  'SpreadsheetConfig:DELETE_TABLE_COLUMN',
);
export const reorderTableColumn = createAction(
  'SpreadsheetConfig:REORDER_TABLE_COLUMN',
);

export const DEFAULT_TYPE = 'line';

// TODO share this between gecko-js and polecat
const CACHE_PREFIX = 'widgetData:';

export const setTitle = createAction('SpreadsheetConfig:SET_TITLE');

export const createWidget =
  (dashboardId, type, service, setNotification) =>
  async (dispatch, getState) => {
    const state = getState();

    const visualizationConfig =
      selectors.widgetConfiguration.visualizationConfig(state);
    const serviceConfig = selectors.serviceConfig(state);
    const forSerialization = { ...visualizationConfig, ...serviceConfig };

    const data = {
      dashboard_id: dashboardId,
      ...serialize('spreadsheets', type, forSerialization), // hardcoded spreadsheets service
    };

    data.config.fieldRanges = visualizationConfig?.fieldRanges;

    dispatch(widgetCreationStart());

    try {
      const widget = await create(data);

      const instrumentId = await pollForInstrumentIdFromWidgetKey(
        widget.data_id,
      );

      if (setNotification) {
        await setNotification(instrumentId);
      }

      trackEvent(
        widgetCreated({
          ...conform.integration({
            name: 'Spreadsheets',
            slug: service,
          }),
          'Legacy widget ID': `${widget.id}`,
          Visualisation: type,
          'Widget type ID': `${widget.widget_type_id}`,
        }),
      );

      dispatch(widgetCreationSuccessful());

      // add a delay for redirecting, to wait for mixpanel tracker to be finished
      redirect(
        pathHelpers.getDashboardPath(
          dashboardId.toString(),
          widget.id,
          service,
        ),
        300,
      );
    } catch (error) {
      dispatch(widgetCreationFailed(error));
    }
  };

export const updateWidget =
  (widgetKey, dashboardId, type, _, setNotification) =>
  async (dispatch, getState) => {
    const state = getState();
    const { spreadsheets } = state;
    const { initialConfig } = spreadsheets;

    const visualizationConfig =
      selectors.widgetConfiguration.visualizationConfig(state);
    const serviceConfig = selectors.serviceConfig(state);
    const forSerialization = { ...visualizationConfig, ...serviceConfig };

    dispatch(widgetUpdateStart());

    let data = serialize('spreadsheets', type, forSerialization);

    // Add specific fieldRange configuration into our config
    if (data.config) {
      data.config.fieldRanges = visualizationConfig.fieldRanges;
    }

    data = omit(data, ['service_account_id', 'dashboard_id']);

    try {
      const widget = await update(widgetKey, data);

      // this to replicate the kind of data the widget is holding if on the
      // dashboard page
      const widgetPreUpdate = {
        id: widgetKey,
        widgetType: {},
        service: {
          title: widget.service.title,
          name: 'spreadsheets',
        },
        serviceTitle: widget.service.title,
        serviceName: 'spreadsheets',
        visualisation: { vizType: type },
        config: initialConfig,
      };

      const integration = {
        name: 'Spreadsheets',
        slug: widget.service.name,
      };

      const indicatorTrack = handleIndicatorTracking(
        widgetPreUpdate,
        getState(),
        get(visualizationConfig, 'indicators', {}),
        integration,
      );

      const goalTrack = handleGoalTracking(
        widgetPreUpdate,
        visualizationConfig,
        integration,
      );
      const formattingTrack = handleNumberFormattingTracking(
        widgetPreUpdate,
        visualizationConfig,
        integration,
      );

      const configTrack = trackEvent(
        widgetConfigEdited({
          ...conform.integration(integration),
          'Legacy widget ID': `${widget.id}`,
          Visualisation: type,
        }),
      );

      await indicatorTrack;
      await goalTrack;
      await formattingTrack;
      await configTrack;

      const graphqlDashboard =
        grapqhlDashboardHelpers.getDashboardFromStorage(dashboardId) || {};
      const isContainerLayout =
        grapqhlDashboardHelpers.isContainerLayout(graphqlDashboard);
      let instrumentId;

      if (isContainerLayout || setNotification) {
        instrumentId =
          await grapqhlDashboardHelpers.getInstrumentIdFromWidgetKey(widgetKey);

        if (setNotification) {
          await setNotification(instrumentId);
        }
      }

      const storageItemKey = isContainerLayout
        ? `instrumentData:${instrumentId}`
        : `${CACHE_PREFIX}${widgetKey}`;

      widgetStorage.removeItem(storageItemKey);

      dispatch(widgetUpdateSuccessful());

      redirect(pathHelpers.getDashboardPath(dashboardId.toString(), widget.id));
    } catch (error) {
      dispatch(widgetUpdateFailed(error));
    }
  };

const trackWorksheetErrors = (worksheet) => {
  /**
   * Strangely, right now, if a worksheet is too large we don't receive
   * an http error. The spreadsheets service sends us a worksheet object
   * where the first three cells contain the error message. example
   *
   * | WARNING | This worksheet was | too large to import |
   *
   * we want to know how many users are seeing this error and the easiest
   * way right now is to track it on the frontend.
   *
   * Future developers - please forgive me.
   */
  const bounds = get(worksheet, 'bounds', {});
  const cells = get(worksheet, 'cells', []);

  if (bounds.columns !== 3 || bounds.rows !== 1) {
    return;
  }

  const content = cells.map((cell) => cell.value).join(' ');

  if (content === 'WARNING This worksheet was too large to import') {
    trackEvent(
      spreadsheetsLoadingFailed({
        'Failure reason': 'Worksheet too large',
      }),
    );
  }
};

export const getWidgetConfig =
  (widgetOrInstrumentId, dashboardId, isOwnedByRoadie) => async (dispatch) => {
    dispatch(widgetConfigFetchStart());

    let config;
    let key;

    // fieldRanges only exists in newer widget configs. Older ones (pre user-series selection)
    // won't contain it. This should be typed later as FieldType | undefined
    let fieldRanges = undefined;

    let instrumentId = widgetOrInstrumentId;
    let notification;

    try {
      if (isOwnedByRoadie) {
        const data = await getSpreadsheetsInstrument(widgetOrInstrumentId);

        config = {
          fileId: data.fileId,
          worksheetId: data.worksheetId,
          legacyServiceAccountId: data.legacyServiceAccountId,
          ...data.config,
        };

        fieldRanges = data.config.fieldRanges;
      } else {
        const json = await getConfig(widgetOrInstrumentId);

        const { template: { type = DEFAULT_TYPE } = {} } = json.config;
        key = json.key;
        config = deserialize('spreadsheets', type, json);
        fieldRanges = json.config?.fieldRanges;

        const dashboard = await getDashboard(dashboardId);
        dispatch(setDashboard(dashboard));

        instrumentId =
          await grapqhlDashboardHelpers.getInstrumentIdFromWidgetKey(
            widgetOrInstrumentId,
          );
      }

      // If the rest of the fetching fails, make sure we've at least stored
      // the widget config into the store, in case the user picks a new file
      // and wants to continue
      dispatch(
        widgetConfigOnlySuccessful({
          key,
          config: omit(config, ['hasHeader']),
        }),
      );

      notification = await getNotification(instrumentId);

      let spreadsheet = null;
      let worksheet = null;

      spreadsheet = await getSpreadsheet(config.fileId, 300);

      const { fileId, worksheetId } = config;

      worksheet = await getWorksheet(fileId, worksheetId);
      trackWorksheetErrors(worksheet);

      dispatch(actions.file.selectWorksheet(worksheet));
      dispatch(actions.setFromConfig({ config, fieldRanges, notification }));
      dispatch(hydrateConfigureView({ fieldRanges }));

      dispatch(
        widgetConfigFetchSuccessful({
          config: omit(config, ['hasHeader']),
          spreadsheet,
          worksheet,
          key,
        }),
      );
    } catch (error) {
      // As we've got a config set from config and hydrate the view
      // so that reselection of a new spreadsheet (if required reloads)
      // with the existing the widget config even if not valid for the new sheet
      dispatch(actions.setFromConfig({ config, fieldRanges, notification }));
      dispatch(hydrateConfigureView({ fieldRanges }));
      dispatch(widgetConfigFetchFailed(error));
    }
  };

export const initConfig =
  (fileId, dashboardId, isOwnedByRoadie) => async (dispatch) => {
    dispatch(initSpreadsheetConfigStart());

    try {
      if (!isOwnedByRoadie) {
        // It appears the dashboard isn't used, but keep it
        // here just in case on non-RODs.
        const dashboard = await getDashboard(dashboardId);
        dispatch(setDashboard(dashboard));
      }

      const spreadsheet = await getSpreadsheet(fileId, 0);

      const { worksheets } = spreadsheet;
      const { id: worksheetId } = worksheets[0];

      const worksheet = await getWorksheet(fileId, worksheetId);
      trackWorksheetErrors(worksheet);

      dispatch(
        initSpreadsheetConfigSuccessful({
          spreadsheet,
          worksheet,
        }),
      );
      dispatch(actions.file.selectWorksheet(worksheet));
    } catch (error) {
      dispatch(initSpreadsheetConfigFailed(error));
    }
  };

export const setValues = (state, activeFieldPath) =>
  doSetValues({ state, activeFieldPath });
