import { isEmpty, negate, once } from 'lodash';
import uaParser from 'ua-parser-js';
import SchemaTypes from '../../schema-types';
import { getWindow } from '../../../lib/global';

const deviceString = ({ type, vendor, model }) =>
  [type, vendor, model].filter(negate(isEmpty)).join(', ');

const platformInfo = once(() => {
  const { browser, device, os } = uaParser(getWindow().navigator.userAgent);

  return {
    $browser: browser.name,
    $browser_version: browser.version,
    $device: deviceString(device),
    $os: os.name,
    $os_version: os.version,
  };
});

const resolve = () => ({
  $current_url: getWindow().location.href,
  $screen_height: getWindow().screen.height,
  $screen_width: getWindow().screen.width,
  $window_height: getWindow().innerHeight,
  $window_width: getWindow().innerWidth,
  ...platformInfo(),
});

const schema = {
  $browser: SchemaTypes.string.isRequired,
  $browser_version: SchemaTypes.string.isRequired,
  $current_url: SchemaTypes.string.isRequired,
  $device: SchemaTypes.string.isRequired,
  $os: SchemaTypes.string,
  $os_version: SchemaTypes.string,
  $screen_height: SchemaTypes.number.isRequired,
  $screen_width: SchemaTypes.number.isRequired,
  $window_height: SchemaTypes.number.isRequired,
  $window_width: SchemaTypes.number.isRequired,
};

export default {
  resolve,
  schema,
};
