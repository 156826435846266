import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FeatureFlagContext from './feature-flag-context';
import { getCreator } from './feature-flag-client-creator';
import { FeatureFlagClient } from './feature-flag-context';
import { LDFlagValue, LDFlagSet } from 'launchdarkly-js-client-sdk';

// We use this to provide default methods to consumers
// of the ffClient context so that they don't have to
// check if it has been created yet.
const initialClient: FeatureFlagClient = {
  variation: (_: string, defaultValue: LDFlagValue) => {
    return defaultValue || (false as LDFlagValue);
  },
  allFlags: (): LDFlagSet => {
    return {};
  },
  on: () => {},
  off: () => {},

  // Allow consumers to detect that we haven't finished intializing LD
  isPendingClient: true,
};

export type FeatureFlagProviderProps = {
  children: React.ReactNode;
};

const FeatureFlagProvider = (props: FeatureFlagProviderProps) => {
  const ffClientCreator = getCreator();
  const [ffClient, setFFClient] = useState(initialClient);

  useEffect(() => {
    ffClientCreator.onFeatureFlagClientReady(setFFClient);
  }, [ffClientCreator]);

  return (
    <FeatureFlagContext.Provider value={{ ffClient, ffClientCreator }}>
      {props.children}
    </FeatureFlagContext.Provider>
  );
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.node,
};

export default FeatureFlagProvider;
