import { gql } from '@apollo/client';
import * as datasetActions from './dataset-actions';
import * as pathHelpers from '../../lib/path';
import {
  createWithAutoPosition as create,
  update,
} from '../../lib/widget-service';
import { redirect } from '../../lib/global';
import { trackEvent } from '@Tracking';
import { widgetCreated, widgetConfigEdited } from '@Tracking/events';
import widgetStorage from '../../lib/widget-storage';
import { TEMPLATES } from '../../universal/serializers/services';
import { removeKeysWithUnderscore } from '../../universal/serializers/helpers';
import * as grapqhlDashboardHelpers from '../../dashboard/graphql-dashboard-helpers';
import INTEGRATIONS from '../../service-list/integrations';
import { router } from '../../router';
import { matchPath } from 'react-router';
import apolloClient from '../../services/concierge-service/apollo-client';
import { find, isEmpty } from 'lodash';

export const INTEGRATION_DATASET_QUERY = gql`
  query integrationDatasetSave($id: ID!) {
    integrationDataset(id: $id) {
      id
      serviceAccountId
      workflow {
        integration
      }
    }
  }
`;

const getJunoWidgetUrlParams = () => {
  const {
    location: { pathname: currentPath },
  } = router.getHistory();

  const match = matchPath(currentPath, {
    path: [
      '/v4/dashboards/:dashboardId/integrationDatasets/:datasetId',
      '/v4/dashboards/:dashboardId/widgets/:widgetId/integrationDatasets/:datasetId',
    ],
  });

  if (match) {
    return match.params;
  }

  return {};
};

const addJunoConfig = async (widgetData, datasetId) => {
  const client = apolloClient.create();

  const response = await client.query({
    query: INTEGRATION_DATASET_QUERY,
    variables: { id: datasetId },
  });

  const {
    workflow: { integration },
    serviceAccountId,
  } = response.data.integrationDataset;
  const { name: integrationName } =
    find(INTEGRATIONS, { slug: integration }) || {};

  return {
    ...widgetData,
    service_account_id: serviceAccountId,
    config: {
      ...widgetData.config,
      juno: {
        datasetId,
        integrationSlug: integration,
        integrationName,
      },
    },
  };
};

const prepareWidgetData = (dashboardId, getState) => {
  const { config, dataSetId, query } = getState().datasets;
  const cleanConfig = removeKeysWithUnderscore(config);

  return {
    dashboard_id: dashboardId,
    config: {
      ...cleanConfig,
      dataSetId,
      query,
      template: TEMPLATES[config.type],
    },
    service_name: 'datasets',
  };
};

export const createWidget = (dashboardId) => async (dispatch, getState) => {
  try {
    dispatch(datasetActions.datasetSet({ isSaving: true }));

    let widgetData = prepareWidgetData(dashboardId, getState);

    const urlParams = getJunoWidgetUrlParams();
    const isIntegrationDataset = !isEmpty(urlParams);

    if (isIntegrationDataset) {
      widgetData = await addJunoConfig(widgetData, urlParams.datasetId);
    }

    const widget = await create(widgetData);

    const integrationName = isIntegrationDataset
      ? widgetData.config.juno.integrationName
      : widget.services[0].name;
    const integrationSlug = isIntegrationDataset
      ? widgetData.config.juno.integrationSlug
      : 'datasets';

    trackEvent(
      widgetCreated({
        'Integration name': integrationName,
        'Integration slug': integrationSlug,
        'Legacy widget ID': `${widget.id}`,
        Visualisation: widget.configuration.type,
        'Widget type ID': `${widget.widget_type_id}`,
      }),
    );

    redirect(
      pathHelpers.getDashboardPath(dashboardId, widget.id, integrationSlug),
      300,
    );
  } catch (error) {
    dispatch(datasetActions.datasetSetError(error, { isSaving: false }));
  }
};

export const updateWidget =
  (widgetId, dashboardId) => async (dispatch, getState) => {
    try {
      dispatch(datasetActions.datasetSet({ isSaving: true }));

      let widgetData = prepareWidgetData(dashboardId, getState);

      const urlParams = getJunoWidgetUrlParams();
      const juno = widgetData.config.juno;

      if (juno || urlParams.datasetId) {
        widgetData = await addJunoConfig(
          widgetData,
          urlParams.datasetId || juno.datasetId,
        );
      }

      const widget = await update(widgetId, widgetData);

      const integrationName = widget.config.juno
        ? widget.config.juno.integrationName
        : widget.service.title;
      const integrationSlug = widget.config.juno
        ? widget.config.juno.integrationSlug
        : widget.service.name;

      await trackEvent(
        widgetConfigEdited({
          'Integration name': integrationName,
          'Integration slug': integrationSlug,
          'Legacy widget ID': `${widget.id}`,
          Visualisation: widget.config.type,
        }),
      );

      const graphqlDashboard =
        grapqhlDashboardHelpers.getDashboardFromStorage(dashboardId) || {};
      const isContainerLayout =
        grapqhlDashboardHelpers.isContainerLayout(graphqlDashboard);

      if (isContainerLayout) {
        const instrumentId =
          await grapqhlDashboardHelpers.getInstrumentIdFromWidgetKey(
            widget.key,
          );
        widgetStorage.removeItem(`instrumentData:${instrumentId}`);
      } else {
        widgetStorage.removeItem(`widgetData:${widget.key}`);
      }

      redirect(pathHelpers.getDashboardPath(dashboardId, widget.id));
    } catch (error) {
      dispatch(datasetActions.datasetSetError(error, { isSaving: false }));
    }
  };
