import { some } from 'lodash';
import { isValidSelection } from '../selection-helpers';
import ColourBrewer from '../../../lib/colour-brewer';

const MIN_COLUMNS = 2;
const MAX_COLUMNS = 10;

const getDefaultConfig = (selections = []) => {
  return {
    type: 'table',
    columns: selections,
    numberFormat: selections.map(() => null),
  };
};

const getSelectionsFromConfig = (config) => config.columns;

const isValid = (cfg) => {
  return some(cfg.columns, isValidSelection);
};

const headerPaths = () => {
  return ['columns'];
};

const headerColours = (cfg) => {
  const { columns = [] } = cfg;
  if (!columns.length) {
    return { columns };
  }

  const brewery = new ColourBrewer();
  const colours = columns.map(() => brewery.getNextColour());
  return { columns: colours };
};

const configMappingRules = {
  line: ['dataRange', 'title'],
  column: ['dataRange', 'title'],
  bar: ['dataRange', 'title'],
  number: ['dataRange', 'title'],
  geckometer: ['dataRange', 'title'],
  text: ['dataRange', 'title'],
  leaderboard: ['dataRange', 'title'],
};

export default {
  getDefaultConfig,
  getSelectionsFromConfig,
  isValid,
  configMappingRules,
  headerPaths,
  headerColours,
  MIN_COLUMNS,
  MAX_COLUMNS,
};
