import createAction from '../../../lib/redux/create-action';
import createErrorAction from '../../../lib/redux/create-error-action';
import * as dashboardActions from '../../../dashboard/actions/dashboard-actions';
import * as dashboardService from '../../../services/dashboard-service';
import { trackEvent } from '@Tracking';
import { dashboardRegeneratedSharingLink } from '@Tracking/events';
import { LOCATIONS } from '@Tracking/constants';

export const regenerateSharingLinkStart = createAction(
  'SharingMenu:REGENERATE_SHARING_LINK_START',
);
export const regenerateSharingLinkSuccess = createAction(
  'SharingMenu:REGENERATE_SHARING_LINK_SUCCESS',
);
export const regenerateSharingLinkFailure = createErrorAction(
  'SharingMenu:REGENERATE_SHARING_LINK_FAILURE',
);

export const regenerateSharingLink = () => async (dispatch, getState) => {
  try {
    dispatch(regenerateSharingLinkStart());
    const {
      dashboard: {
        dashboard: { id },
      },
    } = getState();
    const { sharing_hash: sharingHash } =
      await dashboardService.regenerateSharingLink(id);
    dispatch(dashboardActions.setCurrentDashboardSharingLink(sharingHash));
    dispatch(regenerateSharingLinkSuccess());

    trackEvent(
      dashboardRegeneratedSharingLink({
        Location: LOCATIONS.DashboardShareMenu,
      }),
    );
  } catch (error) {
    dispatch(regenerateSharingLinkFailure(error));
  }
};
