import apolloClient from '../../services/concierge-service/apollo-client';
import { instrumentQuery } from '@Hooks/use-get-instrument-for-editing';
import { widgetConfigFromInstrument } from '../salesforce-graphql-mappers';
import type { SalesforceWidgetConfig } from '../salesforce-types';

export const getSalesforceInstrument = async (
  instrumentId: string,
): Promise<SalesforceWidgetConfig> => {
  const client = apolloClient.create();

  try {
    const { data } = await client.query({
      query: instrumentQuery,
      variables: { id: instrumentId },
    });

    if (!data?.instrument) {
      throw new Error('Instrument not returned in query.');
    }

    return widgetConfigFromInstrument(data.instrument);
  } catch (e) {
    throw new Error(
      `Failed to fetch salesforce instrument when editing instrument ${instrumentId}. ${e}`,
    );
  }
};
