import * as widgetMenuActions from '../actions/widget-menu-actions';
import { WIDGET_MENU_VIEWS } from '../constants';
import { isUndefined } from 'lodash';

const initialState = {
  indicators: undefined,
  numberFormat: undefined,
  view: WIDGET_MENU_VIEWS.CLOSED,
  isEditingQuery: false,
  widgetKey: undefined,
};

const widgetMenuReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case widgetMenuActions.openMenu.type: {
      return {
        ...state,
        widgetKey: payload.widgetKey,
        view: payload.view || WIDGET_MENU_VIEWS.MENU,
      };
    }
    case widgetMenuActions.toggleQueryEditor.type: {
      return {
        ...state,
        isEditingQuery: payload,
      };
    }
    case widgetMenuActions.closeMenu.type: {
      return initialState;
    }
    case widgetMenuActions.doOpenWidgetStatusIndicators.type: {
      return {
        ...state,
        view: WIDGET_MENU_VIEWS.STATUS_INDICATORS,
      };
    }
    case widgetMenuActions.doOpenNumberFormatter.type: {
      return {
        ...state,
        numberFormat: payload,
        view: WIDGET_MENU_VIEWS.NUMBER_FORMAT,
      };
    }
    case widgetMenuActions.openDeleteModal.type: {
      return {
        ...state,
        view: WIDGET_MENU_VIEWS.DELETE,
      };
    }
    case widgetMenuActions.updateNumberFormat.type: {
      const { index, format = {} } = action.payload;
      let { numberFormat } = state;

      // When there is an index, we know the numberFormat is an array
      // otherwise the numberFormat is a simple object
      if (!isUndefined(index)) {
        numberFormat = numberFormat ? [...numberFormat] : [];

        numberFormat[index] = {
          ...numberFormat[index],
          ...format,
        };
      } else {
        numberFormat = numberFormat ? { ...numberFormat } : {};

        numberFormat = {
          ...numberFormat,
          ...format,
        };
      }

      return {
        ...state,
        numberFormat,
      };
    }
    case widgetMenuActions.updateStatusIndicatorValue.type: {
      return {
        ...state,
        indicators: payload,
      };
    }
    case widgetMenuActions.saveNewNumberFormat.type: {
      return initialState;
    }

    case widgetMenuActions.openCopyModal.type: {
      return {
        ...state,
        view: WIDGET_MENU_VIEWS.COPY,
      };
    }
    case widgetMenuActions.openEditWidget.type: {
      return {
        ...state,
        view: WIDGET_MENU_VIEWS.EDIT,
      };
    }

    default: {
      return state;
    }
  }
};

export { widgetMenuReducer as default, initialState };
