import { chain, has } from 'lodash';
import { getValuesFromRow } from './helpers';

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = ({ data } = {}) => {
  if (!has(data, 'databaseQuery.rows[0]')) {
    return {};
  }

  const items = chain(data.databaseQuery.rows)
    // transform data into [[label, value], ...]
    .map(({ values }) => getValuesFromRow(values))
    // removes entries with null label
    .filter(([label]) => label !== null)
    // transform [label, value] into { label, value }
    .map(([label, value]) => ({ label, value }))
    // sort by value
    .sortBy('value')
    // reverse, so highest value is at the top
    .reverse()
    .value();

  return {
    items,
    format: 'decimal',
  };
};

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data) => data;

export { transform, applyConfig };
