import { map, last } from 'lodash';
import {
  getMixedInputValue,
  getNumericCells,
  getSelectionFormat,
} from '../../../universal/transformers/spreadsheet/helpers';
import { getSelectedCells } from '../../../universal/spreadsheet-helpers';
import { getGeckometerDefaultMinMax } from '../../../lib/geckometer';

import type { Cell, LegacyRange } from '../../types';

export const getGeckometerMinMax = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any,
  cells: Cell[],
  // TODO: Remove the optional attribute on range once we userSelectableSeries feature flag is enabled
  range?: LegacyRange,
) => {
  const { value, min, max } = config;

  const cell = range ? range : value;
  const [selectedCells] = getSelectedCells(cells, [cell]);
  const numericCells = getNumericCells(selectedCells);
  const [format] = getSelectionFormat(numericCells);
  const values = map(numericCells, 'value');

  const { _minDefault, _maxDefault } = getGeckometerDefaultMinMax(
    last(values),
    format,
  );
  const minValue =
    min && getMixedInputValue(cells, min.value, format)
      ? min.value
      : _minDefault;
  const maxValue =
    max && getMixedInputValue(cells, max.value, format)
      ? max.value
      : _maxDefault;

  return {
    min: { value: minValue },
    max: { value: maxValue },
  };
};
