/**
 * Cache Key Resolver
 *
 * This lib exposes a function that takes a GraphQL object and returns
 * a cache key. A cache key is the string that is used to reference
 * an item in the Apollo cache. By default it takes the form of
 *
 * typename:id
 *
 * But this behaviour can be customised for a specific type.
 * To do so, add a resolver function to the resolvers object
 * for whatever type you'd like to customise.
 */

const instrumentResolver = (object) => `Instrument:${object.id}`;
const dashboardResolver = (object) => `Dashboard:${object.id}`;

const resolvers = {
  Clock: instrumentResolver,
  Label: instrumentResolver,
  Image: instrumentResolver,
  Line: instrumentResolver,
  LegacyLine: instrumentResolver,
  Geckometer: instrumentResolver,
  Number: instrumentResolver,
  Column: instrumentResolver,
  Bar: instrumentResolver,
  Leaderboard: instrumentResolver,
  Table: instrumentResolver,
  Feed: instrumentResolver,
  Text: instrumentResolver,
  Map: instrumentResolver,
  Rag: instrumentResolver,
  Bullet: instrumentResolver,
  PieChart: instrumentResolver,
  Funnel: instrumentResolver,
  Highcharts: instrumentResolver,
  List: instrumentResolver,
  Monitoring: instrumentResolver,
  ContainerDashboard: dashboardResolver,
  LegacyDashboard: dashboardResolver,
};

const cacheKeyResolver = (object) => {
  if (resolvers[object.__typename]) {
    return resolvers[object.__typename](object);
  }

  if (!object.id) {
    return null;
  }

  return `${object.__typename}:${object.id}`;
};

export default cacheKeyResolver;
