import { SPREADSHEET_SERVICE_NAMES } from '../../data-sources/data-sources-helpers';
export const PLATFORM_NAMES = ['pegasus', 'inquisitor', 'heirloom'];

/**
 * Integration conformer
 *
 * Converts an integration name, slug and datasource into valid tracking properties.
 *
 * It handles edge cases, like wierdly formatted spreadsheets integrations, and logs
 * a warning for common misuse cases, e.g. the developer is passing an internal
 * platform name insted of an integration slug.
 *
 * @param {Object} integration - The integration object.
 * @param {string} integration.name - The human readable name.
 * @param {string} integration.slug - The machine friendly slug.
 * @param {string} [integration.datasource] - An additional clarification on the source of the data. (e.g. database engine)
 *
 * @returns {Object} - A sanitized version of the integration that the event schema expects.
 */
function integrationConform(integration) {
  if (!integration) {
    console.warn(`
      No integration was passed to the integration conformer.
      Make sure you're sending a valid integration with the tracking event.
    `);

    return {};
  }

  const { slug, name, datasource } = integration;

  if (PLATFORM_NAMES.includes(slug)) {
    console.warn(`
      A platform name was passed as an integration slug.
      Make sure you're sending a valid integration with the tracking event.
    `);

    return {};
  }

  if (SPREADSHEET_SERVICE_NAMES.includes(slug)) {
    return {
      'Integration name': 'Spreadsheets',
      'Integration slug': 'spreadsheets',
      'Data source': slug,
    };
  }

  const details = {
    'Integration name': name,
    'Integration slug': slug,
  };

  if (datasource) {
    details['Data source'] = datasource;
  }

  return details;
}

export default integrationConform;
