import {
  chain,
  trim,
  get,
  isNil,
  times,
  filter,
  forEach,
  groupBy,
  reduce,
} from 'lodash';
import { roundToPowerOfTen } from '../../lib/round-to-power-of-ten';
import { ISO_COUNTRY_CODES } from '../constants/iso-country-codes';

const breakpoints = [
  1, // 1 second
  10,
  30,
  60, // a minute
  60 * 5,
  60 * 10,
  60 * 15,
  60 * 30,
  60 * 60, // 1 hour
  60 * 60 * 6,
  60 * 60 * 12,
  60 * 60 * 24, // 24 hours or 1 day
  60 * 60 * 24 * 2,
  60 * 60 * 24 * 3,
  60 * 60 * 24 * 5,
  60 * 60 * 24 * 10,
  60 * 60 * 24 * 15,
  60 * 60 * 24 * 20,
  60 * 60 * 24 * 30,
];

export const getMaxValue = (value, { format }) => {
  // Our normal behaviour for calculating the max value is
  // to find the next highest power of ten from the main value.
  // This results in a sensible estimate normally - e.g. the
  // value is 250 so we set the max at 1000.
  switch (format) {
    case 'duration':
      // Durations deal with number of milliseconds so power of ten
      // gives unusual results (1 hour is 3600000 milliseconds)
      // for this reason we instead define a number of sensible
      // breakpoints and use the next highest breakpoint.

      // We stop defining breakpoints higher than 30 days since we
      // believe it will be rare. In this case we are setting the
      // max to double the value but we don't think that is the best
      // solution. In the future we could set breakpoints up to a year
      // and then go up in years by power of ten (10/100/1000 years).
      return getDurationMaxValue(value, TIME_UNITS.MILLISECONDS);

    case 'percent':
      // For percentage values the max value is almost always 100%
      // But if the main value is above 100% we should increase by
      // power of ten.
      return value <= 1 ? 1 : roundToPowerOfTen(value);

    default:
      return roundToPowerOfTen(value);
  }
};

export const TIME_UNITS = {
  MILLISECONDS: 'milliseconds',
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
  YEARS: 'years',
};

export const UNIT_MULTIPLIER = {
  [TIME_UNITS.MINUTES]: 60,
  [TIME_UNITS.SECONDS]: 1,
  [TIME_UNITS.MILLISECONDS]: 1 / 1000,
};

export const getDurationMaxValue = (value, unit = TIME_UNITS.SECONDS) =>
  breakpoints.find((bp) => bp > value * UNIT_MULTIPLIER[unit]) ||
  roundToPowerOfTen(value);

export const UNIT_DURATIONS = {
  [TIME_UNITS.SECONDS]: 1000,
  [TIME_UNITS.MINUTES]: 60 * 1000,
  [TIME_UNITS.HOURS]: 60 * 60 * 1000,
  [TIME_UNITS.DAYS]: 24 * 60 * 60 * 1000,
  [TIME_UNITS.YEARS]: 52 * 7 * 24 * 60 * 60 * 1000,
};

export const UNKNOWN_COUNTRY = 'No value';

export const DATA_TYPES = {
  BauhausDataColumnPercentage: 'percent',
  BauhausDataColumnDecimal: 'decimal',
  BauhausDataColumnBoolean: 'boolean',
  BauhausDataColumnDuration: 'duration',
  BauhausDataColumnDatetime: 'datetime',
  BauhausDataColumnCountry: 'country',
  BauhausDataColumnCurrency: 'currency',
  BauhausDataColumnString: 'string',
  BauhausDataColumnTimeWindow: 'time_window',
  BauhausDataColumnRecord: 'record',
};

export const getCountryName = (countryCode) => {
  const country = ISO_COUNTRY_CODES[countryCode];

  return country || UNKNOWN_COUNTRY;
};

export const getDatapointValue = (datapoint, column) => {
  if (!datapoint) {
    return null;
  }

  const { __typename } = column || {};

  switch (datapoint.__typename) {
    case 'BauhausStringValue':
      if (__typename === 'BauhausDataColumnCountry') {
        return getCountryName(datapoint.string);
      }

      return datapoint.string;
    case 'BauhausDecimalValue':
      return datapoint.decimal;
    case 'BauhausDatetimeValue':
      return datapoint.datetime;
    case 'BauhausBooleanValue':
      return datapoint.boolean;
    case 'BauhausTimeWindowValue':
      return {
        from: datapoint.from,
        to: datapoint.to,
      };
    case 'BauhausRecordValue':
      return {
        label: datapoint.label,
        image: datapoint.image,
      };
    default:
      return null;
  }
};

/**
 * @returns {unknown[]}
 */
export const getValuesFromRow = (row, columns = []) => {
  return row.map((r, idx) => getDatapointValue(r, columns[idx]));
};

export const getFormatForColumnType = (column) => {
  const { __typename } = column;

  return DATA_TYPES[__typename];
};

export const getDurationUnit = (rows) => {
  /* This will go through every rows and:
   * - gather all decimal values (or set to 0 when undefind)
   * - flatten the resulting array
   * - get the biggest value
   */
  const maxValue = chain(rows)
    .map((row) => {
      return chain(row.values)
        .map((value) => value.decimal || 0)
        .map(Math.abs)
        .value();
    })
    .flatten()
    .max()
    .value();

  const units = Object.entries(UNIT_DURATIONS);

  /*
   * this will return an array of all possible units to use for displaying
   */
  const maxUnits = units.filter(([, value]) => value - 1 < maxValue);

  /*
   * and we get the before-the-last unit value:
   * If hours is the biggest unit, we'll display in minutes.
   * For minutes? We'll display in seconds.
   */
  const [unitKey] = maxUnits[maxUnits.length - 2] || units[0];

  return unitKey;
};

export const formatDurationValue = (value, unit) =>
  value === null ? null : value / UNIT_DURATIONS[unit];

/**
 * Return the right column format.
 *
 * @param {Array.<Object.{ __typename: string, currencyCode: string }>} columns - A list of columns.
 * @param {Array.<Object | null>} rows - A list of rows
 */

export const getColumnFormat = (columns, rows) => {
  /*
   * In order to get the format, we first need to get a valid data column
   * We know that the first column is always the axis.
   * Then come "group by" and "split by" columns
   * And then, we get the data columns, the one we really need to check
   *
   * What we do here is:
   * - reverse the columns, so we get the right one from the start
   * - save the format of the first column
   * - filter to get only the column that share the same types
   * - iterate through all columns and pick a valid columns if:
   *   - the column is of ANY type but currency.
   *   - the format is currency AND we have a currency code
   */
  const reversedColumns = columns.slice().reverse();
  const referenceFormat = getFormatForColumnType(reversedColumns[0]);
  const dataColumns = reversedColumns.filter(
    (column) => getFormatForColumnType(column) === referenceFormat,
  );
  const validColumn = dataColumns.find((column) => {
    const format = getFormatForColumnType(column);

    switch (format) {
      case 'currency':
        if (isNil(column.currencyCode) || column.currencyCode === '') {
          return false;
        }

        return true;
      default:
        return true;
    }
  });

  const column = validColumn || dataColumns[0];
  const format = getFormatForColumnType(column);

  switch (format) {
    case 'duration':
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const unit = getDurationUnit(rows);
      return { format, unit };
    case 'currency':
      if (isNil(column.currencyCode) || column.currencyCode === '') {
        return { format: DATA_TYPES.BauhausDataColumnDecimal };
      }

      return { format, unit: column.currencyCode };
    case 'record':
      return {
        format: 'string',
      };
    default:
      return { format };
  }
};

export const getXAxisFormat = (column) => {
  const { __typename } = column;

  switch (__typename) {
    case 'BauhausDataColumnDatetime':
      return 'datetime';
    default:
      return 'standard';
  }
};

export const getDisplayValue = (value) => {
  if (value && typeof value === 'object') {
    return getDisplayValue(value.label);
  }

  if (trim(value) === '') {
    return 'No value';
  }

  return value;
};

/**
 * processSeries - accepts an array of row values and returns a
 * list of series, formatted for a line/column chart.
 *
 * A row consists of a single x-axis value (e.g. a datetime string)
 * and a list of y-axis values. There could be a single y-axis value,
 * resulting in a single series. Or there could be multiple y-axis
 * values, resulting in a series for each one.
 *
 * [
 *   [today, 0, 100],
 *   [yesterday, 1, 101],
 * ]
 *
 * becomes:
 *
 * [
 *   { data: [[today, 0], [yesterday, 1]] },
 *   { data: [[today, 100], [yesterday, 101]] },
 * ]
 */
export const processSeries = (data) => {
  const firstRow = get(data, '0', []);
  const noOfValues = firstRow.length - 1;
  const processedSeries = times(noOfValues, () => []);

  // Strip out any data points with no x-axis value
  const filteredData = filter(data, (i) => !isNil(i[0]));

  // This is where we transform the data into series as described above
  forEach(filteredData, (item) => {
    const [head, ...rest] = item;
    forEach(rest, (dataPoint, index) => {
      processedSeries[index].push([getDisplayValue(head), dataPoint]);
    });
  });

  return processedSeries
    .map((series) => ({ data: series }))
    .filter((result) => result.data.length > 0);
};

/**
 * processSplitSeries - For visualisation data that contains a
 * split by dimension, accepts an array of row values and
 * returns a list of series, formatted for a line/column chart.
 *
 * Each row consists of
 * 1. A single x-axis value (e.g. a datetime string)
 * 2. A grouping string
 * 3. A single y-axis value
 *
 * The amount of series returned will be equal to the amount
 * of unique grouping strings.
 *
 * [
 *   [today, 'apples', 1],
 *   [today, 'pears', 2],
 *   [yesterday, 'apples', 3],
 *   [yesterday, 'pears', 4],
 * ]
 *
 * becomes:
 *
 * [
 *   { data: [[today, 1], [yesterday, 3]], name: 'apples' },
 *   { data: [[today, 2], [yesterday, 4]], name: 'pears' },
 * ]
 */
export const processSplitSeries = (data, limit) => {
  // Strip out any data points with no x-axis value
  const filteredData = filter(data, (i) => !isNil(i[0]));

  // We want to group the series by name, but if the name is empty or null, we just call it "No value"
  const grouped = groupBy(filteredData, (row) => getDisplayValue(row[1]));

  // This is where we transform the data into series as described above
  let series = reduce(
    grouped,
    (result, value, key) => {
      // For each datapoint remove the group item
      // and leave just the x and y axis values
      const s = value.map((point) => [point[0], point[2]]);
      return [...result, { data: s, name: key, split: true }];
    },
    [],
  );

  // Limit the number of series if a limit has been specified
  if (limit) {
    series = series.slice(0, limit);
  }

  return series;
};

export const getDataResponse = (payload) => {
  const data =
    payload &&
    payload.data &&
    (payload.data.bauhausDataRequest || payload.data.bauhausRawDataRequest);

  return get(data, 'data');
};

export const getImageType = (column, config) => {
  if (column.__typename !== 'BauhausDataColumnRecord') {
    return undefined;
  }

  if (!config?.showImages) {
    return 'off';
  }

  return column.imageType.toLowerCase();
};

export const getImageValue = (value) => {
  if (value && typeof value === 'object') {
    return value.image;
  }

  return undefined;
};
