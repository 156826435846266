import { transform as genericTableTranform } from '../per-visualisation/table';
import { composeTransformers } from '../compose-transformers';
import {
  find,
  findIndex,
  forEach,
  isArray,
  isNull,
  orderBy,
  reduce,
  some,
} from 'lodash';
import { getFormat } from './helpers';

const tableTransform = (
  payload,
  config = {},
  timezone,
  initialContext = {},
) => {
  if (!payload || !payload.length) {
    return {};
  }
  const {
    queryMetas: [{ select = [] } = {}] = [],
    queries: [{ order_by: [{ order = 'asc' } = {}] = [] } = {}] = [],
    detailsMetricResource,
  } = config;

  const isDetailsTable = !!detailsMetricResource;
  let data = payload;

  if (!isDetailsTable) {
    // For table visualisation we need to ensure sorting FE side as multiple
    // queries might return different/mismatching sorted results
    const groupByType = select[0].type;
    if (groupByType === 'enum') {
      data = orderBy(
        data,
        ([val]) => {
          // push no value to the end
          if (isNull(val)) {
            return order === 'asc' ? select[0].values.legnth : -1;
          }
          return findIndex(select[0].values, (v) => v.key === val);
        },
        order,
      );

      data.forEach((row) => {
        if (row[0] === null) row[0] = 'No value';
      });
    } else {
      data = orderBy(data, ([val]) => val, order);
    }
  }

  if (some(select, (s) => s.type === 'enum')) {
    const values = reduce(
      select,
      (res, field, index) => {
        if (field.type === 'enum') {
          res[index] = field.values;
        }
        return res;
      },
      {},
    );

    data = data.map((row) => {
      const r = [...row];
      forEach(values, (val, index) => {
        const currentValue = row[index];
        const newValue = find(val, (v) => v.key === currentValue);
        r[index] = newValue ? newValue.value : '';
      });
      return r;
    });
  }

  // Handle array values as string
  data = data.map((row) => row.map((c) => (isArray(c) ? c.join(', ') : c)));

  const visualisationConfig = {
    data,
    columns: select.map((m) => {
      const col = { heading: m.name };
      if (!['string', 'enum'].includes(m.type)) {
        return { ...col, ...getFormat(m, config, isDetailsTable) };
      }

      return col;
    }),
    wrap: config.wrap ?? undefined,
  };

  return { ...initialContext, ...visualisationConfig };
};

const transform = composeTransformers(tableTransform, genericTableTranform);

export { transform };
