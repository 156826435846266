/* eslint camelcase:0 */
import { zip, partial } from 'lodash';
import {
  getSeries,
  getXLabels,
  getYAxisFormat,
  destructureSalesforceConfigWrap,
} from './helpers';
import { transform as genericLineTransform } from '../per-visualisation/line';
import { composeTransformers } from '../compose-transformers';

const scatterPoints = (labels, { name, data: series }) => {
  const data = zip(labels, series).slice(0, labels.length);
  return { name, data };
};

const lineTransform = (pegasusPayload, timezone, initialContext = {}) => {
  const {
    axis_metadata: axisMetadata,
    x_labels: xLabels,
    series,
  } = pegasusPayload;

  if (!xLabels || !xLabels.length) {
    return {};
  }

  const context = {};
  const xAxisLabels = getXLabels(axisMetadata.x_type, xLabels);
  const seriesValues = getSeries(series);
  const [format, unit] = getYAxisFormat(
    axisMetadata.y_type,
    axisMetadata.y_unit,
  );

  context.format = format;
  context.y_axis = { format, unit };

  switch (xAxisLabels.type) {
    case 'number':
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const labels = xAxisLabels.labels.map(parseFloat);
      context.series = seriesValues.map(partial(scatterPoints, labels));
      context.x_axis = {};
      break;

    case 'datetime':
      context.series = seriesValues.map(
        partial(scatterPoints, xAxisLabels.labels),
      );
      context.x_axis = { type: 'datetime' };
      break;

    default:
      context.series = seriesValues;
      context.x_axis = xAxisLabels;
  }

  return { ...initialContext, ...context };
};

const transform = destructureSalesforceConfigWrap(
  composeTransformers(lineTransform, genericLineTransform),
);

export { transform };
