import localForage from 'localforage';
import { some } from 'lodash';
import { getGlobalFilters } from './reducer/ui-query-reducer/other-ui-query-reducer-methods';

const OLD_CACHE_KEY = 'universal-lastUsedAccount:';
const CACHE_KEY = 'universal-config-preferences:';

// TODO remove from the 23rd of May
// https://app.clubhouse.io/geckoboard/story/21150/remove-universal-config-old-service-account-preference-retrocompatibility
const getOldLastUsedAccountId = (integration) => {
  return localForage.getItem(`${OLD_CACHE_KEY}${integration}`);
};

// TODO remove from the 23rd of May
// https://app.clubhouse.io/geckoboard/story/21150/remove-universal-config-old-service-account-preference-retrocompatibility
const removeOldAccountId = (integration) => {
  return localForage.removeItem(`${OLD_CACHE_KEY}${integration}`);
};

const _getPreferences = (integration) => {
  return localForage.getItem(`${CACHE_KEY}${integration}`);
};

export const setPreferences = (universalState) => {
  const {
    source: { integration, service_account_id: serviceAccountId },
    queries: [{ filter = [] } = {}] = [],
  } = universalState;
  const preferences = { service_account_id: serviceAccountId };

  if (filter.length) {
    preferences.filter = getGlobalFilters(filter);
  }
  // TODO remove from the 23rd of May
  try {
    removeOldAccountId(integration);
  } catch (e) {
    // silently fails
  }
  return localForage.setItem(`${CACHE_KEY}${integration}`, preferences);
};

export const getPreferences = async (universalState) => {
  const {
    source: { integration, service_account_id: id },
  } = universalState;
  const pref = await _getPreferences(integration);

  if (pref && pref.service_account_id === id) {
    return pref;
  }
  return null;
};

const getLastUsedAccountId = (integration) => {
  return Promise.all([
    _getPreferences(integration),
    getOldLastUsedAccountId(integration),
  ]).then(([preferences, oldId]) => {
    let id;
    if (preferences) {
      ({ service_account_id: id } = preferences);
    } else if (oldId) {
      id = oldId;
    }
    return id;
  });
};

export const getServiceAccountId = async (integration, serviceAccounts) => {
  const id = await getLastUsedAccountId(integration);
  // service acount id is stored as number on serviceAccounts payload
  const cachedAccountExists =
    id && some(serviceAccounts, { id: parseInt(id, 10) });
  // fallback on first available service account if not found / removed
  return cachedAccountExists ? id : serviceAccounts[0].id;
};
