import _, {
  partial,
  add,
  chain,
  head,
  mapValues,
  every,
  isUndefined,
} from 'lodash';

const INDEX_BASED = 1;
const toBackendIndex = partial(add, INDEX_BASED);
const toFrontendIndex = partial(add, -INDEX_BASED);

// Return 0 based index of the excel column string
// From http://stackoverflow.com/a/1237333/2259520

function getSelectedCells(cells, selection) {
  return selection.map((indices = [[], []]) => {
    let [[startCol = 0, startRow = 0], [endCol = Infinity, endRow = Infinity]] =
      indices;

    // Ignore selections if they're for the entire sheet
    if (!isValidSelection(indices)) { // eslint-disable-line
      return [];
    }

    if (startCol === -1) {
      startCol = 0;
    }
    if (startRow === -1) {
      startRow = 0;
    }
    if (endCol === -1) {
      endCol = Infinity;
    }
    if (endRow === -1) {
      endRow = Infinity;
    }

    // Normalize for backend match
    [startCol, endCol, startRow, endRow] = [
      startCol,
      endCol,
      startRow,
      endRow,
    ].map(toBackendIndex);
    return cells.filter((cell) => {
      const { column, row } = cell;
      return (
        column >= startCol &&
        column <= endCol &&
        row >= startRow &&
        row <= endRow
      );
    });
  });
}

// Validate that a single selection has been set by the user
// e.g. selection is not [[-1, -1], [-1, -1]]
function isValidSelection(selection) {
  return !_(selection)
    .flatten()
    .every((i) => i === -1);
}

// This is necessary as Google Sheets exports computed currency values
// as strings. For example, suppose A1:A10 are values formatted as currency
// and A11 is SUM(A1:A10) formatted as a currency. Google sheets will export
// it as a TEXT value "$1,234.00"
//
// See this thread for some back story: https://geckoboard.slack.com/archives/devs/p1445966225002683
function greedilyParseFloat(value) {
  return parseFloat(value.replace(/[^0-9-.E]/gi, ''));
}

function swapSelection(selection) {
  if (!selection) return null;
  const [[startCol, startRow], [endCol, endRow]] = selection;
  return [
    [startRow, startCol],
    [endRow, endCol],
  ];
}

function isEntireColumnSelection(selection) {
  const [[, startRow], [endCol]] = selection;
  // Full column and valid selection
  return startRow === -1 && endCol !== -1;
}

function isEntireRowSelection(selection) {
  return isEntireColumnSelection(swapSelection(selection));
}

function getFullRowOrColumnHeaderIndex(selections, allSelectedCells) {
  const filteredSelections = selections.filter(isValidSelection);

  if (!filteredSelections.length) {
    return null;
  }
  if (filteredSelections.every(isEntireColumnSelection)) {
    let row = chain(allSelectedCells).flatten().map('row').min().value();
    row = toFrontendIndex(row);
    return { row };
  }
  if (filteredSelections.every(isEntireRowSelection)) {
    let column = chain(allSelectedCells).flatten().map('column').min().value();
    column = toFrontendIndex(column);
    return { column };
  }
  return null;
}

function getSelectionHeaderIndices(selections, allSelectedCells) {
  const headerDirection = getFullRowOrColumnHeaderIndex(
    selections,
    allSelectedCells,
  );

  return selections.map((selection) => {
    if (!selection) {
      return null;
    }

    if (!isValidSelection(selection)) {
      return null;
    }

    let [column, row] = head(selection);

    if (headerDirection) {
      const { column: headerCol, row: headerRow } = headerDirection;
      // Default to indice from selection for the missing index
      // e.g headerDirection = { row: 1 } then we'll return { row:1, column: X }
      // X coming from the selection itself
      [column = column, row = row] = [headerCol, headerRow];
    } else {
      // Return first cell's coordinates for each selection
      if (column === -1) column++; // whole row selection pick first cell
      if (row === -1) row++; // whole column selection pick first cell
    }

    return mapValues({ row, column }, toBackendIndex);
  });
}

function stableItemSort(items, reverse) {
  if (every(items, (item) => isUndefined(item.value))) {
    return items;
  }

  const sortedItems = items
    .concat()
    .sort(({ value: a }, { value: b }) => (b || 0) - (a || 0));

  if (reverse) {
    return sortedItems.reverse();
  }

  return sortedItems;
}

export {
  getSelectedCells,
  getSelectionHeaderIndices,
  greedilyParseFloat,
  toBackendIndex,
  toFrontendIndex,
  INDEX_BASED,
  isValidSelection,
  stableItemSort,
};
