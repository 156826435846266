import type { Range, VectorRange } from '../../types';
import type { CellMap } from './createSpreadsheetMaps';

/**
 * Takes a set of 2D Ranges and converts them all to 1D Ranges
 */
export function getVectorRanges(
  ranges: Range[] = [],
  map: CellMap,
  transposeDirection: 'rows' | 'columns',
): VectorRange[] {
  return ranges.flatMap<VectorRange>((range) => {
    const { end, start, size } = range;
    const [colStart, rowStart] = start;
    const [colEnd, rowEnd] = end;
    const [colCount, rowCount] = size;

    // Account for the fact that the user can select a range from right to left,
    // but we always want to treat them as selections from top to bottom, left to right
    const topMostRow = Math.min(rowEnd, rowStart);
    const leftMostCol = Math.min(colEnd, colStart);

    const bottomMostRow = Math.max(rowEnd, rowStart);
    const rightMostCol = Math.max(colEnd, colStart);

    if (transposeDirection === 'columns') {
      const columnIndicies = map.resolveColumnsInRange(range);

      // Create a new range, for each column included
      return columnIndicies.map((columnIndex) => {
        return {
          start: [columnIndex, topMostRow],
          end: [columnIndex, bottomMostRow],
          firstCell: [columnIndex, topMostRow],
          // A column vector only contains data for a single
          size: [1, rowCount],
          columnIndex,
        } satisfies VectorRange;
      });
    }

    if (transposeDirection === 'rows') {
      const rowIndicies = map.resolveRowsInRange(range);

      // Create a new range, for each row included
      return rowIndicies.map((rowIndex) => {
        return {
          start: [leftMostCol, rowIndex],
          end: [rightMostCol, rowIndex],
          firstCell: [leftMostCol, rowIndex],
          size: [colCount, 1],
          rowIndex,
        } satisfies VectorRange;
      });
    }

    // We should never be in a situation where we don't have a transpose direction, but this satisfies TypeScript
    return [];
  });
}
