import { getColumnType } from '../../helpers/visualisations-validator';
import type { DataType, Field, VectorRange } from '../../types';
import { getRangeNotation } from './getRangeNotation';
import type { CellMap } from './createSpreadsheetMaps';

/**
 * Given a VectorRange produces a new VectorRange that targets the cell representing
 * the range's header.
 *
 * @param r
 * @returns
 */
const extractRangeHeader = (r: VectorRange): VectorRange => {
  // This function assumes the vector range is "normalised", and the `start` is at the top/left
  // of the range, and the `end` is at the bottom right. This should happen automatically
  // if your range was constructed by `getVectorRanges()`.
  let [colStart, rowStart] = r.start;

  colStart = Math.max(colStart, 0);
  rowStart = Math.max(rowStart, 0);

  const start: VectorRange['start'] = [colStart, rowStart];

  return {
    // We do not spread the original range here because attributes in it may contradict
    // the other attributes specified here
    start,
    end: start,
    size: [1, 1],
    firstCell: start,
    columnIndex: colStart,
  };
};

/**
 * Obtain the header names for the particular ranges
 */
function getHeaderName(
  map: CellMap,
  range: VectorRange,
  containsHeader: boolean,
): string | undefined {
  if (containsHeader) {
    // Explicitly check the header cell.
    // The cell map only returns populated cells, so the first cell it returns for the entire range
    // might not be the actual header cell
    const headerCell = map.getPopulatedCellsByRange(extractRangeHeader(range));

    return headerCell?.[0]?.value;
  }

  return undefined;
}

/**
 * Given a set of 1D ranges, obtain a set of Fields to describe them
 */
export function getFieldsFromRanges(
  ranges: VectorRange[],
  map: CellMap,
  containsHeader: boolean,
): Field[] {
  // Convert each of our ranges into a Field
  return ranges.map((range, index) => {
    const cells = map.getPopulatedCellsByRange(range);

    const name = getHeaderName(map, range, containsHeader);
    const notation = getRangeNotation(range);

    return {
      name: name ? name : notation,
      dataType: getColumnType(cells) as DataType,
      range,
      notation,
      key: index,
    };
  });
}
