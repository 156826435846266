import { find } from 'lodash';
import { mapSubQueryResult } from '../universal/query-helpers';
import universalService, {
  DEFAULT_DELIVERY,
} from '../universal/services/universal-service';
import {
  getFilterQueryFromUiQuery,
  resolveNodesCrossResource,
} from './reducer/ui-query-reducer/other-ui-query-reducer-methods';

export const ENABLED_CACHE = {
  enabled: true,
  max_age: 600, // in seconds
};

export const findCustomFilter = (filters) =>
  find(filters, (filter) => !!filter.node && filter.node.type === 'custom');

// Try to inject required filters when resource supports it
export const runQueryInContext = (query, universalState, initialLoad) => {
  let coreQuery = query;
  const {
    source,
    mainGraph,
    uiQuery: { prerequisites, globalFilters } = {},
  } = universalState;
  let newUiQuery;

  if (mainGraph.length) {
    const { resource: mainEntry } = query.select[0];
    newUiQuery = resolveNodesCrossResource(
      { prerequisites, globalFilters },
      mainGraph,
      mainEntry,
      false,
    );
    const {
      prerequisites: newPrerequisites = [],
      globalFilters: newGlobalFilters = [],
    } = newUiQuery;
    const filters = [...newPrerequisites, ...newGlobalFilters];
    const filterQ = getFilterQueryFromUiQuery(mainGraph, mainEntry, filters);

    if (filterQ.length) {
      const currentFilters = coreQuery.filter || [];
      if (!initialLoad) {
        coreQuery = { ...coreQuery, filter: [...filterQ, ...currentFilters] };
      }
    }
  }

  return universalService.runQuery(
    { source, ...coreQuery },
    DEFAULT_DELIVERY,
    ENABLED_CACHE,
  );
};

// The `initialLoad` parameter defines whether or not this is loading prerequisites
// If it is, it doesn't try to merge the prerequisites into the query
export const getSubQueryResult = async (node, universalState, initialLoad) => {
  const query = node.getQueryForValue();

  if (!query) return null;

  const subQueryPayload = await runQueryInContext(
    query,
    universalState,
    initialLoad,
  );

  // TODO merge existing results with new results if property is not available
  return mapSubQueryResult(query, subQueryPayload);
};

export const applyPreferences = (_queries, preferences) => {
  const queries = [..._queries];

  const { filter = [] } = preferences;
  if (!filter.length) {
    return queries;
  }

  return queries.map((q) => ({
    ...q,
    filter: q.filter.map((f) => {
      const savedFilter = find(filter, { resource: f.resource, path: f.path });
      if (savedFilter) {
        return savedFilter;
      }
      return f;
    }),
  }));
};
