import { omit } from 'lodash';
import * as widgetDataActions from '../actions/widget-data-actions';
import * as widgetActions from '../../widget/actions/widget-actions';
import * as editWidgetActions from '../../widget/edit-widget/actions/edit-widget-actions';
import * as universalConfigSaveActions from '../../universal-config/actions/universal-config-save-actions';
import { isUndefined } from 'lodash';

/*
 This reducer/state is not used by dashies. The state is sometimes
 updated on dashies though we we dispatch some of the actions
 it listens to.
*/

const initialState = {};

const widgetDataReducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case widgetDataActions.dataReceived.type:
      return {
        ...state,
        [payload.key]: {
          data: payload.data,
          updatedAt: payload.updatedAt,
        },
      };

    case widgetDataActions.dataError.type: {
      const widget = state[payload] || {};

      return {
        ...state,
        [payload]: {
          ...widget,
          error,
        },
      };
    }

    case editWidgetActions.configUpdated.type:
    case universalConfigSaveActions.configUpdated.type: {
      const { widgetKey, data } = payload;
      const cleanError = !isUndefined(data)
        ? undefined
        : state[widgetKey].error;

      return {
        ...state,
        [widgetKey]: {
          ...state[widgetKey],
          error: cleanError,
          data,
        },
      };
    }
    case widgetActions.setWidgets.type:
      return initialState;

    case widgetDataActions.clearData.type:
    case widgetActions.widgetRemoved.type:
      return omit(state, payload);

    default:
      return state;
  }
};

export default widgetDataReducer;
