// Brand colors in Javascript
// Note: These colors need to be in sync with
// the colors in the CSS (_colors.scss file)

const GeckoboardGreen = '#0FD354';
const GeckoboardYellow = '#FFD339';
const GeckoboardBlue = '#00D1FF';
const GeckoboardWhite = '#E9E7F9';
const GeckoboardMint = '#3CD3D3';
const GeckoboardPink = '#FF97AA';

const Positive = GeckoboardGreen;
const Warning = '#FEB554';
const Negative = '#EC373C';

const PositiveOnDark = '#7ACD78';
const NegativeOnDark = '#FC5C6A';

const Grey5 = '#f6f6f6';
const Grey10 = '#ececec';
const Grey20 = '#dadada';
const Grey40 = '#b7b7b7';
const Grey60 = '#929292';
const Grey80 = '#6e6e6e';
const Grey100 = '#4a4a4a';
const Grey120 = '#0e0e0e';

const Black10 = '#E8E7EB';
const Black20 = '#D0CFD8';
const Black40 = '#A2A0B0';
const Black60 = '#737089';
const Black70 = '#5C5875';
const Black90 = '#2E294E';
const Black100 = '#16113A';

const BackgroundLight = '#F8F8F9';
const BackgroundMid = '#E6E9F1';
const BackgroundDark = '#DFE4EE';

export {
  GeckoboardGreen,
  GeckoboardYellow,
  GeckoboardBlue,
  GeckoboardWhite,
  GeckoboardMint,
  GeckoboardPink,
  Positive,
  Warning,
  Negative,
  PositiveOnDark,
  NegativeOnDark,
  Grey5,
  Grey10,
  Grey20,
  Grey40,
  Grey60,
  Grey80,
  Grey100,
  Grey120,
  Black10,
  Black20,
  Black40,
  Black60,
  Black70,
  Black90,
  Black100,
  BackgroundLight,
  BackgroundMid,
  BackgroundDark,
};
