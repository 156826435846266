import { omit, isUndefined } from 'lodash';
import { TEMPLATES } from './';
import { removeKeysWithUnderscore } from '../helpers';

const setOrientation = (config) => {
  if (config.type === 'bar') {
    return { ...config, orientation: 'horizontal' };
  }

  return config;
};

// TODO: Introduced on 23/02/2016
// to update number widgets without comparison type
// To be removed when script update DB entries
const updateOldNumberWidget = (config) => {
  if (
    config.type === 'number' &&
    !isUndefined(config.goal) &&
    isUndefined(config.comparison)
  ) {
    return { comparison: { type: 'goal' }, ...config };
  }

  return config;
};

class SalesforceSerializer {
  serialize(state) {
    const { uuid: reportId, config: salesforceConfig, refreshInterval } = state;

    const { ...restConfig } = salesforceConfig;
    const newConfig = setOrientation(removeKeysWithUnderscore(restConfig));

    const config = {
      ...newConfig,
      report_id: reportId,
      template: TEMPLATES[newConfig.type],
    };

    return {
      config,
      service_name: 'salesforce',
      refresh_interval: refreshInterval,
    };
  }

  deserialize(json) {
    const { refresh_interval: refreshInterval } = json;
    const config = json.config;
    const { report_id: reportId } = config;
    const updatedConfig = updateOldNumberWidget(config);
    const widgetConfig = omit(updatedConfig, ['report_id', 'orientation']);

    return { uuid: reportId, config: widgetConfig, refreshInterval };
  }
}

export default SalesforceSerializer;
