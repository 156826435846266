import { transform as genericGeckometerTransform } from '../per-visualisation/geckometer';
import { composeTransformers } from '../compose-transformers';
import { getMainColumnField, getFormat, parseNumberValue } from './helpers';
import { isNull } from 'lodash';

const geckometerTransform = (
  payload,
  config = {},
  timezone,
  initialContext = {},
) => {
  if (!payload || !payload.length) {
    return {};
  }
  const meterColumn = getMainColumnField(config);
  const format = getFormat(meterColumn, config);

  const parsedValue = parseNumberValue(payload);
  const item = isNull(parsedValue) ? null : parsedValue[0];

  const visualisationConfig = {
    item,
    ...format,
  };

  return { ...initialContext, ...visualisationConfig };
};

const transform = composeTransformers(
  geckometerTransform,
  genericGeckometerTransform,
);

export { transform };
