import { uniqWith, isEqual } from 'lodash';
import * as selectionHelpers from '../../helpers/selection-helpers';

import type {
  ColumnRow,
  Range,
  SpreadsheetsVisualizationConfig,
} from '../../types';

/**
 * This function determines the selections that were originally used based upon the
 * ranges actually used within the configuration itself. It's considered a legacy
 * format now, and will remove unused rows/columns from the original ranges that the
 * user selected.
 */
export const getRangesFromConfig = (
  config: SpreadsheetsVisualizationConfig,
): Range[] => {
  const selections: Array<[ColumnRow, ColumnRow]> = [];

  config.xAxis && selections.push(config.xAxis); // bar, column, line
  config.series && selections.push(...config.series); // bar, column, line
  config.columns && selections.push(...config.columns); // table
  config.values && selections.push(config.values); // leaderboard
  config.labels && selections.push(config.labels); // leaderboard
  config.value && selections.push(config.value); // text, number, geckometer

  // Remove any duplicates that may be present and map to a Range object
  return uniqWith(selections, isEqual).map(
    selectionHelpers.convertSelectionToObjectFormat,
  );
};
