import { transform as genericLeaderboardTransform } from '../per-visualisation/leaderboard';
import { composeTransformers } from '../compose-transformers';
import { chain } from 'lodash';
import {
  DEFAULT_LABEL,
  getMainColumnField,
  getEnumHumanValue,
  getFormat,
} from './helpers';

const formatLabel = (label, selectXAxis) => {
  const formattedLabel = label || DEFAULT_LABEL;
  if (selectXAxis.type === 'enum') {
    return getEnumHumanValue(formattedLabel, selectXAxis.values);
  }
  return formattedLabel;
};

const leaderboardTransform = (
  payload,
  config = {},
  timezone,
  initialContext = {},
) => {
  if (!payload || !payload.length) {
    return {};
  }
  const leaderboardColumn = getMainColumnField(config);
  const format = getFormat(leaderboardColumn, config);
  const { queryMetas: [{ select: [selectXAxis = {}] = [] } = {}] = [] } =
    config;

  const items = chain(payload)
    .map(([label, value]) => ({
      label: formatLabel(label, selectXAxis),
      value,
      ...format,
    }))
    .value();

  const visualisationConfig = {
    items,
    wrap: config.wrap ?? undefined,
  };

  return { ...initialContext, ...visualisationConfig };
};

const transform = composeTransformers(
  leaderboardTransform,
  genericLeaderboardTransform,
);

export { transform };
