import { chain } from 'lodash';
import { getValuesFromRow, processSeries } from './helpers';
import cleanTransformedData from '../shared/clean-transformed-data';
import {
  composeTransformers,
  composeApplyConfigTransformers,
} from '../compose-transformers';
import { transform as goalTransform } from '../../transformers/shared/goal';

const formatSeries = (series) => ({ data: series });

/*
 * For multi-series we need to add column names to
 * the series. This function takes the columns and
 * returns a formatter that can be passed to series.map()
 */
const createMultiSeriesFormatter = (columns) => (series, i) => ({
  data: series,
  name: columns[i + 1].name,
});

const lineTransform = ({ data }) => {
  if (!data || !data.databaseQuery) {
    return {};
  }

  const { rows, columns } = data.databaseQuery;

  const seriesData = chain(rows)
    // returns the values
    .map(({ values }) => getValuesFromRow(values))
    .value();

  // process each series and return an array of series ([[], [], ...)
  const processedSeries = processSeries(seriesData);
  const isMulti = processedSeries.length > 1;
  const seriesFormatter = isMulti
    ? createMultiSeriesFormatter(columns)
    : formatSeries;

  const series = chain(processedSeries)
    // wraps array into { data: array }
    .map(seriesFormatter)
    // filter elements with no data, they should be stripped as you cannot have empty series (trigger an error)
    .filter((result) => result.data.length > 0)
    .value();

  return {
    series,
    x_axis: { type: 'datetime' },
    y_axis: { format: 'decimal' }, // We only support decimal values right now
    format: 'decimal',
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(lineTransform, goalTransform);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data, config) => {
  const cleanedData = cleanTransformedData(data);
  return composeApplyConfigTransformers(goalTransform)(cleanedData, config);
};

export { transform, applyConfig };
