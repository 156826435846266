import { chain, map, last, slice } from 'lodash';

const DECIMAL = ['decimal'];
const DATETIME = ['datetime'];
const PERCENT = ['percent'];

function getLabelType(labelType) {
  switch (labelType) {
    case 'currency':
    case 'number':
    case 'percentage':
      return 'number';
    case 'date':
    case 'datetime':
      return 'datetime';
    default:
      return 'standard';
  }
}

function getYAxisFormat(labelType, unit) {
  if (labelType === 'date' || labelType === 'datetime') {
    return DATETIME;
  }

  if (labelType === 'percentage') {
    return PERCENT;
  }

  if (labelType === 'currency') {
    return ['currency', unit];
  }

  return DECIMAL;
}

function getSeries(pegSeries = {}) {
  return chain(pegSeries)
    .map(({ label, values }) => {
      return { name: label, data: values };
    })
    .value();
}

function getXLabels(labelType, pegLabels) {
  const labels = map(pegLabels, 'label');
  const type = getLabelType(labelType);

  return { labels, type };
}

const getGeckometerValue = (config, total, series) => {
  const { numberToDisplay } = config;

  if (numberToDisplay !== 'latestRecord') {
    return total;
  }

  if (!series.length) {
    return undefined;
  }
  return last(series[0].values);
};

/*
 * The salesforce configuration is wrapped inside of a config object,
 * that is why we need to destructure in the transformer in order not to
 * introduce more logic in megatron
 */
const destructureSalesforceConfigWrap =
  (transformer) =>
  (data, { config } = {}, context) => {
    return transformer(data, config, context);
  };

const limitSeriesTransformWrap =
  (limit, transformer) => (data, config, context) => {
    const { series: initialSeries } = data;
    const series = slice(initialSeries, 0, limit);
    return transformer({ ...data, series }, config, context);
  };

export {
  getYAxisFormat,
  getSeries,
  getXLabels,
  getGeckometerValue,
  destructureSalesforceConfigWrap,
  limitSeriesTransformWrap,
};
