import createAction from '../../lib/redux/create-action';
import { dispatchEvent } from '../../lib/dispatch-event';
import { dispatchToGeckoJS } from '../../lib/gecko-view';
import { showGenericErrorToast } from '../../toast/actions';
import { patchConfig } from '../../lib/widget-service';
import { track } from '../../lib/tracker';
import { trackEvent, conform } from '@Tracking';
import {
  widgetIndicatorsAdded,
  widgetIndicatorsEdited,
  widgetIndicatorsDeleted,
  widgetNumberFormattingEdited,
} from '@Tracking/events';
import { isUniversal, isBauhaus } from '../../lib/service';
import { isNumber, difference, filter, get, isEqual, isEmpty } from 'lodash';
import * as universalConfigSaveActions from '../../universal-config/actions/universal-config-save-actions';
import * as editWidgetActions from '../../widget/edit-widget/actions/edit-widget-actions';
import { WIDGET_MENU_VIEWS } from '../constants';

export const addRecord = createAction('WidgetMenu:ADD_RECORD');
export const removeRecord = createAction('WidgetMenu:REMOVE_RECORD');
export const openMenu = createAction('WidgetMenu:OPEN_MENU');
export const closeMenu = createAction('WidgetMenu:CLOSE_MENU');
export const openCopyModal = createAction('WidgetMenu:OPEN_COPY_MODAL');
export const updateNumberFormat = createAction(
  'WidgetMenu:UPDATE_NUMBER_FORMAT',
);
export const updateStatusIndicatorValue = createAction(
  'WidgetMenu:UPDATE_STATUS_INDICATOR_VALUE',
);
export const doOpenNumberFormatter = createAction(
  'WidgetMenu:OPEN_NUMBER_FORMATTER',
);
export const doOpenWidgetStatusIndicators = createAction(
  'WidgetMenu:OPEN_WIDGET_STATUS_INDICATORS',
);
export const saveNewNumberFormat = createAction(
  'WidgetMenu:SAVE_NEW_NUMBER_FORMAT',
);
export const saveNewIndicator = createAction('WidgetMenu:SAVE_NEW_INDICATOR');
export const openDeleteModal = createAction('WidgetMenu:OPEN_DELETE_MODAL');
export const openEditWidget = createAction('WidgetMenu:OPEN_EDIT_WIDGET');
export const toggleQueryEditor = createAction('WidgetMenu:TOGGLE_QUERY_EDITOR');
export const openNumberFormatter = (widgetKey) => (dispatch, getState) => {
  const { widgets } = getState();
  const widget = widgets[widgetKey];
  const { config: { numberFormat } = {} } = widget;

  dispatch(doOpenNumberFormatter(numberFormat));
};

export const openWidgetStatusIndicators = () => (dispatch) => {
  dispatch(doOpenWidgetStatusIndicators());
};

export const doCloseMenu = () => (dispatch, getState) => {
  const { widgetKey } = getState().widgetMenu;

  dispatch(closeMenu());
  dispatchToGeckoJS('widget-menu', {
    open: false,
    widgetKey,
  });
};

export const handleIndicatorTracking = async (
  widget,
  store,
  newIndicators,
  integration,
) => {
  const {
    user: { user: { id: userId, organization_id: orgId } = {} } = {},
    dashboard: { dashboard: { id: dashboardId } = {} } = {},
  } = store;

  const originalIndicators = get(widget, 'config.indicators', {});
  // Pull out a list of the new types of indicators that have been added
  const getStates = (indicatorsList) =>
    filter(indicatorsList, (i) => !isNumber(i) && i !== 'neutral');

  // these will check if there are new indicators and only keep those
  const newStates = difference(
    getStates(newIndicators.thresholds),
    getStates(originalIndicators.thresholds),
  );

  const hasRemovedIndicators =
    isEmpty(newIndicators.thresholds) &&
    !isEmpty(originalIndicators.thresholds);
  const hasAddedIndicators =
    !isEmpty(newIndicators.thresholds) &&
    isEmpty(originalIndicators.thresholds);
  // only send an edited event if there are new states
  const hasEditedIndicators = !isEqual(newIndicators, originalIndicators);

  // legacy tracking
  newStates.forEach((state) => {
    track('Indicator set', {
      properties: {
        type: state,
        userId,
        orgId,
        widgetId: widget.id,
        dashboardId,
      },
    });
  });

  const trackingProps = {
    'Legacy widget ID': `${widget.id}`,
    Visualisation: widget.visualisation.vizType,
    'Widget type ID': `${widget.widgetType.id}`,
    'Widget type name': widget.widgetType.title,
    ...conform.integration(integration),
  };

  if (hasRemovedIndicators) {
    await trackEvent(widgetIndicatorsDeleted(trackingProps));
  } else if (hasAddedIndicators) {
    await trackEvent(widgetIndicatorsAdded(trackingProps));
  } else if (hasEditedIndicators) {
    await trackEvent(widgetIndicatorsEdited(trackingProps));
  }
};

export const saveIndicatorConfig =
  ({ key }) =>
  async (dispatch, getState) => {
    try {
      const {
        widgetMenu: { indicators: newIndicators },
        widgets: { [key]: widget },
      } = getState();

      dispatch(doCloseMenu());
      dispatch(saveNewIndicator({ widgetKey: key, indicators: newIndicators }));
      dispatchToGeckoJS('widget:indicators:change', {
        widgetKey: key,
        indicators: newIndicators,
      });

      await handleIndicatorTracking(
        widget,
        getState(),
        newIndicators,
        widget.widgetType.integration,
      );

      await patchConfig(key, { indicators: newIndicators });
    } catch (e) {
      dispatch(showGenericErrorToast());
    }
  };

export const deleteStatusIndicatorConfig =
  ({ key }) =>
  async (dispatch, getState) => {
    const {
      user: { user: { id: userId, organization_id: orgId } = {} } = {},
      dashboard: { dashboard: { id: dashboardId } = {} } = {},
      widgets,
    } = getState();
    const widget = widgets[key];

    try {
      dispatch(doCloseMenu());
      dispatch(saveNewIndicator({ widgetKey: key, indicators: [] }));

      track('Indicators deleted', {
        properties: {
          userId,
          orgId,
          widgetId: widget.id,
          dashboardId,
        },
      });

      trackEvent(
        widgetIndicatorsDeleted({
          'Legacy widget ID': `${widget.id}`,
          Visualisation: widget.visualisation.vizType,
          'Widget type ID': `${widget.widgetType.id}`,
          'Widget type name': widget.widgetType.title,
          ...conform.integration(widget.widgetType.integration),
        }),
      );

      dispatchToGeckoJS('widget:indicators:change', {
        widgetKey: key,
        indicators: [],
      });

      await patchConfig(key, { indicators: [] });
    } catch (e) {
      dispatch(showGenericErrorToast());
    }
  };

export const handleNumberFormattingTracking = (
  widget,
  newConfig,
  integration,
) => {
  const { numberFormat } = newConfig;
  const { numberFormat: oldFormat } = widget.config || {};

  if (isEqual(numberFormat, oldFormat)) {
    return Promise.resolve();
  }

  track('Number formatting edited');

  return trackEvent(
    widgetNumberFormattingEdited({
      'Legacy widget ID': `${widget.id}`,
      Visualisation: widget.visualisation.vizType,
      'Widget type ID': `${widget.widgetType.id}`,
      'Widget type name': widget.widgetType.title,
      ...conform.integration(integration),
    }),
  );
};

export const saveNumberFormat = (widgetKey, target) => (dispatch, getState) => {
  const { numberFormat } = getState().widgetMenu;
  const widget = getState().widgets[widgetKey];

  dispatch(doCloseMenu());
  dispatch(saveNewNumberFormat({ widgetKey, numberFormat }));

  dispatchEvent(target, 'widget-action', {
    type: 'widget:config:change',
    config: { numberFormat },
  });

  patchConfig(widgetKey, { numberFormat });

  handleNumberFormattingTracking(
    widget,
    { numberFormat },
    widget.widgetType.integration,
  );
};

export const closeAndSave = () => (dispatch, getState) => {
  const state = getState();

  if (state.widgetMenu.view !== WIDGET_MENU_VIEWS.EDIT) {
    dispatch(doCloseMenu());
    return;
  }

  const { widgetKey, serviceName } = state.editWidget;
  const { dashboard } = state.dashboard;

  // Bauhaus does not rely on the widget menu for saving, it handles it via its own compact config
  if (isBauhaus(serviceName)) {
    dispatch(doCloseMenu());
    return;
  }

  if (isUniversal(serviceName)) {
    dispatch(universalConfigSaveActions.updateWidget(widgetKey, dashboard.id));
  } else {
    dispatch(editWidgetActions.updateWidget(widgetKey, dashboard.id));
  }
  dispatch(doCloseMenu());
};
