import { isEqual } from 'lodash';
import type { Range, RangeWithNoSize } from '../../types';

/**
 * Determine if the given selections represent a single cell or not
 */
export function isSingleCellSelected(
  selections: Range[] | RangeWithNoSize[],
): boolean {
  if (selections.length !== 1) {
    return false;
  }

  return isEqual(selections[0].start, selections[0].end);
}
