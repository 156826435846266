import { configureStore } from '@reduxjs/toolkit';
import { once } from 'lodash';
import handleError from '../lib/redux/middleware/handle-error-middleware';
import reducers from './root-reducer';

export const getReduxStore = () =>
  configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: {
          ignoredPaths: ['universal'],
        },
      }).concat(handleError);
    },
  });

export default once(getReduxStore);
