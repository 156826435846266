import PropTypes from 'prop-types';
import React from 'react';
import config from '../../universal/config';

import style from './tv-page-style.scss';

const TvPageComponent = ({ error, children }) => {
  const assetHost = config.get().AssetBaseURL;

  return (
    <div className={style.page}>
      <table className={style.table}>
        {error && (
          <tr>
            <div className={style.error}>{error}</div>
          </tr>
        )}
        <tr className={`${style.logoContainer}`}>
          <a
            className={style.link}
            rel="external noreferrer"
            target="_blank"
            href="https://www.geckoboard.com"
          >
            <img
              className={style.logo}
              src={`${assetHost}/geckoboard-text-logo.svg`}
            />
          </a>
        </tr>
        <tr height="100%">
          <td className={style.content}>{children}</td>
        </tr>
      </table>
    </div>
  );
};

TvPageComponent.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
};

export default TvPageComponent;
