import { fetchJSONWithCredentials } from '../universal/fetch';
import config from '../universal/config';

const postCustomCSS = (url, body) => {
  return fetchJSONWithCredentials(url, {
    method: 'POST',
    body,
    headers: {
      enctype: 'multipart/form-data',
    },
  });
};

export const uploadCustomCSS = async (dashboardId, file) => {
  const url = `${config.get().ManagementBaseURL}/dashboards/${dashboardId}/css`;

  const formData = new FormData();
  formData.append('css', file);

  const dashboard = await postCustomCSS(url, formData);

  const { css } = dashboard;

  return css;
};
