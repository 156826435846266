import { defaultTo } from 'lodash';
import LineChartHelpers from './line-chart-form/helpers';
import NumberHelpers from './number-form/helpers';
import LeaderboardHelpers from './leaderboard-form/helpers';
import GeckometerHelpers from './geckometer-form/helpers';
import BarChartHelpers from './bar-chart-form/helpers';
import ColumnChartHelpers from './column-chart-form/helpers';
import TableHelpers from './table-form/helpers';

const DATA_METHODS = {
  line: LineChartHelpers,
  number: NumberHelpers,
  leaderboard: LeaderboardHelpers,
  geckometer: GeckometerHelpers,
  bar: BarChartHelpers,
  column: ColumnChartHelpers,
  table: TableHelpers,
};

const getVisualisationHelpers = (visualisation) =>
  defaultTo(DATA_METHODS[visualisation], LineChartHelpers);

export default getVisualisationHelpers;
