import possibleTypes from './possibleTypes.json';
import cacheKeyResolver from './cache-key-resolver';

/**
 * When an item is removed from an array, Apollo client throws a warning:
 * "Cache data may be lost when replacing the frames field of a
 * Container object", for example.
 *
 * We resolve this by defining a merge policy for the affected fields
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
 * https://github.com/apollographql/apollo-client/issues/6868
 *
 * When this is caused by manually updating the cache, it can also be
 * solved by using cache.evict() before cache.writeQuery(), or cache.modify()
 * in place of both.
 * https://github.com/apollographql/apollo-client/issues/6451#issuecomment-645612063
 */

const typePolicies = {
  Query: {
    fields: {
      bauhausBlueprint: {
        merge(existing, incoming) {
          return { ...existing, ...incoming };
        },
      },
      instrument: {
        read(_, { args, toReference }) {
          // Redirect instrument cache so that query instrument() can find the instrument in
          // the cache from being fetched by the dashboard() query.
          // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
          return toReference({
            __typename: 'Instrument',
            id: args.id,
          });
        },
      },
      databaseConnections: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  BauhausFieldValue: {
    keyFields: ['fieldId', 'id'],
  },
  ContainerDashboard: {
    fields: {
      containers: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  Container: {
    fields: {
      frames: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  EmbassyIntegration: {
    keyFields: ['slug'],
  },
  ExternalUser: {
    fields: {
      viewableDashboards: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  Instrument: {
    fields: {
      dataSource: {
        merge(existing, incoming) {
          // Since dataSource can be null, we can only merge
          // existing and incoming if they both aren't null.
          if (existing && incoming) {
            return { ...existing, ...incoming };
          }

          return incoming;
        },
      },
      numberFormat: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      statusIndicator: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
};

export default {
  possibleTypes,
  typePolicies,
  dataIdFromObject: cacheKeyResolver,
};
