import { get, last } from 'lodash';
import { transform as genericGeckometerTransform } from '../per-visualisation/geckometer';
import { composeTransformers } from '../compose-transformers';
import {
  getDatapointValue,
  getColumnFormat,
  getMaxValue,
  formatDurationValue,
} from './helpers';

const extractGaugeValue = (data) => {
  const rows = get(data, 'data.bauhausDataRequest.data.rows');
  const columns = get(data, 'data.bauhausDataRequest.data.columns');

  if (!rows) return {};

  const lastItem = last(rows);
  const lastValue = lastItem ? last(rows).values[0] : undefined;
  const format = getColumnFormat(columns);
  const value = getDatapointValue(lastValue);

  return {
    format,
    value,
  };
};

const geckometerTransform = (data) => {
  const rows = get(data, 'data.bauhausDataRequest.data.rows');

  if (!rows) {
    return {};
  }

  const { value, format } = extractGaugeValue(data);
  // getMaxValue takes seconds and bauhaus values are in milliseconds
  const maxValue = getMaxValue(value, format);

  return {
    item: value,
    min: { value: 0 },
    max: { value: maxValue },
    ...format,
  };
};

const durationTransform = (_, __, ___, initialContext) => {
  const { format, max, min, item, unit } = initialContext;

  if (format !== 'duration') {
    return initialContext;
  }

  return {
    ...initialContext,
    /*
     * the min and max values saved in seconds, we must convert to ms
     * if we want to reuse the existing formatDurationValue helper
     */
    min: min && { value: formatDurationValue(min.value * 1000, unit) },
    max: max && { value: formatDurationValue(max.value * 1000, unit) },
    item: formatDurationValue(item, unit),
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(
  geckometerTransform,
  genericGeckometerTransform,
  durationTransform,
);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data, config) => {
  return { ...data, numberFormat: config.numberFormat };
};

export { transform, applyConfig, extractGaugeValue };
