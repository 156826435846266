import { omitBy, isUndefined } from 'lodash';
import * as SalesforceConfigConstants from '../constants/salesforce-config-constants';
import * as pickerActions from '../../file-picker/actions/picker-actions';
import { toValidConfig } from '../helpers';

const switchVisualisationType = (state, newConfig) => {
  const { previousConfigs, config } = state;
  const { title, type } = config;

  return {
    previousConfigs: { ...previousConfigs, [type]: { ...config } },
    config: { ...newConfig, title },
  };
};

const initialState = {
  meta: {},
  data: {},
  config: {},
  previousConfigs: {},
  isMetaLoading: true,
  isInitLoading: true,
  error: null,
};

const salesforceReducerInner = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SalesforceConfigConstants.INIT_CONFIG_LOADING:
      return {
        ...state,
        uuid: payload,
        meta: {},
        data: {},
        config: {},
        previousConfigs: {},
        isMetaLoading: true,
        isInitLoading: true,
        error: null,
      };
    case SalesforceConfigConstants.INIT_CONFIG_SUCCESSFUL:
      return {
        ...state,
        meta: payload.meta,
        config: payload.config,
        refreshInterval: payload.refreshInterval,
        isMetaLoading: false,
        error: null,
      };
    case SalesforceConfigConstants.INIT_CONFIG_ERROR:
      return {
        ...state,
        isMetaLoading: false,
        isFetchingData: false,
        isInitLoading: false,
        error: payload,
      };
    case SalesforceConfigConstants.UPDATE_CONFIG:
      return { ...state, config: { ...state.config, ...payload } };
    case SalesforceConfigConstants.SET_STATE_PROPS:
      return { ...state, ...payload };
    case SalesforceConfigConstants.DATA_FETCH_START:
      return { ...state, data: {}, isFetchingData: true, error: null };
    case SalesforceConfigConstants.DATA_FETCH_SUCCESSFUL:
      return {
        ...state,
        data: payload,
        isFetchingData: false,
        isInitLoading: false,
        error: null,
      };
    case SalesforceConfigConstants.DATA_FETCH_ERROR:
      return {
        ...state,
        isFetchingData: false,
        isInitLoading: false,
        error: payload,
      };
    case SalesforceConfigConstants.WIDGET_CONFIG_FETCH_FAILED:
      return { ...state, error: payload };
    case SalesforceConfigConstants.WIDGET_CREATION_START:
    case SalesforceConfigConstants.WIDGET_UPDATE_START:
      return { ...state, isSaving: true };
    case SalesforceConfigConstants.WIDGET_CREATION_FAILED:
      return { ...state, error: payload, isSaving: false };
    case SalesforceConfigConstants.SWITCH_VISUALISATION_TYPE:
      return { ...state, ...switchVisualisationType(state, payload) };
    case SalesforceConfigConstants.TOGGLE_TABLE_HEADERS:
      return {
        ...state,
        config: {
          ...state.config,
          hasHeader: payload,
        },
      };
    case SalesforceConfigConstants.SET_PROGRESS_GOAL:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { progressGoal, startingValue } = action.payload;
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const newConfig = {
        ...state.config,
        comparison: omitBy(
          { type: 'goal', thresholdType: 'percentage', startingValue },
          isUndefined,
        ),
        goal: isFinite(progressGoal) ? progressGoal.toString() : progressGoal,
      };
      return { ...state, config: { ...newConfig } };
    case SalesforceConfigConstants.SET_GOAL:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { goal } = action.payload;

      return {
        ...state,
        config: {
          ...state.config,
          goal: isFinite(goal) ? goal.toString() : goal,
        },
      };
    case pickerActions.closeFilePicker.type:
      return { ...state, showFilePicker: false };
    default:
      return state;
  }
};

const ensureValidConfig = (state) => {
  return { ...state, config: toValidConfig(state) };
};

const salesforceReducer = (state, action) => {
  return ensureValidConfig(salesforceReducerInner(state, action));
};

const getLastConfigForType = (state, type) => {
  const { previousConfigs } = state;
  return previousConfigs[type] || {};
};

export default salesforceReducer;
export { initialState, getLastConfigForType };
