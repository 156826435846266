import colorPlugin from 'color';
import { times } from 'lodash';
import { getWindow } from '@Lib/global';
import * as colors from '../../src/ui/style/colors.js';

const DEFAULT_COLOR = colors.GeckoboardBlue; // In case we can't find the variable being requested

type CssVariableName = `--${string}`;
type ColorValue = ReturnType<typeof colorPlugin>;

export const getCSSColorVariable = (
  cssVariable: CssVariableName,
  fallbackColor?: string,
): string => {
  return (
    getWindow().getComputedStyle(document.body).getPropertyValue(cssVariable) ||
    fallbackColor ||
    DEFAULT_COLOR
  );
};

const lightenBy = (color: ColorValue, ratio: number): ColorValue => {
  const lightness = color.lightness();
  return color.lightness(lightness + (100 - lightness) * ratio);
};

export const whiten = (colorString: string, ratio: number): string => {
  return colorPlugin(colorString).mix(colorPlugin('white').alpha(ratio)).hex();
};

// Gets a dynamic color scale, based on the primary visualisation color and the length
// of items. The primary visualisation color will first be darkened by 50% to make sure
// that we can get a nice scale for light colours as well. Then we Create a scale where
// the first item is the darkest colour, and the last item is the lightest colour.

// The colours will be different for scales of different lengths. That's how we can make
// sure the contrast between the colours is the highest one possible.
// The color white (the lightest color possible) is excluded.
export const getVisualisationColorScale = (length: number): string[] => {
  const primaryVisualisationColor = getCSSColorVariable(
    '--primary-visualisation-color',
  );

  const baseColor = colorPlugin(primaryVisualisationColor).darken(0.5);
  return times(length, (i) => lightenBy(baseColor, i / length).hex());
};
