import { CustomColors } from '@Generated/graphql';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

import { isValidColor } from './isValidColor';

export type ThemeName = 'LIGHT' | 'DARK' | 'CUSTOM';
// We remove brandColor from the type as the field is deprecated. If
// the version of polecat that is running is running this code, then
// mainVisualizationColor should be set instead, so we can make it required.
export type ThemeColors = Omit<CustomColors, '__typename' | 'brandColor'> & {
  mainVisualizationColor: string;
};

export interface ThemeState {
  dashies: {
    logoSource?: string;
    themeUpdated?: number;
    themeName?: ThemeName;
    customColors?: Partial<ThemeColors>;
  };
}

export const initialState: ThemeState = {
  dashies: {},
};

/**
 * A Redux slice to manage theme previews to apply to the current dashboard.
 *
 * Currently this is only for dashies (but other boards) can
 * be migrated in. When the customization panel is open this
 * slice will contain a theme that should take precendence over
 * anything that's been loaded for a live preview.
 */
export const slice = createSlice({
  name: 'themes',
  initialState,
  reducers: {
    /** Sets the current logo that is being used by the Dashies customization panel */
    setDashiesLogo: (
      state: ThemeState,
      action: PayloadAction<string | undefined>,
    ) => {
      state.dashies.logoSource = action.payload;
    },
    /** Sets the current theme that is being used by the Dashies customization panel */
    setDashiesTheme: (
      state: ThemeState,
      action: PayloadAction<
        { name: 'LIGHT' | 'DARK' } | { name: 'CUSTOM'; colors?: ThemeColors }
      >,
    ) => {
      const { name } = action.payload;
      state.dashies.themeName = name;

      if (name === 'CUSTOM') {
        // Strip out any invalid colours from the updated set, retaining the old values
        state.dashies.customColors = {
          ...state.dashies.customColors,
          ...pickBy(action.payload.colors, (value) => isValidColor(value)),
        };
      } else {
        state.dashies.customColors = undefined;
      }

      state.dashies.themeUpdated = Date.now();
    },
    /** Clears out all the dashies customization */
    clearDashies: (state: ThemeState) => {
      state.dashies = { themeUpdated: Date.now() };
    },
  },
});

export const actions = {
  dashies: {
    setLogo: slice.actions.setDashiesLogo,
    setTheme: slice.actions.setDashiesTheme,
    clear: slice.actions.clearDashies,
  },
};

export default slice.reducer;
