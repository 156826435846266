import {
  StatusIndicatorFormData,
  ManagementAPIStatusIndicatorConfig,
  Direction,
} from '../status-indicator-types';
import { fromNumberOrCell } from './fromNumberOrCell';

/**
 * Convert to StatusIndicatorFormData from widget config as received from
 * Management API
 */
export function fromManagementAPIStatusIndicatorFormat(
  input: ManagementAPIStatusIndicatorConfig | EmptyObject | undefined,
): StatusIndicatorFormData | undefined {
  if (!input || !Array.isArray(input.thresholds)) {
    return undefined;
  }

  const neutralIndex = input.thresholds.indexOf('neutral');
  const [lowerState, lowerValue] = input.thresholds.slice(0, neutralIndex);
  const [upperValue, upperState] = input.thresholds.slice(neutralIndex + 1);

  const successDirection: Direction =
    upperState === 'positive' || lowerState === 'warning' ? 'above' : 'below';

  const upper = fromNumberOrCell(
    typeof upperValue !== 'string' ? upperValue : undefined,
  );
  const lower = fromNumberOrCell(
    typeof lowerValue !== 'string' ? lowerValue : undefined,
  );

  if (upper === undefined && lower === undefined) {
    return undefined;
  }

  return {
    successDirection,
    success: successDirection === 'above' ? upper : lower,
    warning: successDirection === 'above' ? lower : upper,
  };
}

type EmptyObject = Record<string, never>;
