import { chain, find, isEmpty, isUndefined, pickBy } from 'lodash';
import DebouncePromise from '../../lib/debounce-promise';
import getVisualisationHelpers from '../components/get-visualisation-helpers';
import { getMainField, isTypeDatetime, supportTimespan } from '../lib/filters';

export function ensureQuery(type, fieldGroups, config) {
  return getVisualisationHelpers(type).getDefaultQuery(fieldGroups, config);
}

export function getFieldTypes(fieldGroups) {
  return chain(fieldGroups)
    .values()
    .flatten()
    .transform((m, { key, type }) => {
      m[key] = type;
    }, {})
    .value();
}

const getOrderByColumn = (groupBy, columnIndex, columns) => {
  // if we're using group by field then the column index in the viz
  // and the query won't correlate, so we need to do this calculation instead
  if (!isUndefined(groupBy)) {
    if (columnIndex === 0) {
      return groupBy;
    }
    return columns[columnIndex - 1];
  }
  return columns[columnIndex];
};

export const updateSortTableQuery = (query, columnIndex) => {
  const {
    columns,
    order_by: {
      field: prevField,
      aggregate: prevAggregate,
      direction: prevDirection,
    } = {},
  } = query;
  const { field, aggregate } = getOrderByColumn(
    query.group_by,
    columnIndex,
    columns,
  );
  const newQuery = { ...query, order_by: undefined };

  const hasFieldChanged = !isUndefined(field) && field !== prevField;
  const hasChangedToCount = aggregate === 'count' && prevAggregate !== 'count';
  const hasAggregateChanged = aggregate !== prevAggregate;

  // if the current order by field is not set or not the same as the new field
  // then default the new field with ascending direction
  if (hasFieldChanged || hasChangedToCount || hasAggregateChanged) {
    newQuery.order_by = pickBy({ field, aggregate, direction: 'asc' });
  } else {
    // if we're here then we're still on the same field
    // if the direction is ascending then toggle to descending
    // otherwise we're on descending and toggling sorting off
    // so leave order by undefined
    if (prevDirection === 'asc') {
      newQuery.order_by = pickBy({
        field,
        aggregate,
        direction: 'desc',
      });
    }
  }
  return newQuery;
};

export function debouncify(fn) {
  const queryManager = new DebouncePromise();
  return (...args) => {
    return queryManager.push(fn(...args));
  };
}

export const getUpdatedTimeField = (
  query,
  currentTimeField,
  type,
  fields = [],
  fieldGroups = {},
) => {
  if (isEmpty(query) || !supportTimespan(query, type)) {
    return null;
  }

  const { datetime: datetimeFields = [] } = fieldGroups;
  const { filters = [] } = query;
  const mainField = getMainField(query, type);
  const timespanFilter = filters.find(({ isTimespan }) => isTimespan);

  if (isTypeDatetime(fields, mainField)) {
    return mainField;
  }

  if (!timespanFilter && !currentTimeField && datetimeFields.length) {
    // If there is no timespanFilter but there are datetime fields, currentTimeField
    // should always be set. We get into this state when going from a query that
    // doesn't support timespan to one that does, or editing a widget using "all time".
    // Default to the first datetime field that isn't already used in filters.
    const datetimeField = find(datetimeFields, ({ key }) => {
      const filterWithKey = find(filters, ({ field }) => field === key);
      return !filterWithKey;
    });

    // TODO: If !datetimeField, remove one of the datetime filters and
    // use it for currentTimeField. For now we return datetimeFields[0].key
    // it gives us these bugs
    // https://app.clubhouse.io/geckoboard/story/59423/tweak-filter-behaviour-when-switching-from-a-timespan-less-viz-to-one-with-a-timespan
    // https://app.clubhouse.io/geckoboard/story/59484/the-user-should-not-be-able-to-have-a-filter-for-a-time-attrribute-that-is-used-in-time

    return datetimeField ? datetimeField.key : datetimeFields[0].key;
  }

  if (!timespanFilter) {
    // If there is no timespan filter, we're either using "all time"
    // and the currentTimeField has been set from the dataset-time-connector,
    // or the dataset doesn't have any datetime fields. In both cases
    // we dont want to change currentTimeField so just return it.
    return currentTimeField;
  }

  return timespanFilter.field;
};
