import {
  isArray,
  isEmpty,
  isFinite,
  isNull,
  isUndefined,
  omit,
  mapValues,
} from 'lodash';
import { getGeckometerDefaultMinMaxValue } from '../../lib/geckometer';
import { parseDurationInput } from '../../lib/humanise-number';
import {
  isDurationInput,
  isEmptyDurationInput,
  getInputFromDuration,
} from '../ui-field-options';
import { parseNumberBasedOnFormat } from '../../universal/lib/parse-number';
import { isDetailsMetricSelected } from '../universal-config-helpers';
import { transformData } from './other-universal-config-reducer-methods';

export const EMPTY_DURATION_INPUT = { days: '', hours: '', minutes: '' };
export const SUPPORTED_DURATION_INPUTS = [
  'goal',
  'min',
  'max',
  '_minDefault',
  '_maxDefault',
];

export const arrangeDurations = (_config, mainColumn) => {
  const { type, unit } = mainColumn;
  if (type !== 'duration') return _config;

  let config = { ..._config };
  SUPPORTED_DURATION_INPUTS.forEach((field) => {
    if (config[field]) {
      if (!isEmptyDurationInput(config[field])) {
        const duration = parseDurationInput(config[field], unit);
        config[field] = duration.toString();
      } else {
        config = omit(config, [field]);
      }
    } else if (config[field] === '') {
      config = omit(config, [field]);
    }
  });
  return config;
};

// Set min max values from defaults
export const arrangeMinMax = (config) => {
  const { type, min, max, _minDefault, _maxDefault } = config;

  if (type !== 'geckometer') {
    return config;
  }

  let updatedMin = min;
  if (isUndefined(parseNumberBasedOnFormat(min))) {
    updatedMin = _minDefault;
  }

  let updatedMax = max;
  if (isUndefined(parseNumberBasedOnFormat(max))) {
    updatedMax = _maxDefault;
  }

  return { ...config, min: updatedMin, max: updatedMax };
};

/**
 * ----------------------------
 * ensure helpers
 * ----------------------------
 **/
export const ensureNumberFormat = (config) => {
  if (config.type === 'table' && !isArray(config.numberFormat)) {
    return {
      ...config,
      numberFormat: [],
    };
  }
  if (config.type !== 'table' && isArray(config.numberFormat)) {
    return {
      ...config,
      numberFormat: {},
    };
  }
  return config;
};

export const ensureDetailsMetric = (_config, uiQuery) => {
  let config = { ..._config };

  if (isEmpty(uiQuery)) {
    return config;
  }
  if (isDetailsMetricSelected(uiQuery)) {
    const {
      metrics: [{ node }],
    } = uiQuery;
    config = {
      ...config,
      detailsMetricResource: node.getRoot(),
    };
  } else if (config.detailsMetricResource) {
    config = omit(config, 'detailsMetricResource');
  }

  return config;
};

export const ensureDurations = (_config, mainColumn) => {
  // TODO find a way to not go back to minutes all the time.
  // We could store _currentDurationUnit in config to restore here
  const { type: metricType, unit = 'minutes' } = mainColumn;

  return mapValues(_config, (val, key) => {
    if (
      !SUPPORTED_DURATION_INPUTS.includes(key) ||
      isNull(val) ||
      isUndefined(val)
    ) {
      return val;
    }

    const isDurationInputField = isDurationInput(val);

    if (metricType === 'duration' && !isDurationInputField) {
      const parsedValue = parseFloat(val);
      if (isFinite(parsedValue)) {
        return getInputFromDuration(parsedValue, unit);
      }
      return EMPTY_DURATION_INPUT;
    } else if (metricType !== 'duration' && isDurationInputField) {
      if (isEmptyDurationInput(val)) {
        return undefined;
      }
      return parseDurationInput(val, unit).toString();
    }
    return val;
  });
};

export const ensureMinMax = (_config, transformCtx) => {
  const { queries, queryResults, timezone } = transformCtx;
  const { type, min, max } = _config;
  switch (type) {
    case 'line':
      // the line chart will set min and max values
      // for the y axis but provide no field
      // to reset them, so we remove them here
      return omit(_config, ['min', 'max']);
    case 'geckometer':
      // 1st apply transformer to get main number and format
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { item, format } = transformData(
        queries,
        queryResults,
        _config,
        timezone,
      );

      // 2nd compute sensible defaults for min/max based on shared algo
      // Store the raw value so that we can parse the string and save the config
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { _minDefault, _maxDefault } = getGeckometerDefaultMinMaxValue(
        item,
        format,
      );

      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const minDuration = format === 'duration' ? '0' : undefined;
      return {
        ..._config,
        min: min && (minDuration || min),
        max,
        _minDefault: minDuration || _minDefault,
        _maxDefault,
      };
    default:
      return _config;
  }
};
