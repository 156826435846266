import { transform as numberFormatTransform } from '../../shared/number-format';
import { transform as goalTransform } from '../../shared/goal';
import { transform as yAxisMinMaxTransform } from './yaxisminmax';
import { composeTransformers } from '../../compose-transformers';

const transform = composeTransformers(
  numberFormatTransform,
  goalTransform,
  yAxisMinMaxTransform,
);

export { transform };
