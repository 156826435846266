import { set, get } from 'lodash';

function _toSavedPath(path) {
  return `_saved[${path}]`;
}

function getStateToSave(config, paths) {
  const updates = {};
  paths.forEach((path) => {
    set(updates, path, undefined);
    set(updates, _toSavedPath(path), get(config, path));
  });
  return updates;
}

function getStateToRestore(config, paths, defaults) {
  const updates = {};
  paths.forEach((path) => {
    set(updates, _toSavedPath(path), undefined);
    set(updates, path, get(config, _toSavedPath(path), get(defaults, path)));
  });
  return updates;
}

export { getStateToSave, getStateToRestore };
