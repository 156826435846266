import { get } from 'lodash';
import { transform as genericTableTransform } from '../per-visualisation/table';
import { composeTransformers } from '../compose-transformers';
import { getCellValue } from './helpers';

const pickFormat = (type) => {
  switch (type) {
    case 'INTEGER':
    case 'FLOAT':
      return 'decimal';
    case 'TIMESTAMP':
      return 'datetime';
    default:
      return undefined;
  }
};

const tableTransform = (data) => {
  const d = get(data, 'data.databaseQuery');

  if (!d) {
    return {};
  }

  const columns = d.columns.map(({ name: heading, dataType }) => {
    const format = pickFormat(dataType);
    return format ? { heading, format } : { heading };
  });

  const rows = d.rows.map((row) => row.values.map(getCellValue));

  return {
    columns,
    data: rows,
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(tableTransform, genericTableTransform);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data) => data;

export { transform, applyConfig };
