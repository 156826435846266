import servicePicker from '../../services/service-picker';
import * as pickerActions from '../../file-picker/actions/picker-actions';

export const fileFetchSuccessful = (files) =>
  pickerActions.fetchFilesSuccess({ files });

export const fileFetchFailed = (error) =>
  pickerActions.fetchFilesFailed(error, {
    message: error.message,
    isServiceAccountExpired: 'ErrOAuthTokenExpiredOrInvalid' === error.type,
  });

const fetchFiles = (serviceName, opts) => async (dispatch) => {
  try {
    const files = await servicePicker(serviceName).fetchList(serviceName, opts);
    dispatch(fileFetchSuccessful(files));
  } catch (error) {
    dispatch(fileFetchFailed(error));
  }
};

export default fetchFiles;
