import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';
import { DashboardContext } from '@Tracking/contexts';
import {
  STATUS_INDICATOR_TYPES,
  VISUALISATION_TYPES,
} from '@Tracking/constants';

const sharedInstrumentSchemaProperties = {
  'Instrument ID': SchemaTypes.string.isRequired,
  Visualisation: SchemaTypes.oneOf(VISUALISATION_TYPES).isRequired,
  'Widget type name': SchemaTypes.string,
  'Integration name': SchemaTypes.string.isRequired,
  'Integration slug': SchemaTypes.string.isRequired,
};

export const instrumentCreated = defineEvent(
  'Widget - Created',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentConfigEdited = defineEvent(
  'Widget - Config edited',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentIndicatorsAdded = defineEvent(
  'Widget - Indicators added',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentIndicatorsEdited = defineEvent(
  'Widget - Indicators edited',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentIndicatorsDeleted = defineEvent(
  'Widget - Indicators deleted',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentIndicatorAdded = defineEvent(
  'Widget - Indicator added',
  {
    ...sharedInstrumentSchemaProperties,
    Indicator: SchemaTypes.oneOf(STATUS_INDICATOR_TYPES).isRequired,

    // Override to make optional as instrument id isn't available when changing indicators on spreadsheets
    'Instrument ID': SchemaTypes.string,
  },
  DashboardContext,
);

export const instrumentIndicatorEdited = defineEvent(
  'Widget - Indicator edited',
  {
    ...sharedInstrumentSchemaProperties,
    'Indicator changed': SchemaTypes.bool.isRequired,
    'Direction changed': SchemaTypes.bool.isRequired,
    'Value changed': SchemaTypes.bool.isRequired,

    // Override to make optional as instrument id isn't available when changing indicators on spreadsheets
    'Instrument ID': SchemaTypes.string,
  },
  DashboardContext,
);

export const instrumentIndicatorDeleted = defineEvent(
  'Widget - Indicator deleted',
  {
    ...sharedInstrumentSchemaProperties,
    Indicator: SchemaTypes.oneOf(STATUS_INDICATOR_TYPES).isRequired,

    // Override to make optional as instrument id isn't available when changing indicators on spreadsheets
    'Instrument ID': SchemaTypes.string,
  },
  DashboardContext,
);

export const instrumentGoalAdded = defineEvent(
  'Widget - Goal added',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentGoalEdited = defineEvent(
  'Widget - Goal edited',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentGoalRemoved = defineEvent(
  'Widget - Goal removed',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentCopied = defineEvent(
  'Widget - Copied',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentDeleted = defineEvent(
  'Widget - Deleted',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentOpenInteractive = defineEvent(
  'Widget - Open Explore view',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentViewSourceData = defineEvent(
  'Widget - View source data',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);

export const instrumentFormattingEdited = defineEvent(
  'Widget - Number formatting edited',
  sharedInstrumentSchemaProperties,
  DashboardContext,
);
