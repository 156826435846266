/* eslint camelcase:0 */
import { pick } from 'lodash';
import Serializer from './default';
import { isValidSelection } from '../../spreadsheet-helpers';

function mapIndices(indices) {
  const { max_column = 0, max_row = 0, min_column = 0, min_row = 0 } = indices;
  return [
    [min_column - 1, min_row - 1],
    [max_column - 1, max_row - 1],
  ];
}

class LineChartSerializer extends Serializer {
  serialize(state) {
    const defaults = super.serialize(state);
    const configValues = pick(state, this.constructor.configKeys);
    let { series } = configValues;

    // Remove empty selections from our series
    series = series.filter(isValidSelection);

    const config = {
      ...this.constructor.configDefaults,
      ...configValues,
      series,
    };

    return { ...defaults, config };
  }

  deserialize(json) {
    const defaults = super.deserialize(json);
    const { config } = json;
    const payloadValues = pick(config, this.constructor.configKeys);
    const type = this.constructor.configDefaults.template.type;

    const { selection, x_axis, has_header } = config;
    let { xAxis, series, hasHeader } = config;

    if (selection) {
      xAxis = selection[0];
      series = selection.slice(1);
    }

    if (x_axis) {
      xAxis = mapIndices(x_axis);
      series = series.map((s) => s.selection).map(mapIndices);
      hasHeader = has_header;
    }

    return {
      ...defaults,
      ...payloadValues,
      type,
      series,
      xAxis,
      hasHeader,
    };
  }
}

LineChartSerializer.configDefaults = {
  template: {
    type: 'line',
    version: 2,
  },
};

LineChartSerializer.configKeys = [
  'hasHeader',
  'title',
  'xAxis',
  'series',
  'goal',
  'reverseGoalDirection',
  'legends',
  'minYAxis',
  'maxYAxis',
  'numberFormat',
];

export default LineChartSerializer;
