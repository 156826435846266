import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { getDashboard } from '../../services/management-service';
import { updateDashboard } from '../../services/dashboard-service';
import { dispatchToGeckoJS } from '../../lib/gecko-view';
import { redirect } from '../../lib/global';

export const addDashboard = createAction('Dashboard:ADD_DASHBOARD');
export const deleteDashboard = createAction('Dashboard:DELETE_DASHBOARD');
export const changeDashboard = createAction('Dashboard:CHANGE_DASHBOARD');
export const setDashboards = createAction('Dashboard:SET_DASHBOARDS');

export const setDashboard = createAction('Dashboard:SET_DASHBOARD');

export const setDashboardScaleFactor = createAction(
  'Dashboard:SET_DASHBOARD_SCALE_FACTOR',
);
export const setClassicboardScaleFactor = createAction(
  'Dashboard:SET_CLASSICBOARD_SCALE_FACTOR',
);

export const dashboardError = createErrorAction('Dashboard:ERROR');
export const updateSharingError = createErrorAction(
  'Dashboard:UPDATE_SHARING_ERROR',
);

export const fetchDashboardStart = createAction(
  'Dashboard:FETCH_DASHBOARD_START',
);
export const addReadOnlyUsersStart = createAction(
  'Dashboard:ADD_READONLY_USERS_START',
);

export const setDashboardTitleStart = createAction(
  'Dashboard:SET_DASHBOARD_TITLE_START',
);
export const setDashboardTitleSuccess = createAction(
  'Dashboard:SET_DASHBOARD_TITLE_SUCCESS',
);
export const setDashboardTitleFailed = createErrorAction(
  'Dashboard:SET_DASHBOARD_TITLE_FAILED',
);

export const setCurrentDashboardSharingLink = createAction(
  'Dashboard:SET_CURRENT_DASHBOARD_SHARING_LINK',
);
export const setIsMobile = createAction('Dashboard:SET_IS_MOBILE');

export const setImportingService = createAction(
  'Dashboard:SET_IMPORTING_SERVICE',
);
export const toggleImportingModal = createAction(
  'Dashboard:TOGGLE_IMPORTING_MODAL',
);

export const setSharingMode = createAction('Dashboard:SET_SHARING_MODE');
export const setEditMode = createAction('Dashboard:SET_EDIT_MODE');

export const initDashboard = (dashboard) => (dispatch) => {
  dispatch(setDashboard(dashboard));
};

export const fetchDashboard = (dashboardId) => async (dispatch) => {
  try {
    dispatch(fetchDashboardStart(dashboardId));
    const dashboard = await getDashboard(dashboardId);
    dispatch(setDashboard(dashboard));
  } catch (error) {
    dispatch(dashboardError(error, 'dashboard.loadError'));
  }
};

export const setDashboardTitle = (title) => async (dispatch, getState) => {
  const {
    dashboard: { dashboard },
  } = getState();
  const { id } = dashboard;

  dispatch(setDashboardTitleStart(title));

  try {
    await updateDashboard(id, { ...dashboard, title });

    dispatch(setDashboardTitleSuccess(title));
    dispatchToGeckoJS('dashboard:refresh');
  } catch (error) {
    dispatch(setDashboardTitleFailed(error, title));
  }
};

export const addWidget = () => (dispatch, getState) => {
  const {
    dashboard: { dashboard: { id: dashboardId } = {} },
  } = getState();
  redirect(`/v4/dashboards/${dashboardId}`);
};
