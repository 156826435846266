import { mapKeys, omit } from 'lodash';
import * as groupActions from '../../groups/actions/groups-actions';

const initialState = {};

const allDataSourcesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case groupActions.getDataSourcesSuccess.type: {
      const { dataSources } = payload;

      return mapKeys(dataSources, (dataSource) => dataSource.id);
    }

    case groupActions.deleteDataSourceSuccess.type:
      return omit(state, payload.dataSourceId);

    case groupActions.reconnectDataSourceSuccess.type: {
      const { dataSourceId } = payload;

      return {
        ...state,
        [dataSourceId]: {
          ...state[dataSourceId],
          expired: false,
        },
      };
    }

    default:
      return state;
  }
};

export default allDataSourcesReducer;
export { initialState };
