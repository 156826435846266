import { getYAxisFormat, destructureSalesforceConfigWrap } from './helpers';
import { transform as genericNumberTransform } from '../per-visualisation/number';
import { composeTransformers } from '../compose-transformers';

const getYFormat = (axisMetadata) => {
  const [format, unit] = getYAxisFormat(
    axisMetadata.y_type,
    axisMetadata.y_unit,
  );

  // The number widget can't handle dates
  if ('datetime' === format) {
    return ['decimal', unit];
  }

  return [format, unit];
};

/**
 * Salesforce number transformer
 * @param {Object} payload - Data that is returned from the backend (pegasus) for the number visualisation.
 * @param {number} payload.total - The total calculated from all the records.
 * @param {Object} payload.axis_metadata
 * @param {string} payload.axis_metadata.x_type
 * @param {string} payload.axis_metadata.y_type
 * @param {string} payload.axis_metadata.y_unit
 * @param {Object[]} payload.series
 * @param {Object} payload.series[]
 * @param {string} payload.series[].label
 * @param {Object[]} payload.series[].values
 * @param {number} payload.series[].total
 * @param {Object[]} payload.x_labels
 * @param {Object} payload.x_labels[]
 * @param {string} payload.x_labels[].label
 *
 * @param {Object} config - User configuration.
 * @param {string} config.numberToDisplay - Can be `latestRecord` or `grandTotal`.
 */
const numberTransform = (
  payload,
  config = {},
  timezone,
  initialContext = {},
) => {
  const { numberToDisplay } = config;
  const { total, axis_metadata: axisMetadata = {}, series = [] } = payload;

  const [format, unit] = getYFormat(axisMetadata);
  let value = [total];

  if ('latestRecord' === numberToDisplay) {
    if (!series.length) {
      return {};
    }
    const [firstSeries] = series;
    value = firstSeries.values;
  }

  return { ...initialContext, format, unit, value };
};

const transform = destructureSalesforceConfigWrap(
  composeTransformers(numberTransform, genericNumberTransform),
);

export { transform };
