import * as containerMenuActions from '../actions/container-menu-actions';
import { CONTAINER_MENU_VIEWS } from '../../container-layout-constants';

const initialState = {
  view: CONTAINER_MENU_VIEWS.CLOSED,
  containerId: undefined,
};

const containerMenuReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case containerMenuActions.openMenu.type: {
      return {
        ...state,
        view: CONTAINER_MENU_VIEWS.OPEN,
        containerId: payload.containerId,
      };
    }

    case containerMenuActions.closeMenu.type: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { containerMenuReducer as default, initialState };
