import dark from './dark';
import light from './light';
import navy from './navy';
import purple from './purple';
import dashiesDark from './dashiesDark';
import dashiesLight from './dashiesLight';
import { mapValues } from 'lodash';

// Apply defaults from the dark theme
const themes = mapValues(
  { light, navy, purple, dashiesDark, dashiesLight },
  (props) => ({
    ...dark,
    ...props,
  }),
);
themes.dark = dark;
themes.custom = dark;
themes.user = dark;

export default themes;
