import {
  isArray,
  pick,
  set,
  get,
  has,
  times,
  includes,
  cloneDeep,
} from 'lodash';
import { applyAndMerge } from '../../../lib/config-mapper';

const DEFAULT_AGGREGATE = 'sum';
const DATETIME_BUCKETING_ONLY = ['minute', 'hour'];

const getDefaultQuery = (fieldGroups) => {
  const firstDatetimeField = get(fieldGroups, 'datetime[0].key');
  const firstNumericField = get(fieldGroups, 'numeric[0].key');

  if (!firstDatetimeField) {
    throw new Error(
      'Your dataset does not contain any date or datetime fields, which are required for a line chart.',
    );
  }

  const metric = { aggregate: DEFAULT_AGGREGATE };

  if (firstNumericField) {
    metric.field = firstNumericField;
  } else {
    metric.aggregate = 'count';
    metric._aggregate = DEFAULT_AGGREGATE;
  }

  return {
    metrics: [metric],
    x_axis: {
      field: firstDatetimeField,
      bucket_by: 'day',
    },
  };
};

const ensureConfig = (currentConfig) => {
  const config = pick(
    currentConfig,
    'title',
    'type',
    'dataSetId',
    'goal',
    'legends',
    '_saved',
    'minYAxis',
    'maxYAxis',
    'secondaryIndex',
    'reverseGoalDirection',
    'juno',
  );

  if (has(currentConfig, 'comparison.thresholdType')) {
    config.reverseGoalDirection =
      currentConfig.comparison.thresholdType === 'lower';
  }

  config.numberFormat = !isArray(currentConfig.numberFormat)
    ? currentConfig.numberFormat
    : {};
  return config;
};

const updateQuery = (currentQuery, path, value, fields) => {
  const newQuery = cloneDeep(currentQuery);
  set(newQuery, path, value);
  const { metrics = [] } = newQuery;
  const numberOfMetrics = metrics.length || 1;
  const bucketBy = get(newQuery, 'x_axis.bucket_by');
  const xAxisField = get(newQuery, 'x_axis.field');

  // When switching to a date type from a datetime - 'hour' is no longer a valid bucketing option
  if (
    includes(DATETIME_BUCKETING_ONLY, bucketBy) &&
    'date' === fields[xAxisField].type
  ) {
    newQuery.x_axis.bucket_by = 'day';
  }

  newQuery.metrics = times(numberOfMetrics, (i) => {
    const metric = get(newQuery, `metrics[${i}]`, {});

    // Default aggregate to sum
    if (!metric.aggregate) {
      metric.aggregate = DEFAULT_AGGREGATE;
    }

    // Map the field value `aggregate:count` to the aggregate property
    if (get(metric, 'field') === 'aggregate:count') {
      delete metric.field;
      metric._aggregate = metric.aggregate;
      metric.aggregate = 'count';
    }
    if (metric.aggregate === metric._aggregate) {
      delete metric._aggregate;
    }

    // When a field is applied and we're currently aggregating by count then we must revert to the original
    // aggregate
    if (metric.field && metric.aggregate === 'count') {
      metric.aggregate = metric._aggregate || DEFAULT_AGGREGATE;
      delete metric._aggregate;
    }

    // Remove split_by when we have more than 1 metric or the user has selected the none option
    if (numberOfMetrics > 1 || 'split_by:none' === value) {
      delete metric.split_by;
    }

    return metric;
  });

  return newQuery;
};

const COLUMN_MAPPINGS = [
  'metrics',
  [
    'x_axis',
    (xAxis = {}, _lastXAxis, _lastQuery, types) => {
      // Linechart only supports datetimes/dates for the X-Axis
      if (!['datetime', 'date'].includes(types[xAxis.field])) {
        return undefined;
      }

      return pick(xAxis, 'field', 'bucket_by');
    },
  ],
  ['filters'],
];
const NUMBER_AND_GECKOMETER_MAPPINGS = [
  ['field', (field) => [{ field, aggregate: DEFAULT_AGGREGATE }], 'metrics'],
  // Overide the default `aggregate` when available
  ['aggregate', null, 'metrics[0].aggregate'],
  ['filters'],
];
const LEADERBAORD_MAPPINGS = [
  ['metric', (field) => [{ field, aggregate: DEFAULT_AGGREGATE }], 'metrics'],
  // Overide the default `aggregate` when available
  ['aggregate', null, 'metrics[0].aggregate'],
  ['filters'],
];

const QUERY_MAPPING_RULES = {
  geckometer: NUMBER_AND_GECKOMETER_MAPPINGS,
  number: NUMBER_AND_GECKOMETER_MAPPINGS,
  leaderboard: LEADERBAORD_MAPPINGS,
  column: COLUMN_MAPPINGS,
  bar: COLUMN_MAPPINGS,
  table: [['filters']],
};

const mapQuery = (fromType, ...rest) =>
  applyAndMerge(QUERY_MAPPING_RULES[fromType], ...rest);

const visualisationHelpers = {
  ensureConfig,
  getDefaultQuery,
  updateQuery,
  mapQuery,
};

export default visualisationHelpers;
