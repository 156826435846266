import { get, partition, filter, flowRight as compose, isEmpty } from 'lodash';

const MAIN_QUERY_FIELD_PATH = {
  line: 'x_axis.field',
  bar: 'x_axis.field',
  column: 'x_axis.field',
};

export const supportTimespan = (query, type) => {
  switch (type) {
    case 'number':
    case 'geckometer':
      return !!get(query, 'aggregate', null); // e.g aggregate === 'latest'
    default:
      return true;
  }
};
export const getMainField = (query, type) =>
  get(query, MAIN_QUERY_FIELD_PATH[type], null);
const getType = (fields, key) => get(fields, `${key}.type`);
const isDatetime = (type) => type === 'date' || type === 'datetime';
export const isTypeDatetime = compose([isDatetime, getType]);

const syncFiltersQuery = (query, fields, type) => {
  const mainField = getMainField(query, type);
  const filters = get(query, 'filters', []);

  const [[tsFilter], otherFilters] = partition(filters, { isTimespan: true });

  // Remove timespan filter if needed
  if (!supportTimespan(query, type)) {
    return {
      ...query,
      filters: otherFilters,
    };
  }

  // No ops when main field is not datetime or no filters set
  if (!isTypeDatetime(fields, mainField) || !filters.length) {
    // If there's only a single date / datetime filter, and we are supporting timespan
    // remove the filter
    const numOfDateFilters = filter(
      filters,
      (f) => isDatetime(f.field) && !f.isTimespan,
    ).length;
    let newFilters = filters;

    if (numOfDateFilters === 1) {
      newFilters = filter(
        newFilters,
        (f) => !isDatetime(f.field) && !f.isTimespan,
      );
    }

    newFilters = isEmpty(newFilters) ? undefined : newFilters;

    return { ...query, filters: newFilters };
  }

  // remove filters other than timespan with field set with mainField
  const cleanFilters = filter(otherFilters, ({ field }) => mainField !== field);

  let newFilters = cleanFilters;
  if (tsFilter) {
    // sync timespan filter field with mainField
    const newTsFilters = { ...tsFilter, field: mainField };
    newFilters = [newTsFilters, ...cleanFilters];
  }

  return {
    ...query,
    filters: newFilters,
  };
};

export { syncFiltersQuery };
