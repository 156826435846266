import {
  DataSourceConfigInput,
  InstrumentForEditingQuery,
  SalesforceNumberToDisplay,
  VisualizationInput,
} from '../generated/graphql';
import {
  graphQLToReduxConfig,
  reduxConfigToGraphQL,
} from '@Lib/graphql-legacy-config-mappers';
import type {
  NumberToDisplayRedux,
  SalesforceVisualizationConfig,
  SalesforceWidgetConfig,
} from './salesforce-types';
import { toWrapOptionsInput } from '../visualisation/getWrap';

const numberToDisplayConfigToGraphQL: Record<
  NumberToDisplayRedux,
  SalesforceNumberToDisplay
> = {
  grandTotal: 'GRAND_TOTAL',
  latestRecord: 'LATEST_RECORD',
};
const numberToDisplayGraphQLToConfig: Record<
  SalesforceNumberToDisplay,
  NumberToDisplayRedux
> = {
  GRAND_TOTAL: 'grandTotal',
  LATEST_RECORD: 'latestRecord',
};

export type InstrumentForEditing = NonNullable<
  InstrumentForEditingQuery['instrument']
>;

// The min/max defaults are stored with non-numerical characters
// when the value is a currency, ie $1000, so we have to strip
// those out.
const stringToFloat = (value: string): number => {
  return parseFloat(value.replace(/[^\d.-]/g, ''));
};

export const dataSourceConfigInputFromWidgetConfig = (
  config: SalesforceWidgetConfig,
): DataSourceConfigInput => {
  return {
    salesforce: {
      legacyServiceAccountId: config.meta.legacy_service_account_id.toString(),
      refreshIntervalSeconds: config.refreshInterval,
      reportId: config.uuid,
      columns: JSON.stringify(config.config.columns),
      hasHeader: config.config.hasHeader,
      numberToDisplay: config.config.numberToDisplay
        ? numberToDisplayConfigToGraphQL[config.config.numberToDisplay]
        : undefined,
      series: config.config.series,
      xAxis: config.config.xAxis,
      yAxis: config.config.yAxis,
    },
  };
};

export const vizInputFromWidgetConfig = (
  config: SalesforceVisualizationConfig,
): VisualizationInput => {
  switch (config.type) {
    case 'number':
      return {
        number: {
          title: config.title || '',
          label: config.label,
          comparison: reduxConfigToGraphQL.mapComparison(config.comparison),
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          progressIndicator: reduxConfigToGraphQL.mapProgressIndicator(
            config.goal,
            config.comparison,
          ),
          statusIndicator:
            config.indicators &&
            reduxConfigToGraphQL.mapStatusIndicators(config.indicators),
        },
      };
    case 'geckometer':
      return {
        geckometer: {
          title: config.title || '',
          min: {
            floatValue: stringToFloat(
              config.min ? config.min : config._minDefault,
            ),
          },
          max: {
            floatValue: stringToFloat(
              config.max ? config.max : config._maxDefault,
            ),
          },
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            config.indicators &&
            reduxConfigToGraphQL.mapStatusIndicators(config.indicators),
        },
      };
    case 'line':
    case 'column':
    case 'bar':
      return {
        [config.type]: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            reduxConfigToGraphQL.mapChartGoalToStatusIndicators(config),
        },
      };
    case 'leaderboard':
      return {
        leaderboard: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          sortOrder: config.reverseSort ? 'ASCENDING' : 'DESCENDING',
        },
      };
    case 'table':
      return {
        table: {
          title: config.title || '',
          columnWidths: config.columnWidths,
          numberFormats: config.numberFormat
            ? config.numberFormat.map((numberFormat) => {
                const mappedFormat =
                  reduxConfigToGraphQL.mapNumberFormat(numberFormat);
                return mappedFormat || null;
              })
            : [],
          wrap: toWrapOptionsInput(config.wrap),
        },
      };
    default:
      throw new Error(`Couldn't map visualizationInput from state to GraphQL`);
  }
};

export const widgetConfigFromInstrument = (
  instrument: InstrumentForEditing,
): SalesforceWidgetConfig => {
  if (
    !instrument.dataSource?.config ||
    !('reportId' in instrument.dataSource.config)
  ) {
    throw new Error(
      'DataSource config not set when editing a salesforce instrument.',
    );
  }

  const dataSourceConfig = instrument.dataSource.config;

  const sharedConfig: Pick<
    SalesforceWidgetConfig,
    'refreshInterval' | 'uuid' | 'meta'
  > = {
    refreshInterval: dataSourceConfig.refreshIntervalSeconds,
    uuid: dataSourceConfig.reportId,
    meta: {
      legacy_service_account_id: parseInt(
        dataSourceConfig.legacyServiceAccountId!,
        10,
      ),
    },
  };
  const shareVizConfig: Partial<SalesforceVisualizationConfig> = {
    columns: dataSourceConfig.columns && JSON.parse(dataSourceConfig.columns),
    hasHeader: !!dataSourceConfig.hasHeader,
    yAxis: dataSourceConfig.yAxis || undefined,
    xAxis: dataSourceConfig.xAxis || undefined,
    numberToDisplay: dataSourceConfig.numberToDisplay
      ? numberToDisplayGraphQLToConfig[dataSourceConfig.numberToDisplay]
      : undefined,
    series: dataSourceConfig.series || undefined,
  };

  switch (instrument.__typename) {
    case 'Number':
      return {
        ...sharedConfig,
        config: {
          ...shareVizConfig,
          type: 'number',
          title: instrument.title || '',
          label: instrument.label || '',
          comparison: graphQLToReduxConfig.mapComparison(instrument.comparison),
          indicators: graphQLToReduxConfig.mapStatusIndicators(
            instrument.statusIndicator,
          ),
          ...graphQLToReduxConfig.mapProgressIndicator(
            instrument.progressIndicator,
          ),
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
        },
      };
    case 'Geckometer': {
      const min = graphQLToReduxConfig
        .mapConfigValue(instrument.min)
        ?.toString();
      const max = graphQLToReduxConfig
        .mapConfigValue(instrument.max)
        ?.toString();
      return {
        ...sharedConfig,
        config: {
          ...shareVizConfig,
          type: 'geckometer',
          title: instrument.title || '',
          min,
          max,
          _minDefault: min || '',
          _maxDefault: max || '',
          indicators: graphQLToReduxConfig.mapStatusIndicators(
            instrument.statusIndicator,
          ),
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
        },
      };
    }
    case 'Line':
      return {
        ...sharedConfig,
        config: {
          ...shareVizConfig,
          type: 'line',
          title: instrument.title || '',
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
            instrument.statusIndicator,
          ),
        },
      };
    case 'Column':
    case 'Bar':
      return {
        ...sharedConfig,
        config: {
          ...shareVizConfig,
          type: instrument.__typename === 'Bar' ? 'bar' : 'column',
          title: instrument.title || '',
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
            instrument.statusIndicator,
          ),
        },
      };
    case 'Leaderboard':
      return {
        ...sharedConfig,
        config: {
          ...shareVizConfig,
          type: 'leaderboard',
          title: instrument.title || '',
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          reverseSort: instrument.sortOrder === 'ASCENDING',
          showImages: instrument.showImages ? instrument.showImages : undefined,
        },
      };
    case 'Table':
      return {
        ...sharedConfig,
        config: {
          ...shareVizConfig,
          type: 'table',
          title: instrument.title || '',
          columnWidths: instrument.columnWidths,
          numberFormat: instrument.numberFormats?.map(
            (nf) => graphQLToReduxConfig.mapNumberFormat(nf) || null,
          ),
          showImages: instrument.showImages ? instrument.showImages : undefined,
          wrap: instrument.wrap ?? undefined,
        },
      };
    default:
      throw new Error(
        'Visualisation type is not a valid salesforce visualisation',
      );
  }
};
