import {
  map,
  filter,
  chain,
  includes,
  head,
  isUndefined,
  reduce,
  find,
  isEmpty,
  reject,
} from 'lodash';
import { parseNumberBasedOnFormat } from '../universal/lib/parse-number';
import { getGeckometerValue } from '../universal/transformers/pegasus/helpers';
import { getGeckometerDefaultMinMax } from '../lib/geckometer';
import { TABULAR_REPORT } from './report-types';

const DATETIME_FORMATS = ['date', 'datetime', 'time'];

const MAX_COLUMNS = 10;

const toOptions = (items, disabledName = null) => {
  return map(items, (item) => {
    return {
      value: item.name,
      label: item.label,
      disabled: item.name === disabledName,
    };
  });
};

const toDateOrTimeGroups = (groups) => {
  return filter(groups, ({ type }) => includes(DATETIME_FORMATS, type));
};

const getCurrentFormat = (aggregations, yAxis) => {
  return chain(aggregations).find({ name: yAxis }).get('type').value();
};

const getAlternativeSeries = (config, state) => {
  const {
    meta: { groups },
  } = state;
  const { xAxis = '' } = config;

  // Pick first group that isn't used as xAxis
  return chain(groups).map('name').without(xAxis).head().value();
};

const getSeries = (state) => {
  const { config } = state;
  const { savedSeries } = config;

  if (savedSeries) {
    return savedSeries;
  }

  return getAlternativeSeries(config, state);
};

// Meta could have been changed after a widget has been created.
// Check that meta is still available and default to first one if not
const ensureValidMeta = (field, config, state) => {
  let collection = [];
  switch (field) {
    case 'xAxis':
    case 'series':
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const {
        meta: { groups },
      } = state;
      collection = groups;
      break;
    case 'yAxis':
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const {
        meta: { aggregations },
      } = state;
      collection = aggregations;
      break;
    default:
      break;
  }

  const availableGroups = map(collection, 'name');

  if (!includes(availableGroups, config[field])) {
    return head(availableGroups);
  }

  return config[field];
};

const toValidAxisConfig = (config, state) => {
  // make sure that xAxis and yAxis are always defined
  const xAxis = ensureValidMeta('xAxis', config, state);

  const yAxis = ensureValidMeta('yAxis', config, state);

  return { ...config, xAxis, yAxis };
};

const toValidSeriesConfig = (config, state) => {
  const { series, xAxis } = config;

  // If multi-series: series can't be the same as xAxis value
  if (isUndefined(series)) {
    return config;
  }

  const validMeta = ensureValidMeta('series', config, state);
  const validConfig = { ...config, series: validMeta };
  const { series: validSeries } = validConfig;

  if (validSeries !== xAxis) {
    return validConfig;
  }

  return { ...validConfig, series: getAlternativeSeries(validConfig, state) };
};

const toValidLatestRecord = (config, state) => {
  const {
    meta: { groups },
  } = state;
  const { xAxis, numberToDisplay } = config;

  if (numberToDisplay !== 'latestRecord') {
    return config;
  }

  const dateGroups = toDateOrTimeGroups(groups);
  if (!dateGroups.length) {
    return { ...config, numberToDisplay: 'grandTotal' };
  }

  if (find(dateGroups, { name: xAxis })) {
    return config;
  }

  return { ...config, xAxis: dateGroups[0].name };
};

const toValidMinMax = (config, state) => {
  const { meta, data } = state;

  if (isEmpty(data)) {
    return {
      ...config,
      _minDefault: '',
      _maxDefault: '',
    };
  }

  const { aggregations } = meta;
  const { yAxis } = config;
  const format = getCurrentFormat(aggregations, yAxis);
  const {
    data: { total, series },
  } = state;
  const value = getGeckometerValue(config, total, series);

  return { ...config, ...getGeckometerDefaultMinMax(value, format) };
};

const toValidTabularConfig = (config, state) => {
  const { columns } = config;
  const { columns: metaColumns } = state.meta;

  // Forward config if there are already columns
  // And check if the columns still exist in the metadata
  if (columns && columns.length) {
    const availableColumns = reject(
      columns,
      (column) =>
        !find(metaColumns, {
          name: column.field,
        }),
    );
    return { ...config, columns: availableColumns };
  }

  const defaultColumns = metaColumns
    .map((column) => ({
      field: column.name,
    }))
    .slice(0, MAX_COLUMNS);
  return { ...config, columns: defaultColumns };
};

const toValidConfig = (state) => {
  const { config, meta } = state;

  switch (meta.type) {
    // We will need to add rules here to generate the config for tabular reports
    case TABULAR_REPORT: {
      return toValidTabularConfig(config, state);
    }
    default: {
      // Apply the toValid function in order.
      const toValidFunctions = [
        toValidAxisConfig,
        toValidSeriesConfig,
        toValidLatestRecord,
        toValidMinMax,
      ];

      return reduce(
        toValidFunctions,
        (intermediateConfig, fn) => {
          return fn.call(this, intermediateConfig, state);
        },
        config,
      );
    }
  }
};

const setMinMaxFromDefaults = (state) => {
  const { config } = state;
  const { type, min, max, _minDefault, _maxDefault } = config;

  if (type !== 'geckometer') {
    return state;
  }

  let updatedMin = min;
  if (isUndefined(parseNumberBasedOnFormat(min))) {
    updatedMin = _minDefault;
  }

  let updatedMax = max;
  if (isUndefined(parseNumberBasedOnFormat(max))) {
    updatedMax = _maxDefault;
  }

  const updatedConfig = { ...config, min: updatedMin, max: updatedMax };
  return { ...state, config: updatedConfig };
};

const toRenderableState = (state) => {
  return setMinMaxFromDefaults(state);
};

const isValidInterval = (intervals, interval) => {
  return chain(intervals).map('value').includes(interval).value();
};

export {
  toOptions,
  toDateOrTimeGroups,
  getCurrentFormat,
  getAlternativeSeries,
  getSeries,
  ensureValidMeta,
  toValidAxisConfig,
  toValidSeriesConfig,
  toValidLatestRecord,
  toValidConfig,
  toValidMinMax,
  toValidTabularConfig,
  toRenderableState,
  isValidInterval,
};
