import type { WrapOptionsInput } from '@Generated/graphql';
import type { WrapOptions } from '../lib/graphql-legacy-config-mappers';

import { getCreator } from '@Lib/feature-flags/feature-flag-client-creator';

type WrapOptionsWithName = WrapOptions & {
  __typename?: string;
};

export const toWrapOptionsInput = (
  currentOptions?: WrapOptionsWithName | null,
): WrapOptionsInput => {
  const flags = getCreator().ldClient?.allFlags();
  const wrappingFeatureEnabled = flags?.['widget-column-wrapping'];

  // Use one of the defaults, which depends on whether the feature flag is enabled or not
  if (!currentOptions) {
    return wrappingFeatureEnabled ? { mode: 'AUTO' } : { mode: 'OFF' };
  }

  // When a widget is modified we always want to reset the autoDecision
  // as factors may have changed causing a change in whether we can wrap or not
  return {
    mode: currentOptions.mode,
  };
};
