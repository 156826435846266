import { sortBy, capitalize } from 'lodash';
import apolloClient from '../services/concierge-service/apollo-client';
import { Dashboards } from '../services/concierge-service/graphql/dashboards-query.graphql';
import { TRACKING_LAYOUT_TYPES } from '@Tracking/contexts/dashboard';

const getUserDisplayName = (user) => {
  if (user.firstName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user.first_name) {
    return `${user.first_name} ${user.last_name}`;
  }
  return user.email;
};

const sortGroupsByName = (groups) => {
  return sortBy(groups, [({ name = '' }) => name.toLowerCase(), 'id']);
};

const getDashboard = (id, dashboards) => {
  if (`${id}`.startsWith('dash_')) {
    return dashboards.find((d) => d.id === id);
  }
  return dashboards.find((d) => d.legacyId === id.toString());
};

const getDashboardTrackingProps = (dashboard) => {
  const type = TRACKING_LAYOUT_TYPES[dashboard.layoutType.__typename];

  const props = {
    'Dashboard ID': dashboard.id,
    'Legacy dashboard ID': dashboard.legacyId,
    'Dashboard type': type,
  };

  switch (type) {
    case TRACKING_LAYOUT_TYPES.ClassicLayout:
      props['Classicboard column count'] = dashboard.layoutType.columnCount;
      break;

    case TRACKING_LAYOUT_TYPES.FixedLayout:
      props['Fixieboard grid size'] = capitalize(dashboard.layoutType.grid);
      break;

    default:
      break;
  }

  return props;
};

const getDashboardTrackingPropsById = (id) => {
  const { dashboards } = apolloClient.create().readQuery({
    query: Dashboards,
  });

  if (!dashboards) {
    return {};
  }

  const dashboard = getDashboard(id, dashboards);

  if (!dashboard) {
    return {};
  }

  return getDashboardTrackingProps(dashboard);
};

export {
  getUserDisplayName,
  sortGroupsByName,
  getDashboardTrackingProps,
  getDashboardTrackingPropsById,
};
